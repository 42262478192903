import { useEffect, useState } from "react";
import FormAdd from "../FormAdd/FormAdd";

function FormUpdateAdmin({ admin,// информация о тренере(объект)
    isOpen,
    isClose,
    onSubmit, // действия при сабмите
    textButtonSubmit, // текст кнопки сабмита
    colorBackground, // цвет фона
}) {


    // Стейты, в которых содержятся значения инпута
    const [nameAdmin, setNameAdmin] = useState('');
    const [subnameAdmin, setSubnameAdmin] = useState('');
    const [number, setNumber] = useState('');
    const [password, setPassword] = useState('');
    const [validationMassegePassword, setValidationMassegePassword] = useState('Пароль');
    const [validationMassegeName, setValidationMassegeName] = useState('Имя');
    const [validationMassegeSubname, setValidationMassegeSubname] = useState('Фамилия');
    const [validationMassegeNumber, setValidationMassegeNumber] = useState('Номер телефона');
    //
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    //передать данные  из инпутов
    function handleChangeName(e) {
        setNameAdmin(e.target.value);
        if (e.target.validationMessage === "") {
            setValidationMassegeName('Имя');
        } else {
            setValidationMassegeName(e.target.validationMessage);
        }
    }

    function handleChangeSubname(e) {
        setSubnameAdmin(e.target.value);
        if (e.target.validationMessage === "") {
            setValidationMassegeSubname('Фамилия');
        } else {
            setValidationMassegeSubname(e.target.validationMessage);
        }

    }

    function handleChangeNumber(e) {
        let numberFromStr = +e.target.value;
        setNumber(numberFromStr);
        if (e.target.validationMessage === "") {
            setValidationMassegeNumber("Номер");
        } else {
            setValidationMassegeNumber(e.target.validationMessage);
        }
    }

    function handleChangePassword(e) {
        setPassword(e.target.value);
        if (e.target.validationMessage === "") {
            setValidationMassegePassword('Пароль');
        } else {
            setValidationMassegePassword(e.target.validationMessage);
        }
    }

    //Передать данные = отправка на сервер 
    function handleSubmit(e) {
        e.preventDefault();
        onSubmit({
            name: nameAdmin,
            surname: subnameAdmin,
            phoneNumber: number,
            password: password
        });
    }

    //Заполнить инпуты при загрузке
    useEffect(() => {
        setNameAdmin(admin.name);
        setSubnameAdmin(admin.surname);
        setNumber(admin.phoneNumber);
    }, [admin]);

    //Отслеживать изменения в инпута, кнопка задисейблена пока нет внесены изменения 
    useEffect(() => {
        if (nameAdmin === admin.name && subnameAdmin === admin.surname && number === admin.phoneNumber && password === "") {
            setIsSubmitDisabled(true);
        } else {
            setIsSubmitDisabled(false);
        }
    }, [nameAdmin, subnameAdmin, number, password, admin]);

    //Отслеживать ошибки инпутов, чтобы задисейблить кнопку
    useEffect(() => {
        if (
            validationMassegePassword !== 'Пароль' ||
            validationMassegeName !== 'Имя' ||
            validationMassegeSubname !== 'Фамилия' ||
            validationMassegeNumber !== 'Номер'
        ) {
            setIsSubmitDisabled(true);
        } else {
            setIsSubmitDisabled(false);
        }
    }, [validationMassegePassword, validationMassegeName, validationMassegeSubname, validationMassegeNumber])

    return (
        <FormAdd
            isOpen={isOpen} // стейт контролирующий открытие
            isClose={isClose} // клик на кнопку для сворачивания
            onSubmit={handleSubmit}
            text={'Редактировать'}
            textButtonSubmit={textButtonSubmit}
            colorBackground={colorBackground}
            validationOptions={isSubmitDisabled} // дисейблить сабмит если true
            children={
                <>
                    <div className="formadd__input-conainer">
                        <input value={nameAdmin} type="text" placeholder="Имя" className={`${colorBackground} formadd__input formadd__input_name_image`}
                            name="name-admin" id="name-admin" minLength="2" maxLength="30" onChange={handleChangeName} required />
                        <span className="formadd__text-error" id="error-firstname">{validationMassegeName}</span>
                    </div>
                    <div className="formadd__input-conainer">
                        <input value={subnameAdmin} type="text" placeholder="Фамилия" className={`${colorBackground} formadd__input formadd__input_name_image`}
                            name="subname-admin" id="subname-admin" minLength="2" maxLength="30" onChange={handleChangeSubname} required />
                        <span className="formadd__text-error" id="error-firstname">{validationMassegeSubname} </span>
                    </div>
                    <div className="formadd__input-conainer">
                        <input value={number} type="text" placeholder="Номер" className={`${colorBackground} formadd__input formadd__input_name_image`}
                            name="number-admin" id="number-admin" minLength="11" maxLength="11" onChange={handleChangeNumber} required />
                        <span className="formadd__text-error" id="error-firstname">{validationMassegeNumber}</span>
                    </div>
                    <div className="formadd__input-conainer">
                        <input value={password} type="text" placeholder="Новый пароль" className={`${colorBackground} formadd__input formadd__input_name_image`}
                            name="password-admin" id="password-admin" minLength="4" maxLength="11" onChange={handleChangePassword} />
                        <span className="formadd__text-error" id="error-firstname">{validationMassegePassword}</span>
                    </div>
                </>
            }
        />
    )
}

export default FormUpdateAdmin;