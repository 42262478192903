//Информация о тренере и проведенных тренировках
//SuperAdmin --> this 
//Страница тренера студии --> this 

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import InfoTooltip from "../InfoTooltip/InfoTooltip"; // инфотул для отображения информации об ошибке
import TrainingCalendar from '../TrainingCalendar.js/TrainingCalendar'; // календарь
import TrainingHistory from '../TrainingHistory/TrainingHistory'; // история тренировок
import SignIn from '../SignIn/SignIn'; // Авторизация

function TrainerPage(
    {
        backgroundColor, // цвет фона
        textHeaderColor, // цвет титульника
        styleHeaderBoarderColor, // цвет полоски в хедере
        api, // АПИ
        apiAuth, // Апи авторизации
        colorBackgroundForm, // цвет фона формы добавления
        styleFooter, // стиль футера
        styleText, // стиль текста
        textTitleColor, // цвет титульников
        previousWeekButtonImg, // изображение стрелки перекл дней недели
        nextMonthButtonImg, // изображение стрелки перекл дней недели
        infoFlex,// давать ли информацию по абонементам Flex
        infoDark, // давать ли информацию по абонементам Dark
        imgPersonalAccaunt, // картинка кнопки перехода в ЛК
        imgList, // картинка кнопки тренировок(списка)
        imgTimeTable, // картинка кнопки календаря
        isSuperAdmin, //Возможность добавлять группы только у суперадмина
        idTrainerFromSuperAdmin, // айди тренера из компонента суперадмина
        infoPaymentsToTrainer, // данные по стоимости посещений для рассчета
        styleCheckboxButton,// стиль свитчера
    }

) {

    //Авторизация
    const [isAuthTrainer, setIsAuthTrainer] = useState(false); // авторизация тренера
    // стейты управления отображения компонентов на странице
    const [isBigTrainingCalendar, setIsBigTrainingCalendar] = useState(true); // стейт для открытия большого календаря
    const [isListTraining, setListTraining] = useState(true); // календарь в виде списка или большого календаря
    //стейты, хранящие данные 
    const [idTrainer, setIdTrainer] = useState('') // айди тренера
    const [trains, setTrains] = useState([]); // массив тренировок на месяц вперед и неделю назад от текущей даты для календаря
    //инфотул
    const [isInfoToolOpen, setIsInfoToolOpen] = useState(false); // стейт для открытия информационного окна
    const [isTextMassageInfoTool, setTextMassageInfoTool] = useState(''); // текс в инфотул
    //Календарь
    const [isBigCalendar, setIsBigCalendar] = useState(false); // показывать большой календарь
    const [isChangeArrayTrains, setIsChangeArrayTrains] = useState(true); // true - отображать все тренировки,false - без групповых 


    //Авторизация тренеров
    function handleSignInTrainer(data) {
        apiAuth.loginTrainer(data)
            .then(() => {
                setIsAuthTrainer(true);
                setTimeout(() => { setIsInfoToolOpen(false); }, 3000)
            }).catch((err) => {
                setIsAuthTrainer(false);
                setTextMassageInfoTool(`${err.message}`)
                setTimeout(() => { setIsInfoToolOpen(false); }, 3000)
            })
    }


    //Если страница тренера, то айди при получении информации о тренера
    //Проверить авторизацию тренера при отрисовке компонена
    useEffect(() => {
        if (!isSuperAdmin) {
            api.getSelfTrainer()
                .then((selfTrainer) => {
                    setIdTrainer(selfTrainer.user._id);
                    setIsAuthTrainer(true);
                })
                .catch((error) => {
                    setIsAuthTrainer(false);
                });
        }
    }, [])

    //Если суперадмин, то айди устанавливается прямой передачей 
    useEffect(() => {
        if (isSuperAdmin) {
            setIdTrainer(idTrainerFromSuperAdmin);
        }
    }, [idTrainerFromSuperAdmin, isSuperAdmin])


    //Переключать тренировки на все или на без групповых
    function handleIsChangeArrayTrains(state) {
        if (state) {
            setIsChangeArrayTrains(false);
        } else {
            setIsChangeArrayTrains(true);
        }
    }

    //Переключать отображение календаря на список и большой
    function handleClickOnSettingCalendar() {
        if (isBigCalendar === true) {
            setIsBigCalendar(false)
        } else {
            setIsBigCalendar(true);
        }
    }

    //КАЛЕНДАРЬ
    function openBigCalendar() {
        if (isBigTrainingCalendar) {
            setIsBigTrainingCalendar(false);
            setListTraining(false);
        } else {
            setIsBigTrainingCalendar(true);
            setListTraining(true);
        }
    }

    //КАЛЕНДАРЬ
    //получить тренировки на месяц вперед и неделю назад для отрисовки и отфильтровать их по тренеру
    useEffect(() => {
        let updateGroupTrains = [];
        let updateAllTrains = [];
        api.getTrainsForCalendar()
            .then((trains) => {
                updateAllTrains = trains.trains.filter(training => training.trainer === idTrainer);
                updateGroupTrains = trains.trains.filter(training => training.trainer === idTrainer && training.typeOfTraining === "групповая");
                isChangeArrayTrains ? // отображать все тренировки
                    setTrains(updateGroupTrains)
                    :
                    setTrains(updateAllTrains);
            })
            .catch((err) => { console.log(err) });
    }, [isChangeArrayTrains])

    //Личный кабинет тренера(открыть)
    function handleClickOnPersonalAccaunt() {
        setIsBigTrainingCalendar(false);
    }

    //Если монтирован в страницу суперадмина, то сразу личный кабинет без расписания
    useEffect(() => {
        if (isSuperAdmin) {
            setIsBigTrainingCalendar(false);
        }
    }, [isSuperAdmin])

    //ИНФОТУЛ
    //закрывать инфотул через 3 секунды после открытия
    useEffect(() => {
        if (isInfoToolOpen) {
            setTimeout(() => {
                setIsInfoToolOpen(false);
                setTextMassageInfoTool('')
            }, 3000)
        }
    }, [isInfoToolOpen]);


    return (
        !isSuperAdmin && !isAuthTrainer ? // если страница тренера и тренер не авторизован
            <SignIn
                onSubmit={handleSignInTrainer}
                textMassageInfoTool={isTextMassageInfoTool} // информация для отображения ошибки в случае возникновения
            />
            :
            <div className={`admin-page ${backgroundColor}`}>
                {/* ИНФОТУЛ */}
                {isInfoToolOpen ?
                    < InfoTooltip
                        isOpen={isInfoToolOpen}
                        text={isTextMassageInfoTool}
                        showButton={false}
                        backgoundColor={colorBackgroundForm} />
                    : <></>}
                {/* ШАПКА */}
                {!isSuperAdmin ? // если суперадмин, то шапку не добавлять
                    <header className={`admin-page__header`}>
                        <div className={`mainPage__link-container admin-page__header-link-container`}>
                            <Link to="/flex/trainer" className={`mainPage__link-page ${textHeaderColor} ${styleHeaderBoarderColor}`}>Flex Lab</Link>
                            <Link to="/dark/trainer" className={`mainPage__link-page mainPage__link-page_Dark`}>DARK</Link>
                        </div>
                        <p className={`mainPage__link-page__description ${textHeaderColor}`}>stretching & dance</p>
                    </header>
                    : <></>}
                {/* РАСПИСАНИЕ И БОЛЬШОЙ КАЛЕНДАРЬ (В расписании только тренировки тренера(фильтрация внутри в зависимости от стейта тренера), в больщом - общие) */}
                {isBigTrainingCalendar ?
                    <TrainingCalendar
                        trainings={trains} // массив всех тренировок
                        isBig={isBigCalendar} // true - показывать большой календарь
                        //ДЛЯ СПИСКА 
                        infoFlex={infoFlex}// давать ли информацию по абонементам Flex
                        infoDark={infoDark} // давать ли информацию по абонементам Dark
                        previousWeekButtonImg={previousWeekButtonImg} // изображение стрелки перекл дней недели
                        nextMonthButtonImg={nextMonthButtonImg} // изображение стрелки перекл дней недели
                        api={api} // передать АПИ студии из компонента студии в которое монтируется расписание
                        backgroundButtonColor={backgroundColor} // цвет фона кнопок
                        titleTimeTable={'Список тренировок'} // заголовок таблицы
                        timeTableBackgroundColor={backgroundColor} // цвет фона таблицы
                        setTrains={setTrains} // менять стейт массива тренировок в компоненте студии в которое монтируется расписание
                        client={true} // проверка есть ли клиент в БД, если нет, то не показывать инф об абонементе (true - клиент есть в БД)
                        handleIsChangeArrayTrains={handleIsChangeArrayTrains} // переключать массив тренировок ( с групповыми или без)
                        isTrainerPage={true} // true - страница тренера
                        idTrainer={idTrainer} // айди тренера, если страница тренера(в TrainingList отображаются только его тренировки)
                        styleText={styleText} // стиль текста
                        textTitleColor={textTitleColor} // цвет титульников
                        styleCheckboxButton={styleCheckboxButton}   // стиль свитчера
                    /> :
                    idTrainer === '' || idTrainer === undefined ?
                        <p className='timetable__title'>Выберите тренера</p>
                        :
                        // СТАТИСТИКА ПОСЕЩЕНИЙ 
                        <TrainingHistory
                            api={api} // апи студии
                            idTrainer={idTrainer} // айди тренера
                            styleText={styleText} // стиль текста
                            textTitleColor={textTitleColor} // цвет титульников
                            isSuperAdmin={isSuperAdmin} // true - страница суперадмина
                            infoPaymentsToTrainer={infoPaymentsToTrainer} // данные по стоимости посещений для рассчета
                        />
                }
                {!isSuperAdmin ? // если суперадмин, то не показывать футер
                    <footer className={`page__footer ${styleFooter}`}>
                        {/* Менять календарь на большой или список */}
                        {isListTraining && isBigTrainingCalendar ?
                            <button className={`admin-page__list-comand-button ${backgroundColor}`}
                                onClick={handleClickOnSettingCalendar}>
                                <img className='admin-page__button-img' src={imgList} alt='imgHomePage' />
                            </button>
                            : <></>}
                        {/* Личный аккаунт */}
                        {isBigTrainingCalendar ?
                            // если открыт календарь, то показыватьк кнопку ЛК и если открыт ЛК показывать календарь
                            <>
                                <button
                                    className={`admin-page__list-comand-button admin-page__list-comand-button_personal ${backgroundColor}`}
                                    onClick={handleClickOnPersonalAccaunt}>
                                    <img className='admin-page__button-img' src={imgPersonalAccaunt} alt='imgPersonalAccaunt' />
                                </button>
                            </>
                            :
                            <>
                                < button className={`admin-page__list-comand-button ${backgroundColor}`}
                                    onClick={openBigCalendar}>
                                    <img className='admin-page__button-img' src={imgTimeTable} alt='imgTimeTable' />
                                </button>
                            </>
                        }
                    </footer >
                    : <></>
                }
            </div >
    )
}

export default TrainerPage;