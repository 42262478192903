//Статистика по абонементам и всем тренерам(проведенные тренировки и посещения)
//SuperAdmin->this

import { useEffect, useState } from "react";
import InfoTooltip from "../InfoTooltip/InfoTooltip";
import TrainingHistory from "../TrainingHistory/TrainingHistory";
import SelectMonthYear from "../SelectMonthYear/SelectMonthYear"; // селектор выбора месяца и года
import AbonementsInfo from "../AbonementsInfo/AbonementsInfo"; // общ инф по абонементам
import AbonementsList from "../AbonementsList/AbonementsList"; // список абонементов

function StatisticsSuperAdmin({
    api,
    backgoundColor,
    styleText, // стиль текста
    isStatisticsVisists, // показать статистику по посещениям
    isStatisticsAbonements, // показать статистику по абонементам
    isAbonementsInfo, // true - показ общ статистики false - списка абонементов
    styleBottomBorder, // стиль нижней рамки списка (статистика абонементов)
    isSettingsAbonements, // следить за открытием настроек абонементов
    isSettingsPaymentsToTrainer, // следить за открытием настроек оплат за посещения
}) {

    const [abonements, setAbonements] = useState([]); // массив абонементов для отображения на месяц
    //инфотул
    const [isInfoToolOpen, setIsInfoToolOpen] = useState(false); // стейт для открытия информационного окна
    const [isTextMassageInfoTool, setTextMassageInfoTool] = useState(''); // текс в инфотул
    //Селектор выбора месяца и года
    const [month, setMonth] = useState('');
    const [year, setYear] = useState(''); // Добавлено состояние для года

    //ВЫБОР МЕСЯЦА И ГОДА
    //месяц
    function handleChangeMonth(date) {
        setMonth(date)
    }
    //год
    function handleChangeYear(date) {
        setYear(date)
    }

    useEffect(() => {
        // console.log(isStatisticsVisists)

    }, [isStatisticsVisists])

    //Получить абонементы зависимости от года и месяца
    useEffect(() => {
        if (month !== "" && year !== "") {
            Promise.all([
                api.getAbonementForMonthAndYear(month, year),
                api.getHystoryAbonementForMonthAndYear(month, year)
            ])
                .then(([abonementsResult, historyAbonementsResult]) => {
                    const combinedAbonements = abonementsResult.list.concat(historyAbonementsResult.list);
                    setAbonements(combinedAbonements);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [month, year]);


    return <div className="statistics-super-admin">
        {/* ИНФОТУЛ */}
        {isInfoToolOpen ?
            <InfoTooltip
                isOpen={isInfoToolOpen} text={isTextMassageInfoTool} showButton={false} backgoundColor={backgoundColor} />
            : <></>}
        {isSettingsAbonements || isStatisticsVisists || isSettingsPaymentsToTrainer ? <></>
            :
            <SelectMonthYear
                handleMonth={handleChangeMonth}
                handleYear={handleChangeYear}
            />}
        {/* СТАТИСТИКА АБОНЕМЕНТОВ */}
        {isStatisticsAbonements ?
            isAbonementsInfo ?
                <AbonementsInfo
                    api={api}
                    month={month}
                    year={year}
                    abonements={abonements}
                />
                : <AbonementsList
                    month={month}
                    year={year}
                    abonements={abonements}
                    styleBottomBorder={styleBottomBorder} // стиль нижней рамки списка
                    styleText={styleText}
                />
            : <></>}
        {/* СТАТИСТИКА ВИЗИТОВ */}
        {isStatisticsVisists ?
            <TrainingHistory
                api={api} // апи студии
                idTrainer={''} // все тренеры, должен попаст в поиск пустой объект, чтобы вернулась информация по всем тренерам
                styleText={styleText} // стиль текста
                isSuperAdmin={true} // true - страница суперадмина
                isHideListTrains={true} // не показывать подробную таблицу тренировок
            />
            : <></>}
    </div>
}

export default StatisticsSuperAdmin;