//Список тренеров для отображения у Суперадмина
//SuperAdmin -> this

import { useEffect, useState } from "react";

function TrainersList({
    backgroundColor, // цвет фона
    handleActiveTrainerInfo, //управлять отображением информации о тренере при клике на него
    trainers,
    styleText,
    textTitleColor, // цвет титульника
}) {

    const [searchType, setSearchType] = useState("phone"); // поиск по телефону или по Имени и Фамилии
    const [trainersAlphabetically, setTrainerssAlphabetically] = useState([]) // массив тренеров по алфавиту

    // ТРЕНЕРЫ---------- ТРЕНЕРЫ---------- ТРЕНЕРЫ---------- ТРЕНЕРЫ---------- 

    // открыть страницу инф о клиенте и передать в стейт его айди для поиска инф
    function handleOpenTrainer(trainer) {
        handleActiveTrainerInfo(trainer); // передать айди в AdminPage и далее в AboutTrainerPage
    }

    //ПОИСК----------ПОИСК----------ПОИСК----------ПОИСК----------ПОИСК----------ПОИСК----------
    // управлять поиском по номеру или Имени
    function handleSearchType(type) {
        setSearchType(type)
    }

    // Отсортировать массив, чтобы переставить тренеров аренда и самотрен в конец списка
    function customSort(a, b) {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();

        // Проверяем, содержатся ли слова "аренда" или "само" в поле name каждого объекта
        const aContainsKeyword = aName.includes("аренда") || aName.includes("само");
        const bContainsKeyword = bName.includes("аренда") || bName.includes("само");

        // Если один из объектов содержит ключевое слово, а другой нет,
        // помещаем объект с ключевым словом в конец массива
        if (aContainsKeyword && !bContainsKeyword) {
            return 1;
        } else if (!aContainsKeyword && bContainsKeyword) {
            return -1;
        }

        // Возвращаем ноль, чтобы сохранить оригинальный порядок объектов,
        // если ни один из них не содержит ключевых слов
        return 0;
    }

    //useEffect---------------useEffect---------------useEffect---------------useEffect---------------useEffect---------------
    //Сортировать массив тренеров по алфавиту
    useEffect(() => {
        let alphabetically = trainers.sort((a, b) => a.name.localeCompare(b.name, 'ru'));
        alphabetically.sort(customSort); // Отсортировать массив, чтобы переставить тренеров аренда и самотрен в конец списка
        setTrainerssAlphabetically(alphabetically);
    }, [trainers])

    return (
        <div className="timetable">

            {/* СПИСОК ТРЕНЕРОВ + ИНПУТ ПОИСКА ТРЕНЕРОВ(ЕСЛИ НЕ БЫЛО КЛИКА НА КОНКРЕТНОМ ТРЕНЕРЕ) */}

            {/* СПИСОК ТРЕНЕРОВ */}
            <div className="timetable__list">
                {trainers !== undefined ?
                    trainersAlphabetically.map(trainer => {
                        let nameTrainer = trainer.name;
                        let surnameTrainer = trainer.surname;
                        let phoneNumberTrainer = trainer.phoneNumber;
                        return <button
                            className={`timetable__list-element ${backgroundColor}`}
                            id={trainer._id}
                            key={trainer._id}
                            onClick={() => { handleOpenTrainer(trainer) }} // клик на клиента в списке
                        >
                            <p className={`trainer-list__trainer-info ${styleText}`}>{nameTrainer} {surnameTrainer} {phoneNumberTrainer}</p>
                        </button>
                    })
                    : <></>
                }
            </div>
        </div >
    )
}


export default TrainersList;