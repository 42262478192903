// Список разовых посещений на тренировки
// AdminPage -> this

import { useEffect, useState } from "react";

import Preloader from "../Preloader/Preloader"; // лоадер ( пока загружается)
import InfoTooltip from "../InfoTooltip/InfoTooltip";

function ListOfOneTimeTraining(
    {
        api, // передать АПИ студии из компонента студии в которое монтируется расписание
        textTitleColor, // цвет текста
        titleTimeTable, // заголовок таблицы
        timeTableBackgroundColor, // цвет фона таблицы
        loaderTimeTable, // лоадер пока приходят тренировки с АПИ ( true или false )
        trains,// массив тренировок в расписание из компонента студии в которое монтируется расписание
        styleText, // стиль текста
        statePage, //какая страница открыта ( общая или личная) если true - добавить на тренировку, если false - убрать
        timetableListTextStyle, // стиль для текста в истории посещений
        imgAddOneTimeTraining, //картинка добавления абонемента для разовой тренировки
        imgRemoveOneTimeTraining, // картинка удаления разовой тренировки
        backgroundButtonColor,
        backgoundColor,
    }

) {
    //Инфотул информационный
    const [isInfoToolOpen, setIsInfoToolOpen] = useState(false); // стейт для открытия информационного окна
    const [textMassageInfoTool, setTextMassageInfoTool] = useState(''); // текс в инфотул

    // Инфотул для работы с тренировкой 
    const [isInfoToolOpenPresenceСheck, setIsInfoToolOpenPresenceСheck] = useState(false); // стейт для открытия информационного окна
    //Тренировки
    const [listOneTimeTrains, setListOneTimeTrains] = useState([]); // массив разовых записей на тренировки
    const [selectedTrain, setSelectedTrain] = useState({}); // объект выбранной тренировки
    const [selectedClient, setSelectedClient] = useState({}); // объект клиента на выбранной тренировки
    //Стоимость абонементов 
    const [abonementCosts, setAbonementCosts] = useState([]) // массив данных по стоимости абонемента
    const [costSelectedAbonement, setCostSelectedAbonement] = useState(0) // стоимость абонемента

    //Данные
    const [idList, setIdList] = useState('');// айди записи в списке разовых тренировок( передать в АПИ)

    //Отслеживать изменения 
    const [isChange, setIsChange] = useState(false); // отслеживать изменения

    //Типы абонементов(из типов тренировок)
    const typesAbonement = {
        'групповая': 'групповые-разовая',
        'групповая-разовая': 'групповые-разовая',
        'персональная-разовая': 'персональные-разовая',
        'персональная-сплит-разовая': 'персональные-сплит-разовая',
    }

    // работа с Инфотулом
    function closeInfoTool() { // свернуть инфотул
        setIsInfoToolOpen(false);
        setTextMassageInfoTool('')
    }

    function closeInfoToolPresenceСheck() { // свернуть инфотул подтверждения был ли на тренировке клиент
        setIsInfoToolOpenPresenceСheck(false);
    }

    function handleCloseInfoTool(e) { // закрыть инфотул на оверлей или эск 
        if (e.key === "Escape" || e.target.classList.contains('info-tooltip__overlay')) {
            closeInfoTool(e);
        }
    }

    //Создать абонемент на 1 тренировку (после подтверждения админом)
    function addAbonementOneTime() {
        let typeAbonement = typesAbonement[selectedTrain.typeOfTraining];// тип абонемента
        // console.log(typeAbonement)
        if (typeAbonement) {
            api.createAbonementForOneTimeTraining(selectedTrain._id, selectedTrain.dateOn, selectedClient._id, typeAbonement, costSelectedAbonement, idList)
                .then(() => {
                    setIsChange(true); // отследить изменения для мгновенной отрисовки
                    setIsInfoToolOpen(true);
                    setTextMassageInfoTool('Разовая тренировка подтверждена');
                }).catch((err) => {
                    setIsInfoToolOpen(true);
                    setTextMassageInfoTool(`Ошибка ${err.message}`);
                });
        } else {
            setIsInfoToolOpen(true);
            setTextMassageInfoTool('Возникла ошибка при добавлении-проверьте тип тренирвоки')
        }

    }

    //Если клиент не пришел, то удалить разовую запись(не путать с тренировкой)
    function removeOneTimeTrain() {
        api.removeListOneTimetraining(idList, selectedClient._id, selectedTrain._id, selectedTrain.typeOfTraining)
            .then(() => {
                setIsChange(true); // отследить изменения для мгновенной отрисовки
                setIsInfoToolOpen(true);
                setTextMassageInfoTool('Разовая запись удалена(не тренировка)');
            }).catch((err) => {
                setIsInfoToolOpen(true);
                setTextMassageInfoTool(`Ошибка ${err.message}`);
            })
    }

    //ТРЕНИРОВКИ
    // клик на тренировку
    function clickOnOneTimeTrain(train, client, idList) {
        setSelectedTrain(train) // записать объект кликнутой тренировки
        setSelectedClient(client) // записать объектклиента на кликнутой тренировки
        setIdList(idList); // айди записи в разовых тренировках
        //Проставить стоимость абонемента
        let typeAbonement = typesAbonement[selectedTrain.typeOfTraining];// тип абонемента
        abonementCosts.forEach((abonement) => {
            if (abonement.type === typeAbonement) {
                setCostSelectedAbonement(abonement.cost)
            }
        })
    }

    //useEffect
    //Получить массив разовых записей
    useEffect(() => {
        api.getListOneTimeTrains()
            .then((list) => { setListOneTimeTrains(list.list); })
            .catch((error) => { console.log(error) })
    }, [isChange])

    // Получить стоимость абонементов
    useEffect(() => {
        api.getAbonementCosts()
            .then((costs) => { setAbonementCosts(costs.abonementCosts); })
            .catch((err) => { console.log(err); })
    }, [])

    //Отслеживать изменения и сбрасывать стейт для мгновеннойотрисовки 
    useEffect(() => {
        if (isChange) {
            setTimeout(() => setIsChange(false), 1000);
        }
    }, [isChange]);

    return (
        <div className={`timetable ${timetableListTextStyle}`}>
            {/* ИНФОТУЛ  */}
            <InfoTooltip
                isOpen={isInfoToolOpen}
                text={textMassageInfoTool}
                isClose={closeInfoTool}
                showButton={false}
                backgoundColor={backgoundColor}
            />
            <h2 className={`timetable__title ${textTitleColor} `}>Список разовых тренировок</h2>
            <div className="timetable__list">
                {loaderTimeTable ? // данные в процессе получения - отобразить лоадер
                    <Preloader /> :
                    listOneTimeTrains.length < 1 || listOneTimeTrains.length === undefined ? // если массив пустой
                        <p className={`timetable__text-no-record ${textTitleColor}`}>Записи отсутсвуют</p> :
                        listOneTimeTrains.map(train => {

                            let date = new Date(train.train.dateOn);
                            let dateTo = new Date(train.train.dateTo);

                            let dateTrain = new Intl.DateTimeFormat('ru-RU', { day: 'numeric', month: 'long', year: 'numeric' }).format(date);
                            let formattedDate = dateTrain.replace(/\b(\d)\b/g, '0$1'); // Добавление ведущего нуля, если день месяца однозначный

                            let timeTo = dateTo.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' });
                            let time = date.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' });



                            return <button
                                className={`timetable__list-element ${timeTableBackgroundColor}`}
                                id={train._id}
                                key={train._id}
                                onClick={() => { clickOnOneTimeTrain(train.train, train.client, train._id) }}>
                                <div className="timetable__element" >
                                    <p className={`timetable__text ${styleText} timetable__element_dateTrain`}>{formattedDate} | {time} - {timeTo}</p>
                                </div>
                                <div className="timetable__element" >
                                    <p className={`timetable__text   ${styleText}`}>{train.train.name}</p>
                                </div>
                                <div className="timetable__element" >
                                   
                                    <p className={`timetable__text  ${styleText}`}>{train.train.trainer.name}</p>
                                </div>
                                <div className="timetable__element" >
                                 
                                    <p className={`timetable__text  ${styleText}`}>{train.train.typeOfTraining}</p>
                                </div>
                                <div className="timetable__element" >
                
                                    <p className={`timetable__text  ${styleText}`}>{train.client.name} {train.client.surname} {train.client.phoneNumber}</p>
                                </div>
                                {/* Добавленные кнопки (только для админа и выше) */}
                                {selectedTrain._id === train.train._id ?
                                    < div className="admin-page__training-list-buttons-container">
                                        {/* ПОДТВЕРДИТЬ ТРЕНИРОВКУ */}
                                        <div
                                            className={`admin-page__list-comand-button ${backgroundButtonColor}`}
                                            onClick={addAbonementOneTime}>
                                            <img className='admin-page__button-img' src={imgAddOneTimeTraining} alt='imgAddTraining' />
                                        </div>
                                        {/* УДАЛИТЬ ТРЕНИРОВКУ */}
                                        <div
                                            className={`admin-page__list-comand-button ${backgroundButtonColor}`}
                                            onClick={removeOneTimeTrain}>
                                            <img className='admin-page__button-img' src={imgRemoveOneTimeTraining} alt='imgAddTraining' />
                                        </div>
                                    </div>
                                    : <></>}
                            </button>
                        }
                        )
                }
            </div>
        </div >
    )

}

export default ListOfOneTimeTraining;