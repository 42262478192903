import { useEffect, useState } from "react";

import InfoTooltip from "../InfoTooltip/InfoTooltip";

function SettingsPaymentsToTrainer({
    api,
    styleText,
    colorBackground,
    colorButtonSubmit,
}) {

    const [typeTraining, setTypeTraining] = useState(''); // тип тренировкаа
    const [payment, setPayment] = useState(0); // стоимость
    const [isTrainingPayment, setIsTrainingPayment] = useState(true); // есть ли тренировка
    const [trainersPayments, setTrainingsPayments] = useState([]); // массив стоимостей тренировкаа
    const [disabledButtonSubmit, setDisabledButtonSubmit] = useState(true); // валидация для сабмита
    const [isInfoToolOpen, setIsInfoToolOpen] = useState(false); // управление инфотулом
    const [messageInfoTool, setMessageInfoTool] = useState(''); // сообщение инфотул

    const handleChangeTrainType = (event) => {
        setTypeTraining(event.target.value);
    };

    const handleChangePayment = (event) => {
        setPayment(event.target.value);
    };

    useEffect(() => {
        //console.log(payment)
    }, [payment]);

    //Сабмит
    function onSubmit(event) {
        event.preventDefault();
        if (isTrainingPayment) { // если стоимость уже заведена
            api.updatePaymentToTheTrainer(typeTraining, payment)
                .then((updatePayment) => {
                    let updatePaymentNew = updatePayment.updatePayment;
                    let updatedPayments = trainersPayments.map((trainerPayment) => {
                        if (trainerPayment.type === updatePaymentNew.type) {
                            return {
                                type: updatePaymentNew.type,
                                payment: updatePaymentNew.payment,
                            };
                        } else {
                            return trainerPayment;
                        }
                    });
                    setTrainingsPayments(updatedPayments);
                    setIsInfoToolOpen(true);
                    setMessageInfoTool('Успешно');
                })
                .catch((err) => {
                    console.log(err);
                    setIsInfoToolOpen(true);
                    setMessageInfoTool('Произошла ошибка');
                });
        } else { // если новая
            api.createPaymentToTheTrainer(typeTraining, payment)
                .then((newPayment) => {
                    let updatedPayment = newPayment.payment;
                    setTrainingsPayments((prevPayments) => [...prevPayments, updatedPayment]);
                    setIsInfoToolOpen(true);
                    setMessageInfoTool('Успешно');
                })
                .catch(() => {
                    setIsInfoToolOpen(true);
                    setMessageInfoTool('Произошла ошибка');
                });
        }
    }


    //Инфотул
    //Свернуть
    function closeInfotool() {
        setIsInfoToolOpen(false);
    }

    //Валидация
    useEffect(() => {
        if (trainersPayments) {
            let findTraining = trainersPayments.find(trainersPayments => trainersPayments.type === typeTraining) // найти тренировка по типу для сравнения цены
            let findPayment;
            if (findTraining) {
                findPayment = findTraining.payment;
            }

            if (payment === 0 || payment === findPayment) {
                setDisabledButtonSubmit(true);
            } else {
                setDisabledButtonSubmit(false);
            }
        }
    }, [trainersPayments, typeTraining, payment])

    //Получить стоимость посещений на тренировках
    useEffect(() => {
        api.getPaymentToTheTrainer()
            .then((trainersPayments) => { setTrainingsPayments(trainersPayments.payments) })
            .catch((err) => { console.log('Произошла ошибка при получении стоимости тренировок') })
    }, [])

    //Проставлять стоимость в зависимости от выбранного типа тренировкаа и пррверить есть ли тренировка в базе
    useEffect(() => {
        if (trainersPayments) {
            let find = trainersPayments.find(trainersPayment => trainersPayment.type === typeTraining)
            if (find === undefined) {
                setIsTrainingPayment(false); // проставить, что тренировка не найден
                return
            } else {
                let trainerPayment = find.payment;
                setPayment(trainerPayment)
                setIsTrainingPayment(true); // проставить, что тренировка  найден
            }
        }

    }, [typeTraining])

    // Автоматически сворачивать инфотул
    useEffect(() => {
        if (isInfoToolOpen) {
            setTimeout(() => { setIsInfoToolOpen(false) }, 3000)
        }
    }, [isInfoToolOpen])

    return <div className="settings-trainers">
        {/* ИНФОТУЛ */}
        {isInfoToolOpen ?
            <InfoTooltip
                isOpen={isInfoToolOpen}
                isClose={closeInfotool}
                text={messageInfoTool}
                showButton={false}
                backgoundColor={colorBackground} />
            : <></>}
        {/* ФОРМА РЕДАКТИРОВАНИЯ И ДОБАВЛЕНИЯ СТОИМОСТИ  */}
        <h2 className={`settings-trainers__title ${styleText}`}>Оплаты за посещения тренировок</h2>
        <form className='settings-trainers__form' onSubmit={onSubmit}>
            <div className="formadd__set">
                <div className="formadd__input-conainer">
                    <select
                        value={typeTraining}
                        onChange={handleChangeTrainType}
                        className={`${colorBackground} formadd__input formadd__input_name_image formadd__input-select`}
                    >
                        <option className="formadd__select-option" value="">Выберите тип тренировки</option>

                        <>
                            <option className="formadd__select-option" value="групповая">Групповая</option>

                            <option className="formadd__select-option" value="персональная-разовая">Персональная разовая</option>
                            <option className="formadd__select-option" value="персональная-4">Перс.блок 4</option>
                            <option className="formadd__select-option" value="персональная-8">Перс.блок 8</option>
                            <option className="formadd__select-option" value="персональная-сплит-разовая">Сплит разовая</option>
                            <option className="formadd__select-option" value="персональная-сплит-4">Сплит.блок 4</option>
                            <option className="formadd__select-option" value="персональная-сплит-8">Сплит.блок 8</option>
                            <option className="formadd__select-option" value="персональная-продление">Персональная-продление</option>
                        </>

                    </select>
                    <span className="formadd__text-error" id="error-firstname">Тип тренировки</span>
                </div>
                {/* ОПЛАТА ЗА ПОСЕЩЕНИЕ */}
                <div className="formadd__input-conainer">
                    <input value={payment} type="number" placeholder="Стоимость" className={`${colorBackground} formadd__input formadd__input_name_image`}
                        name="payment" id="payment" onChange={handleChangePayment} required />
                    <span className="formadd__text-error" id="error-firstname">Стоимость</span>
                </div>
            </div>
            <button className={`formadd__button ${colorButtonSubmit}`} type="submit" disabled={disabledButtonSubmit}>{isTrainingPayment ? 'Редактировать' : 'Создать'}</button>
        </form>
        {/* ИНфОРМАЦИЯ О ЗАНЕСЕННЫХ В БАЗУ ДАННЫХ ОПЛАТАХ ЗА ПОСЕЩЕНИЯ */}
        <h3 className={`settings-trainers__title ${styleText}`}>Оплата</h3>
        <div className="settings-trainers__trainers-table">
            <div className="settings-trainers__trainers-row">
                <p className={`settings-trainers__trainers-info settings-trainers__trainers-table_title ${styleText}`}>Тип тренировки</p>
                <p className={`settings-trainers__trainers-info settings-trainers__trainers-table_title ${styleText}`}>₽</p>
            </div>
            {trainersPayments.map((trainer) => {
                let typeTraining = trainer.type;
                let paymentTraining = trainer.payment;

                return <div className="settings-trainers__trainers-row" key={trainer.type}>
                    <p className={`settings-trainers__trainers-info ${styleText}`}>{typeTraining}</p>
                    <p className={`settings-trainers__trainers-info ${styleText}`}>{paymentTraining}</p>
                </div>
            })}
        </div>
    </div>
}

export default SettingsPaymentsToTrainer;