
function AbonementsList({
    abonements,
    styleBottomBorder, // стиль нижней рамки списка
    styleText,
}) {

    //Форматирование даты для отображения
    const options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        timeZone: 'Asia/Yekaterinburg',
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const formatter = new Intl.DateTimeFormat('ru-RU', options);
        const formattedDate = formatter.format(date);
        return formattedDate;
    }

    return <div className="abonements-list">
        {abonements !== undefined ?
            abonements.map((abonement) => {
                //клиент
                let clientName = abonement.client.name;
                let clientSurName = abonement.client.surname

                //Дата
                let dateOn = formatDate(abonement.dateOn)
                let dateTo = formatDate(abonement.dateTo)

                //Тип
                let abonementType = abonement.type;

                //Стоимость

                let abonementCost = abonement.cost;

                return <div key={abonement._id} className={`abonements-list__container ${styleBottomBorder}`}>
                    <div className="abonements-list__container-element">
                        <p className={`abonements-list__text ${styleText}`}>{clientSurName} {clientName}</p>
                    </div>
                    <div className="abonements-list__container-element">
                        <p className={`abonements-list__text ${styleText}`}>{abonementType}</p>
                        <p className={`abonements-list__text ${styleText}`}>{abonementCost}</p>
                    </div>
                    <div className="abonements-list__container-element">
                        <p className={`abonements-list__text ${styleText}`}>c {dateOn}</p>
                        <p className={`abonements-list__text ${styleText}`}> по {dateTo}</p>
                    </div>
                </div>
            })
            : <></>}

    </div>
}

export default AbonementsList;