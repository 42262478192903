import Preloader from "../Preloader/Preloader"; // лоадер ( пока загружается)
import InfoTooltip from "../InfoTooltip/InfoTooltip";
import { useEffect, useState } from "react";

function CommentsTable(
    {
        api, // передать АПИ студии из компонента студии в которое монтируется расписание
        textTitleColor, // цвет текста
        titleTimeTable, // заголовок таблицы
        backgroundColor, // цвет фона таблицы
        loaderTimeTable, // лоадер пока приходят тренировки с АПИ ( true или false )
        comments,// массив тренировок в расписание из компонента студии в которое монтируется расписание
        setComments, // добавить комментарий в массив для отрисовки
        idClient, // айди клиента
        styleButton, // стиль кнопок
    }
) {

    // данные
    const [isTextMassageInfoTool, setTextMassageInfoTool] = useState(''); // текс в инфотул
    const [commentText, setCommentText] = useState(''); // текст комментария
    // управление
    const [isInfoToolOpen, setIsInfoToolOpen] = useState(false); // стейт для открытия информационного окна

    let idAdmin = '645e67dc690e59e0f4c0470f' // айди админа хардкод

    // работа с Инфотулом
    function closeInfoTool() { // свернуть инфотул
        setIsInfoToolOpen(false);
        setTextMassageInfoTool('')
    }

    function handleCloseInfoTool(e) { // закрыть инфотул на оверлей или эск 
        if (e.key === "Escape" || e.target.classList.contains('info-tooltip__overlay')) {
            closeInfoTool(e);
        }
    }

    // Работа с комментариями
    function getTime(date) {
        // Преобразование объекта даты в строку
        const dateString = date.toString();
        // Разбиение строки на компоненты даты и времени
        const [dayOfWeek, month, dayOfMonth, year, time] = dateString.split(' ');

        // Преобразование времени в формат HH:mm
        const formattedTime = time.slice(0, 5);
        // Преобразование названия месяца в формат MMMM
        const formattedMonth = month.charAt(0).toUpperCase() + month.slice(1);
        // Форматирование даты в требуемый формат
        const formattedDate = `${dayOfWeek} ${formattedTime} ${dayOfMonth} ${formattedMonth} ${year}`;

        return formattedDate;
    }



    function addCommentClient() { // создать новый комментарий
        api.addClientComment(idAdmin, idClient, commentText)
            .then((newComment) => {
                setComments(newComment.updateClient.comments) // отрисовать новый список комментариев
                setCommentText('') // очистить поле ввода
            })
            .catch((err) => { console.log(err) })
    }

    function handleSubmitComment(e) {
        e.preventDefault();
        addCommentClient();
    }

    useEffect(() => { // слушатели на закрытие инфотул // свернуть на esc или клик на оверлей
        if (isInfoToolOpen) {
            document.addEventListener('click', handleCloseInfoTool);
            document.addEventListener('keydown', handleCloseInfoTool);
        } else {
            document.removeEventListener('click', handleCloseInfoTool);
            document.removeEventListener('keydown', handleCloseInfoTool);
        }
    }, [isInfoToolOpen])

    return (
        <div className="timetable">
            <InfoTooltip
                isOpen={isInfoToolOpen}
                text={isTextMassageInfoTool}
                isClose={closeInfoTool}
                showButton={false}
                backgroundColor={backgroundColor}
            />
            <h2 className={`timetable__title ${textTitleColor}`}>{titleTimeTable}</h2>
            <div className={`timetable__list ${backgroundColor}`}>
                <form className={`timetable__list ${backgroundColor}`} onSubmit={handleSubmitComment}>
                    <textarea
                        className={`comments-table__textarea ${backgroundColor}`}
                        value={commentText}
                        onChange={(event) => setCommentText(event.target.value)}
                        placeholder="Введите комментарий"
                        required
                    ></textarea>
                    <button className={`${styleButton}`} type="submit">Добавить комментарий</button>
                </form>
                {loaderTimeTable ? // данные в процессе получения - отобразить лоадер
                    <Preloader /> :
                    // ФОРМИРОВАНИЕ ТАБЛИЦЫ
                    comments === undefined || comments.length < 1 ? // если массив комментариев пустой
                        <h3 className="timetable__title">Записи отсутсвуют</h3> :
                        comments.map(comment => {
                            console.log(comment)
                            let date = new Date(Date.parse(comment.date));
                            let time = getTime(date);
                            return <div
                                className={`timetable__list-element ${backgroundColor}`}
                                id={comment._id}
                                key={comment._id}
                            >
                                <div className="timetable__element" >
                                    <p className="timetable__text">{time}</p>
                                    {/* <p className="timetable__text">{time}</p> */}
                                </div>
                                <div className="timetable__element" >
                                    <p className="timetable__text">{comment.text}</p>
                                </div>
                            </div>
                        })
                }
            </div>
        </div >
    )
}

export default CommentsTable;