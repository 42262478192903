//СПИСОК ТРЕНИРОВОК ДЛЯ АДМИНА И СУПЕРАДМИНА
//AdminPage->TrainingCalendar->this

import { useEffect, useState } from "react";

import InfoTooltip from "../InfoTooltip/InfoTooltip";
import DayList from "../DayList/DayList";
import Preloader from "../Preloader/Preloader";
import ClientsTable from "../ClientsTable/ClientsTable";
import Selector from "../Selector/Selector";
import FormUpdateTrain from "../FormUpdateTrain/FormUpdateTrain";
import QRCodeReader from "../QRCodeReader/QRCodeReader";

function TrainingList({ // Спиок тренировок в календаре ( отображение тренировок на главной админа и тренера) и возможность просмотреть кто записался на тренировку
    api, // передать АПИ студии из компонента студии в которое монтируется расписание
    textTitleColor, // цвет текста
    timetableWeekButtonStyle, // цвет фона кнопок дней недели
    titleTimeTable, // заголовок таблицы
    timeTableBackgroundColor, // цвет фона таблицы
    styleText, // стиль текста
    loaderTimeTable, // лоадер пока приходят тренировки с АПИ ( true или false )
    timetableButtonStyle, // цвет фона кнопок переключения недели
    idClient, // айди клиента
    disabledButtonTrain, // работает ли клик на тренировку или нет, true - не работает, нужно для отображения компонента списка посещений
    timetableListTextStyle, // стиль для текста в истории посещений
    previousWeekButtonImg, // изображение стрелки перекл дней недели
    nextMonthButtonImg, // изображение стрелки перекл дней недели
    infoFlex,// давать ли информацию по абонементам Flex
    infoDark, // давать ли информацию по абонементам Dark
    isTrainerPage, // true - страница тренера
    idTrainer, // айди тренера, если страница тренера(в TrainingList отображаются только его тренировки)
    backgroundButtonColor, // цвет фона кнопок
    imgUpdateTraining, // изображение кнопки редактирования тренировки
    imgRemoveTraining, // изображение кнопки удаления тренировки
    imgQRCode, // изображение кнопки qrcode
    trainers, // массив тренеров для показа в инпуте при выборе тренировки 
    backgoundColorPage,
    styleCheckboxButton,   // стиль свитчера
    client, // проверка есть ли клиент в БД, если нет, то не показывать инф об абонементе (true - клиент есть в БД)
    //Форма редактирования тренировки
    isTypeSpace, // выбор зала, true - добавить выбор
    colorBackgroundForm, // цвет фона
    isChangeTrainingsListHandle, // слушатель изменений расписания для повторного запроса на сервер для получения массива тренировок
    newTrain, // новая тренировка для отрисовке в массиве
}
) {

    const [isInfoToolOpen, setIsInfoToolOpen] = useState(false); // стейт для открытия информационного окна
    const [isClientsTable, setIsClientsTable] = useState(false); // стейт для отображения списка записавшихся клиентов
    const [isButtonInfoTool, setButtonInfoTool] = useState(false); // отображать или нет кнопки в инфотуле
    const [wasUpdateTraining, setWasUpdateTraining] = useState(false); // тренировка отредактирована (для обновления списка без обновления страницы)
    const [isOpenConfirmationOfDeletion, setIsOpenConfirmationOfDeletion] = useState(false); // открыть инфотул подтверждения удаления
    const [isTextMassageConfirmationOfDeletion, setIsTextMassageConfirmationOfDeletion] = useState(''); // текст подтверждения удаления тренировки
    const [selectedTrain, setSelectedTrain] = useState('');
    const [dateSelectedTrain, setDateSelectedTrain] = useState(''); // дата выбранной тренировки для текста подтверждения удаления
    const [updateTraining, setUpdateTraining] = useState({}); // обновленная тренировка
    const [trainings, setTrainings] = useState([]) // массив тренировок
    const [groupeTrainings, setGroupeTrainings] = useState([]) // массив только групповых тренировок
    const [showTraining, setShowTraining] = useState([]) // какой массив тренировок показывать
    const [isGroupeTrainings, setIsGroupeTrainings] = useState(true); // отображать только групповые тренировки
    const [clients, setClients] = useState([]) // список клиентов на тренировке для отображения
    const [waitingList, setWaitingList] = useState([]) // список клиентов в листе ожидания
    const [isTextMassageInfoTool, setTextMassageInfoTool] = useState(''); // текс в инфотул
    const [idTrain, setIdTrain] = useState(''); // айди тренировки для записи во время открытия Инфотула
    const [objectSelectedTraining, setObjectSelectedTraining] = useState({}); // объект выбранной тренировки
    //Календарь
    const [isChangeArrayTrains, setIsChangeArrayTrains] = useState(true); // true - отображать все тренировки,false -только те, гдет тренер ведет(актуально для страницы Тренера TrainerPage) 
    //форма редактирования 
    const [isOpenUpdateTraining, setIsOpenUpdateTraining] = useState(false);
    const [trainingData, setTrainingsData] = useState({}); // объект с данными по тренировке, заполняется при клике на тренировку и передается в форму редактирования абонемента
    //qrcode
    const [isOpenQrCodeReader, setIsOpenQrCodeReader] = useState(false); // true - открыть считыватель qrcode
    const [idQrCodeClient, setIdQrCodeClient] = useState("");
    const [isLoadingQrCodeTrainingAddVisit, setIsLoadingQrCodeTrainingAddVisit] = useState(false); // true -загрузка или добавление на тренировку, отобразить лоадер в компоненет QRCode
    const [textQrCodeClient, setTextQrCodeClient] = useState('');
    const [isButtonsQRCode, setIsButtonsQRCode] = useState(false); // true - показывать кнопки в форме поиска qrcode
    const [isInfoMessageTextQRCode, setIsInfoMessageTextQRCode] = useState(false); // true - показывать информационное сообщение с кнопкой продолжить


    // Открыть список записавшихся на тренировку
    function openInfoToolGetVisits(trainid, time, formattedDate) {
        setIdTrain(trainid)
        let dateTrain = `${time}  ${formattedDate}`
        setDateSelectedTrain(dateTrain) // текст для инфотула подтверждения удаления тренировки
        handleToggleExtraButtons(trainid); // кнопки редактирования тренировки
        api.getTrainById(trainid)
            .then((train) => {
                setIsClientsTable(true);
                setClients(train.train.listOfVisits);
                setWaitingList(train.train.waitingList);
            }).catch((err) => { console.log(err) });
    }

    //функция для передачи в ClientTable, чтобы клик не срабатывал
    function сlickOnClients() {
        //заглушка - не удалять
    }

    const handleToggleExtraButtons = (trainId) => {
        setSelectedTrain(trainId);
    };

    //Редактирование тренировки
    function handleOpenFormUpdateTraining(trainingData) { // открыть редактирование 
        setIsOpenUpdateTraining(true); // открыть форму редактирования 
        setTrainingsData(trainingData); // данные тренировки в виде объекте, прокидываются из TrainingList при клике на тренировку
        setObjectSelectedTraining(trainingData);
    }

    function handleSubmitUpdateTraining(dataTraining) { // обновить тренировку
        api.updateTrain(idTrain, dataTraining.nameTrain, dataTraining.trainer, dataTraining.capacity)
            .then((upadeTraining) => {
                setUpdateTraining(upadeTraining.update);
                setWasUpdateTraining(true);
                setIsInfoToolOpen(true);
                setTextMassageInfoTool('Тренировка отредактирована')
                setIsOpenUpdateTraining(false);
                setWasUpdateTraining(true);
            }).catch((err) => {
                setIsInfoToolOpen(true);
                setTextMassageInfoTool(`Произошла ошибка ${err.message}`)
            });
    }

    function handleCloseFormUpdateTraining() { // свернуть редактирование абонемента
        setIsOpenUpdateTraining(false);
    }

    //Удалить тренировку
    //открыть инфотул удаления тренироки
    function handleOpenFormRemoveTraining() {
        setIsTextMassageConfirmationOfDeletion(`Удалить тренировку в ${dateSelectedTrain}`)
        setIsOpenConfirmationOfDeletion(true);
    }

    //удалить тренировку(одну)
    function handleRemoveOneTraining() {
        api.removeTrain(idTrain)
            .then((train) => {
                const updateTrainings = trainings.filter(training => training._id !== idTrain)
                setTrainings(updateTrainings);
                setIsOpenConfirmationOfDeletion(false); // свернуть форму удаления тренировки
                let listOfVisits = train.listOfVisits // приходит список записанных на тренировку клиентов, которая была удалена
                setIsInfoToolOpen(true);
                setTextMassageInfoTool(`Тренировка в ${dateSelectedTrain} удалена`)
                setIsChangeArrayTrains(true);
            }).catch((err) => {
                setTextMassageInfoTool(`Произошла ошибка ${err.message}`)
                setIsChangeArrayTrains(true);
            });
    }

    //удалить тренировки на конкретное время
    function handleRemoveManyTraining() {
        api.removeManyTrain(idTrain)
            .then((train) => {
                const updateTrainings = trainings.filter(training => training._id !== idTrain)
                setTrainings(updateTrainings);
                setIsOpenConfirmationOfDeletion(false); // свернуть форму удаления тренировки
                setIsInfoToolOpen(true);
                setTextMassageInfoTool(`Тренировки в ${dateSelectedTrain} удалены`)
                setIsChangeArrayTrains(true);
            }).catch((err) => {
                setIsOpenConfirmationOfDeletion(false); // свернуть форму удаления тренировки
                setIsInfoToolOpen(true);
                setTextMassageInfoTool(`${err.message}`)
                setIsChangeArrayTrains(true);
            });
    }

    //Свернуть инфотул подтверждения удаления
    function closeConfirmationOfDeletion() {
        setIsOpenConfirmationOfDeletion(false)
    }

    //Переключать тренировки на все или на без групповых Управлять именно переключателем
    function handleIsChangeArrayTrains() {
        if (isGroupeTrainings) {
            setIsGroupeTrainings(false);
        } else {
            setIsGroupeTrainings(true);
        }

    }

    //Инфотул
    function closeInfoTool() {
        setIsInfoToolOpen(false);
    }

    //Менять массив тренировок для отображения в зависимости от переключателя
    useEffect(() => {
        //Если открыта страница тренера, то показывать только его тренировки
        if (isTrainerPage) {
            let updateGroupTrains = [];
            let updateAllTrains = [];

            if (isGroupeTrainings) { // если показывать только групповые
                updateGroupTrains = trainings.filter(training => training.trainer._id === idTrainer && training.typeOfTraining === "групповая");
                setShowTraining(updateGroupTrains)
            } else { // если все тренировки
                updateAllTrains = trainings.filter(training => training.trainer === idTrainer);
                setShowTraining(updateAllTrains)
            }
            //Если не страница тренера
        } else {
            if (isGroupeTrainings) { // если показывать только групповые
                setShowTraining(groupeTrainings)
            } else { // если все тренировки
                setShowTraining(trainings)
            }
        }

    }, [isGroupeTrainings, trainings, groupeTrainings])

    //отрисовать тренировки (все и только группы) на текущую дату
    useEffect(() => { // получить текущую дату и загрузить активные записи на нее для первоначального отображения или тренировки клиента
        // getUploaderTimeTeble(true); // пока загрузка показывать лоадер
        let date = new Date();
        api.getTrainsOfDate(date)
            .then((trainings) => {
                if (isTrainerPage) { // если страница тренера, то в списке только его тренировки
                    const filteredTrainings = trainings.trains.filter((training) => idTrainer === training.trainer._id);
                    const sortedTrainings = filteredTrainings.sort((a, b) => new Date(a.dateOn) - new Date(b.dateOn));
                    setTrainings(sortedTrainings);
                } else { // если страница админа
                    let listTrainings = trainings.trains.sort((a, b) => new Date(a.dateOn) - new Date(b.dateOn))
                    setTrainings(listTrainings)
                    let groupeTrainings = listTrainings.filter(tr => tr.typeOfTraining.includes('групповая'))
                    setGroupeTrainings(groupeTrainings);
                }
            }).catch((err) => {
                console.log(err);
            });

    }, [])

    //Добавить в массив новую созданную тренировку
    useEffect(() => {
        if (isChangeTrainingsListHandle) {

        }
    }, [isChangeTrainingsListHandle])

    useEffect(() => {
        let listTrainings = trainings.sort((a, b) => new Date(a.dateOn) - new Date(b.dateOn))
        let groupeTrainings = listTrainings.filter(tr => tr.typeOfTraining.includes('групповая'))
        setGroupeTrainings(groupeTrainings);
    }, [trainings])

    //Обновить тренировку без перезагрузки страницы
    useEffect(() => {
        if (wasUpdateTraining && updateTraining) {
            setTrainings(prevTrainings => {
                return prevTrainings.map(training => {
                    if (training._id === updateTraining._id) {
                        // Заменить отличающиеся поля тренировки
                        return {
                            ...training,
                            name: updateTraining.name,
                            trainer: updateTraining.trainer,
                            capacity: updateTraining.capacity,
                            dateOn: updateTraining.dateOn,
                            dateTo: updateTraining.dateTo,
                            typeOfTraining: updateTraining.typeOfTraining,
                            repeat: updateTraining.repeat,
                            listOfVisits: updateTraining.listOfVisits,
                            waitingList: updateTraining.waitingList
                        };
                    }
                    // Вернуть неизмененную тренировку, если ID не совпадает
                    return training;
                });
            });
        }
    }, [wasUpdateTraining, updateTraining]);

    //Свернуть инфотул
    useEffect(() => {
        if (isInfoToolOpen) {
            setTimeout(() => {
                setIsInfoToolOpen(false); // свернуть инфотул
            }, 3000)
        }
    }, [isInfoToolOpen])

    // сбросить слушатель на редактирование тренировки
    useEffect(() => {
        if (wasUpdateTraining) {
            setTimeout(() => {
                setWasUpdateTraining(false); // сбросить слушатель на редактирование тренировки
            }, 1)
        }
    }, [wasUpdateTraining])

    //Сворачивать список записавшихся на тренировку и ожидающих при переключении дней недели(меняется массив тренировок)
    useEffect(() => {
        setIsClientsTable(false);
        setWaitingList(false);

    }, [trainings])

    //Добавить клиентов на тренировку по QRCode
    function handleOpenFormQRCode() { //Открыть считыватель qrcode
        setIsOpenQrCodeReader(true);
    }

    function handleIdQrCodeClient(id) { // получить id клиента
        setIdQrCodeClient(id);
    }

    function handleClickClose() {
        setIsOpenQrCodeReader(false);
    }

    function handleCloseQRReader() { // свернуть
        setIsOpenQrCodeReader(false);
        setIsInfoMessageTextQRCode(false);
        setIsButtonsQRCode(false);
        setIdQrCodeClient(""); // очистить id клиента, найденный по qrcode, тк без этого не будет осуществляться новый поиск
    }

    function handleClickQRCodeButtonOne() { // функция при клике на первую кнопку qrcode (записать на разовую тренировку)
        api.createListOneTimeTrain(idTrain, idQrCodeClient)
            .then(() => {
                setIsButtonsQRCode(false);
                setIsInfoMessageTextQRCode(true);
                setTextQrCodeClient("Внесен разовый визит на тренировку, не забудьте его подтвердить")
            }).catch((err) => {
                setIsButtonsQRCode(false);
                setIsInfoMessageTextQRCode(true);
                setTextQrCodeClient(`Произошла ошибка добавления разового визита ${err.message}, попробуйте повторно или отсканируйте новый QRCode`)
            })
    }

    function handleClickQRCodeButtonTwo() { // функция при клике на вторую кнопку qrcode (Свернуть форму выбора)
        setIsButtonsQRCode(false);
        setIsInfoMessageTextQRCode(false);
        setTextQrCodeClient('Отсканируйте QRCode')
        setIdQrCodeClient(""); // очистить id клиента, найденный по qrcode, тк без этого не будет осуществляться новый поиск
    }

    function handleClickQRCodeButtonThree() { // продолжить сканирование
        setTextQrCodeClient('Отсканируйте QRCode')
        setIdQrCodeClient(""); // очистить id клиента, найденный по qrcode, тк без этого не будет осуществляться новый поиск
        setIsInfoMessageTextQRCode(false);
        setIsButtonsQRCode(false);
    }

    //Добавить на тренировку при считывании QRCode
    useEffect(() => {
        if (idQrCodeClient !== "") {
            setIsLoadingQrCodeTrainingAddVisit(true);
            const training = trainings.find(training => training._id === idTrain);
            console.log(idTrain, idQrCodeClient, training.typeOfTraining)
            api.addVisitOnTrain(idTrain, idQrCodeClient, training.typeOfTraining)
                .then(() => {
                    setIsLoadingQrCodeTrainingAddVisit(false);
                    setIsInfoMessageTextQRCode(true);
                    setTextQrCodeClient("Клиент успешно добавлен на тренировку, можете продолжить сканирование")
                }).catch((err) => {
                    if (err.status === 409) {
                        if (err.message === "Клиент с такими данными уже записан на тренировку") {
                            setIsLoadingQrCodeTrainingAddVisit(false);
                            setIsInfoMessageTextQRCode(true); // показать информационное окно
                            setTextQrCodeClient("ОШИБКА: Клиент уже записан на тренировку")
                            setTimeout(() => { setTextQrCodeClient("") }, 5000)
                        } else if (err.message === "Превышена максимальная вместимость") {
                            setIsLoadingQrCodeTrainingAddVisit(false);
                            setIsInfoMessageTextQRCode(true); // показать информационное окно
                            setTextQrCodeClient("ОШИБКА: Места закончились, клиент внесен в лист ожидания")
                            setTimeout(() => { setTextQrCodeClient("") }, 5000)
                        } else if (err.message === "Необходимо продлить абонемент") {
                            setIsLoadingQrCodeTrainingAddVisit(false);
                            setIsButtonsQRCode(true); // показать кнопки выбора
                            setTextQrCodeClient("ОШИБКА: Необходимо продлить абонемент, внести разовый визит на тренировку?")
                        } else if (err.message === "Абонемент не найден") {
                            setIsLoadingQrCodeTrainingAddVisit(false);
                            setIsButtonsQRCode(true); // показать кнопки выбора
                            setTextQrCodeClient("ОШИБКА: Отсутсвует действующий абонемент, внести разовый визит на тренировку?")
                        } else {
                            setIsLoadingQrCodeTrainingAddVisit(false);
                            setIsInfoMessageTextQRCode(true); // показать информационное окно
                            setTextQrCodeClient(`Произошла ошибка : ${err.message}, попробуйте повторно или отсканируйте новый QRCode`)
                        }
                    } else {
                        console.log(err.message)
                        setIsInfoMessageTextQRCode(true); // показать информационное окно
                        setTextQrCodeClient(`Произошла ошибка : ${err.message}, попробуйте повторно или отсканируйте новый QRCode`)
                        setIsLoadingQrCodeTrainingAddVisit(false);
                    }
                })
        }
    }, [idQrCodeClient])


    return (
        <div className={`timetable ${timetableListTextStyle}`}>
            {/* ДОБАВЛЕНИЕ КЛИЕНТОВ ЧЕРЕЗ QR Code */}
            {isOpenQrCodeReader ?
                <QRCodeReader
                    isLoaderAPI={isLoadingQrCodeTrainingAddVisit}
                    handleIdClient={handleIdQrCodeClient}
                    handleCloseQRReader={handleCloseQRReader}
                    text={textQrCodeClient}
                    isButtons={isButtonsQRCode}
                    handleClickOne={handleClickQRCodeButtonOne}
                    handleClickTwo={handleClickQRCodeButtonTwo}
                    handleClickThree={handleClickQRCodeButtonThree}
                    isInfoMessageText={isInfoMessageTextQRCode}
                    handleClickClose={handleClickClose}
                />
                : <></>}
            {/* ФОРМА РЕДАКТИРОВАНИЯ ТРЕНИРОВКИ */}
            {isOpenUpdateTraining ?
                <FormUpdateTrain
                    isOpen={isOpenUpdateTraining}
                    isClose={handleCloseFormUpdateTraining}
                    trainingData={trainingData} // данные тренировки
                    trainers={trainers} // массив тренеров для показа в инпуте при выборе тренировки
                    onSubmit={handleSubmitUpdateTraining}
                    isTypeSpace={isTypeSpace} // выбор зала, true - добавить выбор
                    colorBackground={colorBackgroundForm}
                />
                : <></>}
            {isInfoToolOpen ?
                <InfoTooltip
                    isOpen={isInfoToolOpen}
                    text={isTextMassageInfoTool}
                    isClose={closeInfoTool}
                    backgoundColor={colorBackgroundForm}
                    showButton={isButtonInfoTool}
                />
                : <></>}
            {/* ПОДТВЕРЖДЕНИЕ УДАЛЕНИЯ ТРЕНИРОВКИ */}
            {isOpenConfirmationOfDeletion ?
                <InfoTooltip
                    text={'Удалить одну или все тренировки на это время?'}
                    isOpen={isOpenConfirmationOfDeletion}
                    isClose={closeConfirmationOfDeletion}
                    backgoundColor={colorBackgroundForm}
                    showButton={true}
                    textButtonOne={'Одну'} // текст кнопки
                    textButtonTwo={'Все'} // текст кнопки
                    clickButtonOne={handleRemoveOneTraining}
                    clickButtonTwo={handleRemoveManyTraining} // удалить все последущие тренировки на это время
                />
                : <></>}
            <h2 className={`timetable__title ${textTitleColor}`}>Расписание</h2>

            {/* // ПЕРЕКЛЮЧАТЕЛЬ ТРЕНИРОВОК(ОБЩИЙ ИЛИ ТОЛЬКО ГРУППОВЫЕ) */}
            {/* <Selector
                nameOne={'Общий список'}
                handleOnClickOne={handleIsChangeArrayTrains} // переключать массив тренировок ( с групповыми или без)
                switchControl={!isGroupeTrainings} // внешний контроль отображения, true -вкл свитчер и отображать все тренировки без групповых
                // styleText={styleText} // Стиль текста
                styleCheckboxButton={styleCheckboxButton}   // стиль свитчера
            /> */}
            <DayList
                textTitleColor={textTitleColor} // цвет текст
                titleTimeTable={titleTimeTable}
                timetableButtonStyle={timetableButtonStyle}
                previousWeekButtonImg={previousWeekButtonImg}
                nextMonthButtonImg={nextMonthButtonImg}
                api={api}
                setTrains={setTrainings} // изменить тренировки в зависимости от выбранного дня
                statePage={true} // какая страница открыта 
                idClient={idClient} // айди клиента 
                styleText={styleText}
            />

            <div className="timetable__list">
                {loaderTimeTable ? // данные в процессе получения - отобразить лоадер
                    <Preloader /> :
                    showTraining.length < 1 || trainings === undefined ? // если массив тренировок пустой
                        <p className={`timetable__text-no-record ${textTitleColor}`}>Записи отсутсвуют</p>
                        :
                        showTraining.map(train => {
                            //Наполнение информацией

                            // Время
                            let date = new Date(train.dateOn);
                            let dateTo = new Date(train.dateTo);

                            let dateTrain = new Date(train.dateOn);
                            let formattedDate = `${dateTrain.getDate()} ${getMonthName(dateTrain.getMonth())} ${dateTrain.getFullYear()}`;

                            let timeTo = formatTime(dateTo);
                            let time = formatTime(date);

                            // Функция для форматирования времени
                            function formatTime(date) {
                                const hours = date.getHours().toString().padStart(2, '0');
                                const minutes = date.getMinutes().toString().padStart(2, '0');
                                return `${hours}:${minutes}`;
                            }

                            // Функция для получения названия месяца
                            function getMonthName(monthIndex) {
                                const monthNames = [
                                    'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
                                    'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
                                ];
                                return monthNames[monthIndex];
                            }

                            //Тренер

                            //Инф о тренере
                            let trainerName;
                            if (train.trainer !== null && train.trainer !== undefined) {
                                trainerName = train.trainer.name;
                            } else {
                                trainerName = 'тренер'
                            }

                            return <button
                                disabled={disabledButtonTrain}
                                className={`timetable__list-element ${timeTableBackgroundColor}`}
                                id={train._id}
                                key={train._id}
                                onClick={() => { openInfoToolGetVisits(train._id, time, formattedDate) }}>
                                <div className="timetable__element" >
                                    <p className={`timetable__text  ${styleText}`}>{formattedDate}</p>
                                    <p className={`timetable__text  ${styleText} timetable__element_dateTrain `}>{train.name}</p>
                                </div>
                                <div className="timetable__element" >
                                    <p className={`timetable__text  ${styleText}`}>{time} - {timeTo}</p>
                                    <p className={`timetable__text  ${styleText}`}>{trainerName}</p>
                                </div>
                                <div className="timetable__element" >
                                    <p className={`timetable__text  ${styleText}`}>Заполненность {train.listOfVisits.length} из {train.capacity}</p>
                                    <p className={`timetable__text  ${styleText}`}>Лист ожидания {train.waitingList.length}</p>
                                </div>
                                <div className="timetable__element" >
                                    {isTypeSpace ?  // показывать ли тип зала
                                        <p className={`timetable__text  ${styleText}`}>{train.typeSpace} зал</p>
                                        :
                                        <></>}
                                </div>
                                {/* Добавленные кнопки (только для админа и выше) */}
                                {!isTrainerPage && selectedTrain === train._id && (
                                    <div className="admin-page__training-list-buttons-container">
                                        {/* РЕДАКТИРОВАТЬ ТРЕНИРОВКУ */}
                                        <div
                                            className={`training-list__button ${backgroundButtonColor}`}
                                            onClick={() => { handleOpenFormUpdateTraining(train) }}>
                                            <img className='training-list__img-button' src={imgUpdateTraining} alt='imgAddTraining' />
                                        </div>
                                        {/* ДОБАВИТЬ КЛИЕНТОВ НА ТРЕНИРОВКУ ПО QR */}
                                        <div
                                            className={`training-list__button ${backgroundButtonColor}`}
                                            onClick={handleOpenFormQRCode}>
                                            <img className='training-list__img-button' src={imgQRCode} alt='imgQRCode' />
                                        </div>
                                        {/* УДАЛИТЬ ТРЕНИРОВКУ */}
                                        <div
                                            className={`training-list__button ${backgroundButtonColor}`}
                                            onClick={handleOpenFormRemoveTraining}>
                                            <img className='training-list__img-button' src={imgRemoveTraining} alt='imgAddTraining' />
                                        </div>
                                    </div>
                                )}
                            </button>
                        })
                }
            </div>
            {/* Список клиентов, записанных на тренировку */}
            {isClientsTable ?
                <>
                    <h2 className={`timetable__title ${textTitleColor} ${styleText}`}>Список клиентов</h2>
                    <ClientsTable
                        fullInfoClient={false} // true - полная информация по абонементу, false -краткая информация по клиенту ( только имя, телефон и индикатор абонемента)
                        clients={clients} // список клиентов для отображения в таблице
                        handleActiveClientInfo={сlickOnClients} //управлять отображением кнопок Добавить клиента и др
                        infoFlex={infoFlex}// давать ли информацию по абонементам Flex
                        infoDark={infoDark} // давать ли информацию по абонементам Dark
                        admin={true} // проверка страница admin или нет (true - admin)
                        handleNameClient={сlickOnClients}// пробросить из ClientInfo для WA
                        handlePhoneNumberClient={сlickOnClients} // пробросить из ClientInfo для WA
                        backgroundColor={'clients-table_background'} // стиль фона
                    />
                </>
                : <></>}
            {/* Список клиентов, в листе ожидания */}
            {isClientsTable ?
                <>
                    <h2 className={`timetable__title ${textTitleColor} ${styleText}`}>Лист ожидания</h2>
                    <ClientsTable
                        fullInfoClient={false} // true - полная информация по абонементу, false -краткая информация по клиенту ( только имя, телефон и индикатор абонемента)
                        clients={waitingList} // список клиентов для отображения в таблице
                        handleActiveClientInfo={сlickOnClients} //управлять отображением кнопок Добавить клиента и др
                        infoFlex={infoFlex}// давать ли информацию по абонементам Flex
                        infoDark={infoDark} // давать ли информацию по абонементам Dark
                        admin={true} // проверка страница admin или нет (true - admin)
                        handleNameClient={сlickOnClients}// пробросить из ClientInfo для WA
                        handlePhoneNumberClient={сlickOnClients} // пробросить из ClientInfo для WA
                        backgroundColor={'clients-table_background'}
                        styleText={styleText}
                    />
                </>
                : <></>}
        </div >
    )
}

export default TrainingList;