//AdminPage->this

import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import TrainingList from '../TrainingList/TrainingList';
import Selector from '../Selector/Selector';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/ru';

import FormUpdateTrain from '../FormUpdateTrain/FormUpdateTrain';
import InfoTooltip from '../InfoTooltip/InfoTooltip';
import WindowClientsControl from '../WindowClientsControl/WindowClientsControl';

import imgClients from '../../images/group-svgrepo-com.svg'

const localizer = momentLocalizer(moment);

const TrainingCalendar = ({
  trainings, // массив тренировок (все тренировки)
  isBig, // true - показывать боль
  styleText, // стиль текста
  // ДЛЯ СПИСКА
  previousWeekButtonImg, // изображение стрелки перекл дней недели
  nextMonthButtonImg,// изображение стрелки перекл дней недели
  api, // передать АПИ студии из компонента студии в которое монтируется расписание
  infoFlex,// давать ли информацию по абонементам Flex
  infoDark, // давать ли информацию по абонементам Dark
  isTrainerPage, // true - страница тренера
  idTrainer, // айди тренера, если страница тренера(в TrainingList отображаются только его тренировки)
  imgUpdateTraining, // изображение кнопки редактирования тренировки
  imgRemoveTraining, // изображение кнопки удаления тренировки
  imgQRCode, // изображение кнопки qrcode
  trainers, // массив тренеров для показа в инпуте при выборе тренировки 
  backgoundColorPage,
  //Selector 
  styleCheckboxButton, // стиль свитчера
  //Days
  timetableWeekButtonStyle, // стиль кнопок переключения дней недели
  //Форма редактирования тренировки
  isTypeSpace, // выбор зала, true - добавить выбор
  colorBackgroundForm, // цвет фона
  textTitleColor, // цвет титульника
  //Слушать добавления тренировок 
  isChangeTrainingsListHandle,
  newTrain, // новая тренировка для отрисовке в массиве

}) => {

  const [isGroupeTrainings, setIsGroupeTrainings] = useState(false); // отображать только групповые тренировки
  const [isSpaceControl, setIsSpaceControl] = useState(false); // управлять залами, true - малый 
  const [groupeTrainings, setGroupeTrainings] = useState([]) // массив только групповых тренировок
  const [showTraining, setShowTraining] = useState([]) // какой массив тренировок показывать
  //Инфотул
  const [isInfoToolOpen, setIsInfoToolOpen] = useState(false); // стейт для открытия информационного окна
  const [isButtonInfoTool, setButtonInfoTool] = useState(false); // отображать или нет кнопки в инфотуле
  const [isTextMassageInfoTool, setTextMassageInfoTool] = useState(''); // текс в инфотул
  //Календарь
  const [isChangeArrayTrains, setIsChangeArrayTrains] = useState(true); // true - отображать все тренировки,false -только те, гдет тренер ведет(актуально для страницы Тренера TrainerPage) 
  //форма редактирования 
  const [isOpenUpdateTraining, setIsOpenUpdateTraining] = useState(false);
  const [trainingData, setTrainingsData] = useState({}); // объект с данными по тренировке, заполняется при клике на тренировку и передается в форму редактирования тренировки
  const [dateSelectedTraining, setDateSelectedTraining] = useState(new Date()); // дата выбранной тренировки
  const [timeSelectedTraining, setTimeSelectedTraining] = useState(new Date()); // время выбранной тренировки
  const [textButtonOne, settextButtonOne] = useState('');
  const [textButtonTwo, settextButtonTwo] = useState('');
  const [textButtonThree, settextButtonThree] = useState('');
  //Работа с тренировкой
  const [isUpdateTraining, setIsUpdateTraining] = useState(false); // работа с редактирование тренировки
  const [isRemoveTraining, setIsRemoveTraining] = useState(false); // работа с удалением тренировки
  //Работа с клиентами на тренировки
  const [isWindowClientsControl, setIsWindowClientsControl] = useState(false);


  const optionsDate = {
    timeZone: 'Asia/Yekaterinburg',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  };

  const optionsTime = {
    timeZone: 'Asia/Yekaterinburg',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  };

  const formatterDate = new Intl.DateTimeFormat('ru-RU', optionsDate);
  const formatterTime = new Intl.DateTimeFormat('ru-RU', optionsTime);

  const messages = {
    allDay: 'весь день',
    previous: 'предыдущий',
    next: 'следующий',
    today: 'сегодня',
    month: 'месяц',
    week: 'неделя',
    day: 'день',
    agenda: 'повестка дня',
    date: 'дата',
    time: 'время',
    event: 'событие',
  };

  //Наполнение тренировки
  const events = showTraining.map(training => {
    //Инф о тренере
    let trainerName;
    let typeOfTraining;
    if (training.trainer !== null && training.trainer !== undefined) {
      const firstLetter = training.trainer.surname[0];
      trainerName = `${training.trainer.name} ${firstLetter}`;
    } else {
      trainerName = 'тренер'
    }

    if (training.typeOfTraining.includes('групповая')) {
      typeOfTraining = 'групповая'
    } else if (training.typeOfTraining.includes('сплит')) {
      typeOfTraining = 'сплит'
    } else if (training.typeOfTraining.includes('персональная')) {
      typeOfTraining = 'персональная'
    } else {
      typeOfTraining = training.typeOfTraining;
    }

    const name = `${training.name} ${trainerName} ${typeOfTraining}`;
    const nameTrainer = trainerName;
    const start = new Date(training.dateOn);
    const end = new Date(training.dateTo);

    return {
      id: training._id,
      title: name,
      start: start,
      end: end,
    }
  });

  const eventContentRenderer = ({ event }) => (
    <div>
      <div>{event.title}</div>
      <div>{event.start.toString()}</div>
      <div>{event.end.toString()}</div>
      <div>{event.description}</div>
    </div>
  );

  //Стиль ячеек
  const getEventStyle = (event) => {
    const backgroundColor =
      event.title.includes('групповая') ? 'grey' :
        event.title.includes('персональная') ? 'black' :
          'rgba(0,0,0, 0.5)';


    const style = {
      backgroundColor: backgroundColor,
    };

    return {
      style: style,
    };
  };

  //Переключать тренировки на все или на без групповых
  function handleIsChangeArrayTrains() {
    if (isGroupeTrainings) {
      setIsGroupeTrainings(false);
    } else {
      setIsGroupeTrainings(true);
    }
  }

  //Переключать залы
  function handleIsChangeArraySpace() {
    if (isSpaceControl) {
      setIsSpaceControl(false)
    } else {
      setIsSpaceControl(true);
    }
  }

  //Наполнить массив групповых тренировок
  useEffect(() => {
    const groupeTrainings = trainings.filter((training) => training.typeOfTraining.includes('групповая'));
    setGroupeTrainings(groupeTrainings)
  }, [trainings])

  //Менять массив тренировок для отображения в зависимости от переключателя
  useEffect(() => {

    if (infoFlex) { // если Flex 
      if (isGroupeTrainings) { // если показывать только групповые

        setShowTraining(groupeTrainings)
      } else { // если все тренировки
        setShowTraining(trainings)
      }
    }

    if (infoDark) { // если Dark
      let sortedTrainings = [];
      if (!isSpaceControl && isGroupeTrainings) {  // если большой зал и только групповые
        sortedTrainings = groupeTrainings.filter((train) => train.typeSpace === 'большой')
        setShowTraining(sortedTrainings);
      } else if (!isSpaceControl && !isGroupeTrainings) { // если большой зал и все тренировки
        sortedTrainings = trainings.filter((train) => train.typeSpace === 'большой')
        setShowTraining(sortedTrainings);
      } else if (isSpaceControl && isGroupeTrainings) { // если малый зал и только групповые
        sortedTrainings = groupeTrainings.filter((train) => train.typeSpace === 'малый')
        setShowTraining(sortedTrainings);
      } else if (isSpaceControl && !isGroupeTrainings) { // если малый зал и все тренировки
        sortedTrainings = trainings.filter((train) => train.typeSpace === 'малый')
        setShowTraining(sortedTrainings);
      }
    }

  }, [isGroupeTrainings, trainings, groupeTrainings, isSpaceControl])

  // Установка временного диапазона от 07:00 до 23:59:59
  const minTime = moment().set({ hour: 7, minute: 0, second: 0 });
  const maxTime = moment().set({ hour: 23, minute: 59, second: 59 });

  //Цвет текста и бордюров
  useEffect(() => {

    if (infoDark && isBig && !isWindowClientsControl) { // если Дарк и открыт большой календарь, то добавить стиль текста
      document.querySelector('.trainig-calendar-container').classList.add('trainig-calendar-container_dark');
    }

  }, [infoDark, isBig])

  //Инфотул------------------------Инфотул------------------------Инфотул------------------------Инфотул------------------------
  function closeInfoTool() {
    setIsInfoToolOpen(false);
    if (isRemoveTraining) {
      setIsRemoveTraining(false);
    }
  }

  //Клик на первую кнопку(слева)
  function clickButtonOne() {
    if (isRemoveTraining) { // будет удаление одной тренировки
      handleRemoveOneTraining();
    } else {
      // если редактировать, то открыть форму редактирования
      setIsInfoToolOpen(false); // свернуть инфотул выбора
      setIsOpenUpdateTraining(true); // открыть форму редактирования тренировки
    }
  }

  //Клик на  кнопку(справа)
  function clickButtonTwo() {
    if (isUpdateTraining) {
      settextButtonThree("")
      settextButtonOne(
        <div
          className={`training-list__button`}>
          <img className='training-list__img-button' src={imgUpdateTraining} alt='imgAddTraining' />
        </div>
      );
      settextButtonTwo('Все');
    } else {
      settextButtonThree("")
      setIsRemoveTraining(true); // перевести в работу с удалением тренировок
      setTextMassageInfoTool(`Удалить одну (${dateSelectedTraining}) или все тренировки в ${timeSelectedTraining} ?`);
      settextButtonOne('Одну');
      settextButtonTwo('Все');
      if (isRemoveTraining) {
        handleRemoveManyTraining();
      }
    }
  }

  //РАБОТА С ТРЕНИРОВКАМИ 
  //Клик на тренировку
  function handleEventClick(event) {

    const trainingClick = trainings.find(training => training._id === event.id);
    setTrainingsData(trainingClick);
    setIsInfoToolOpen(true); // открыть инфотул
    setButtonInfoTool(true); // показывать кнопки

    const formattedDate = formatterDate.format(new Date(trainingClick.dateOn));
    const formattedTime = formatterTime.format(new Date(trainingClick.dateOn));
    setDateSelectedTraining(formattedDate);
    setTimeSelectedTraining(formattedTime);

    setTextMassageInfoTool(`Действия с тренировкой ${formattedDate} в ${formattedTime}`);
    settextButtonOne(
      <div
        className={`training-list__button`}>
        <img className='training-list__img-button' src={imgUpdateTraining} alt='imgAddTraining' />
      </div>
    );
    settextButtonTwo(
      <div
        className={`training-list__button `}>
        <img className='training-list__img-button' src={imgRemoveTraining} alt='imgAddTraining' />
      </div>
    );
    settextButtonThree(
      <div
        className={`training-list__button`}>
        <img className='training-list__img-button' src={imgClients} alt='imgClients' />
      </div>
    );
  }

  //Создать тренировку
  const handleSelectSlot = (slotInfo) => {
    // slotInfo содержит информацию о выбранном пустом слоте
    console.log('Selected Slot Info:', slotInfo);
    // Здесь вы можете выполнить нужные действия при клике на пустое поле
  };

  // обновить тренировку
  function handleSubmitUpdateTraining(dataTraining) {
    api.updateTrain(trainingData._id, dataTraining.nameTrain, dataTraining.trainer, dataTraining.capacity)
      .then((upadeTraining) => {

        // Функция для обновления объекта в массиве
        const updateShowTrainings = (updatedObject) => {
          // Используем метод map() для прохода по массиву объектов
          const updatedTrainings = showTraining.map((training) => {
            // Если id объекта совпадает с заданным id, заменяем его на обновленный объект
            if (training._id === updatedObject._id) {
              return updatedObject;
            } // Если id не совпадает, возвращаем исходный объект без изменений
            return training;
          });
          setShowTraining(updatedTrainings) // Обновляем стейт с новым массивом объектов
        };

        updateShowTrainings(upadeTraining.update);

        setButtonInfoTool(false); // показывать кнопки
        setTextMassageInfoTool('Успешно');
        setIsOpenUpdateTraining(false);
        setTimeout(() => {
          setIsInfoToolOpen(false);
        }, 3000)  // скрыть инфотул 
      }).catch((err) => {

        setButtonInfoTool(false); // показывать кнопки
        setTextMassageInfoTool(`Ошибка : ${err.message}`);
        setTimeout(() => {
          setIsInfoToolOpen(false);
        }, 3000)  // скрыть инфотул 
      });
  }


  //Открыть работу с клиентами на тренировке 
  function clickButtonThree() {
    openWindowClientsControl()
    settextButtonThree("")
  }

  //Свернуть форму редактирования тренировки
  function handleCloseFormUpdateTraining() {
    setIsOpenUpdateTraining(false);
  }

  //Удалить тренировку

  //удалить тренировку(одну)
  function handleRemoveOneTraining() {
    api.removeTrain(trainingData._id)
      .then((train) => {
        const updateTrainings = trainings.filter(training => training._id !== trainingData._id)
        setShowTraining(updateTrainings);
        setTextMassageInfoTool(`Тренировка ${dateSelectedTraining} в ${timeSelectedTraining} удалена`);
        setButtonInfoTool(false);
        setTimeout(() => { setIsInfoToolOpen(false) }, 3000)
        setIsChangeArrayTrains(true);
      }).catch((err) => {
        setTextMassageInfoTool(`Произошла ошибка ${err.message}`)
        setButtonInfoTool(false);
        setTimeout(() => { setIsInfoToolOpen(false) }, 3000)
      });
  }

  //удалить тренировки на конкретное время
  function handleRemoveManyTraining() {
    api.removeManyTrain(trainingData._id)
      .then((train) => {
        const updateTrainings = trainings.filter(training => training._id !== trainingData._id)
        setShowTraining(updateTrainings);
        setTextMassageInfoTool(`Тренировки в ${timeSelectedTraining} удалены`);
        setButtonInfoTool(false);
        setTimeout(() => { setIsInfoToolOpen(false) }, 3000)
        setIsChangeArrayTrains(true);
      }).catch((err) => {
        setIsInfoToolOpen(true);
        setTextMassageInfoTool(`${err.message}`)
        setButtonInfoTool(false);
        setTimeout(() => { setIsInfoToolOpen(false) }, 3000)
      });
  }

  //Работа с клиентами на тренировке
  function openWindowClientsControl() {
    setIsWindowClientsControl(true);
    setIsInfoToolOpen(false);
  }

  function closeWindowClientsControl() {
    setIsWindowClientsControl(false);
    setIsInfoToolOpen(false);
  }

  useEffect(() => {
    return () => {
      setIsWindowClientsControl(false);
    };
  }, []);

  useEffect(() => {
    if (!trainingData) {
      setIsWindowClientsControl(false);
    }
  }, [trainingData])


  return (
    isBig ?
      isWindowClientsControl ?
        // ОКНО РАБОТЫ С КЛИЕНТАМИ НА ТРЕНИРОВКЕ 
        < WindowClientsControl
          backgoundColorPage={backgoundColorPage}
          textTitleColor={textTitleColor}
          infoFlex={infoFlex}
          infoDark={infoDark}
          api={api}
          training={trainingData}
          close={closeWindowClientsControl}
        />
        :
        <>
          <h2 className={`timetable__title timetable__title_calendar ${textTitleColor}`}>Расписание</h2>
          <Selector nameOne={'Групповые | Общий список'}
            handleOnClickOne={handleIsChangeArrayTrains} // переключать массив тренировок ( с групповыми или без)
            switchControl={!isGroupeTrainings} // внешний контроль отображения, true -вкл свитчер и отображать все тренировки без групповых
            styleCheckboxButton={styleCheckboxButton}   // стиль свитчера
          />
          {infoDark ?
            <Selector nameOne={'Большой | Малый зал'}
              handleOnClickOne={handleIsChangeArraySpace} // переключать массив тренировок по залам ( большой или малый зал)
              switchControl={isSpaceControl} // внешний контроль отображения, true -вкл свитчер и отображать все тренировки без групповых
              styleCheckboxButton={styleCheckboxButton}   // стиль свитчера
            />
            : <></>}
          <div className='trainig-calendar-container'>
            <Calendar
              defaultView="week" // Устанавливаем начальный вид как недельный
              onSelectEvent={handleEventClick} // Добавляем обработчик клика на событие
              onSelectSlot={handleSelectSlot} // Обработчик клика на пустое поле
              localizer={localizer}
              events={events}
              // views={views}
              views={['week', 'day']}
              components={{
                eventContentRenderer
              }}
              messages={messages}
              min={minTime.toDate()} // Преобразование в объект Date
              max={maxTime.toDate()} // Преобразование в объект Date
              eventPropGetter={getEventStyle} // Применяем функцию getEventStyle
            />
          </div>
          {/* ФОРМА РЕДАКТИРОВАНИЯ ТРЕНИРОВКИ */}
          {isOpenUpdateTraining ?
            <FormUpdateTrain
              isOpen={isOpenUpdateTraining}
              isClose={handleCloseFormUpdateTraining}
              trainingData={trainingData} // данные тренировки
              trainers={trainers} // массив тренеров для показа в инпуте при выборе тренировки
              onSubmit={handleSubmitUpdateTraining}
              isTypeSpace={isTypeSpace} // выбор зала, true - добавить выбор
              colorBackground={colorBackgroundForm}
            />
            : <></>}
          {/* ИНФОТУЛ ВЫБОРА РАБОТЫ С ТРЕНИРОВКОЙ */}
          <InfoTooltip
            isOpen={isInfoToolOpen} // управлять открытием
            isClose={closeInfoTool} // управлять сворачиваением
            text={isTextMassageInfoTool} // передать текст
            showButton={isButtonInfoTool} // показывать кнопки или нет
            clickButtonOne={clickButtonOne} // дейсвтие при клике
            clickButtonTwo={clickButtonTwo} // дейсвтие при клике
            clickButtonThree={clickButtonThree} // дейсвтие при клике
            backgoundColor={backgoundColorPage} // цвет фона
            textButtonOne={textButtonOne}
            textButtonTwo={textButtonTwo}
            textButtonThree={textButtonThree}
            isButtonStyleBorder={true} // true - без заливки фона
          />
        </>
      :
      <TrainingList
        previousWeekButtonImg={previousWeekButtonImg} // изображение стрелки перекл дней недели
        nextMonthButtonImg={nextMonthButtonImg} // изображение стрелки перекл дней недели
        api={api} // АПИ
        infoFlex={infoFlex}// давать ли информацию по абонементам Flex
        infoDark={infoDark} // давать ли информацию по абонементам Dark
        isTrainerPage={isTrainerPage} // true - страница тренера
        idTrainer={idTrainer} // айди тренера, если страница тренера(в TrainingList отображаются только его тренировки)
        imgUpdateTraining={imgUpdateTraining} // изображение кнопки редактирования тренировки
        imgRemoveTraining={imgRemoveTraining} // изображение кнопки удаления тренировки
        imgQRCode={imgQRCode} // изображение кнопки qrcode
        trainers={trainers} // массив тренеров для показа в инпуте при выборе тренировки
        backgoundColorPage={backgoundColorPage} // для инфотула
        styleText={styleText} // Стиль текста
        styleCheckboxButton={styleCheckboxButton}   // стиль свитчера
        textTitleColor={textTitleColor} // цвет титульника
        //Days
        timetableWeekButtonStyle={timetableWeekButtonStyle} // стиль кнопок переключения дней недели
        //Форма редактирования тренировки
        isTypeSpace={isTypeSpace} // выбор зала, true - добавить выбор
        colorBackgroundForm={colorBackgroundForm} // цвет фона
        isChangeTrainingsListHandle={isChangeTrainingsListHandle} // слушатель добавления новой тренировки
        newTrain={newTrain} // новая тренировка для отрисовки после добавления
      />
  );
};

export default TrainingCalendar;
