import { useEffect, useState } from "react";
import FormAdd from "../FormAdd/FormAdd";

// TrainingList -> this component

function FormUpdateTrain({
    isOpen,
    isClose,
    onSubmit, // действия при сабмите
    colorBackground, // цвет фона
    colorButtonSubmit, // стиль кнопки сабмита
    trainers, // список тренеров для инпута
    trainingData, // данные тренировки для первоначальной отрисовки в инпутах
    isTypeSpace, // нужно ли добавить инпут с выбором зала 
}) {

    // Стейты, в которых содержятся значения инпута и информационный текст
    const [nameTrain, setNameTrain] = useState('') // название тренировки
    const [trainer, setTrainer] = useState(trainingData !== undefined ? trainingData.trainer : "");    // тренер
    const [numberOfPeople, setNumberOfPeople] = useState('') // максимальное количество людей на тренировке
    const [typeSpace, setTypeSpace] = useState('') // тип зала
    const [validationMassegeTypeSpace, setValidationMassegeTypeSpace] = useState('Тип Зала');
    const [validationMassegeNameTrain, setValidationMassegeNameTrain] = useState('Введите название тренировки');
    const [validationMassegeTrainer, setValidationMassegeTrainer] = useState('Укажите тренера')
    const [validationMessageNumberOfPeople, setValidationMessageNumberOfPeople] = useState('Введите max вместимость'); //
    const [validationMassegeQuantityVisitsOn, setValidationMassegeQuantityVisitsOn] = useState('');
    const [validationMassegeQuantityVisits, setValidationMassegeQuantityVisits] = useState('');
    // стейты управления
    const [isFormEdited, setIsFormEdited] = useState(true); // управлять кнопкой сабмита ( для редактироваия), чтобы отправить, только после изменения одного из полей минимум
    const [isSuperAdmin, setIsSuperAdmin] = useState(false); // суперадмин или нет

    function cleanInput() {

    }

    //передать данные  из инпутов
    function handleChangeNameTrain(e) { // слушать изменение названия тренировки
        setNameTrain(e.target.value);
        if (e.target.validationMessage === '') {
            setValidationMassegeNameTrain('название тренировки')
        } else {
            setValidationMassegeNameTrain(e.target.validationMessage);
        }
    }

    function handleChangeNumberOfPeople(e) { // слушать изменение количества max посещений
        setNumberOfPeople(e.target.value);
        if (e.target.validationMessage === '') {
            setValidationMessageNumberOfPeople('max вместимость');
        } else {
            setValidationMessageNumberOfPeople(e.target.validationMessage);
        }
    }

    function handleChangeTrainer(e) { // слушать изменение выбранного тренера для тренировки
        const selectedTrainer = trainers.find(trainer => trainer._id === e.target.value); // Находит тренера с соответствующим _id в списке тренеров
        setTrainer(selectedTrainer._id);
    }

    function handleChangeTypeSpace(e) { // слушать изменения выбора зала
        setTypeSpace(e.target.value);
    }


    //Передать данные = отправка на сервер 
    function handleSubmit(e) {
        e.preventDefault();

        let data = {
            nameTrain,
            trainer,
            capacity: numberOfPeople,
        };

        if (isTypeSpace) { // если необходимо добавить зал
            data.typeSpace = typeSpace;
        }

        onSubmit(data);
    }

    //Свернуть форму
    function handleCloseForm() {

    }

    //очистить инпуты после сабмита
    useEffect(() => {
        cleanInput() // сбросить значения инпута
        setValidationMassegeQuantityVisits('Ставится автоматически, проставлять не нужно');
    }, [])

    //Дисейблить кнопку, если нет изменений
    useEffect(() => {
        const fields = [nameTrain, trainer, numberOfPeople];
        const initialValues = [trainingData.name, trainingData.trainer._id, trainingData.capacity];
        const allFieldsFilled = fields.some((field, index) => field !== initialValues[index]);
        setIsFormEdited(!allFieldsFilled);
    }, [nameTrain, trainer, numberOfPeople, trainingData]);

    //Проставить начальные значения инпутов
    useEffect(() => {
        if (trainingData) {
            setNameTrain(trainingData.name);
            setTrainer(trainer._id);
            setNumberOfPeople(trainingData.capacity);
        }
    }, [trainingData]);

    return (
        <FormAdd
            onSubmit={handleSubmit} // сабмит
            isOpen={isOpen} // стейт контролирующий открытие
            isClose={isClose} // клик на кнопку для сворачивания
            buttonCloseDisable={false} // отключить кнопку закрытия
            colorButtonSubmit={colorButtonSubmit} // стиль кнопки сабмита
            colorBackground={colorBackground} // управление цветом формы и инпутов
            text={'Редактировать тренировку'} // заголовок формы
            textButtonSubmit={'Редактировать'} // текст кнопки сабмита
            validationOptions={isFormEdited} // управлять кнопкой сабмита в зависимости от того, были заполненны поля или нет
            children={
                <>
                    {/* Название тренировки */}
                    <div className="formadd__input-conainer">
                        <input value={nameTrain}
                            type="string"
                            placeholder="Название тренировки"
                            className={`${colorBackground} formadd__input formadd__input_name_image`}
                            onChange={handleChangeNameTrain}
                            minLength="5" maxLength="30"
                        />
                        <span className="formadd__text-error" id="error-firstname">{validationMassegeNameTrain}</span>
                    </div>
                    {/* Максимальное количество людей на тренировке */}
                    <div className="formadd__input-conainer">
                        <input
                            type="number"
                            placeholder="Вместимость"
                            className={`${colorBackground} formadd__input formadd__input_name_image`}
                            value={numberOfPeople}
                            onChange={handleChangeNumberOfPeople}
                            min="1"
                            max="50"
                        />
                        <span className="formadd__text-error" id="error-firstname">
                            {validationMessageNumberOfPeople}
                        </span>
                    </div>
                    {/* Тренер */}
                    <div className="formadd__input-conainer">
                        <select
                            value={trainer}
                            onChange={handleChangeTrainer}
                            className={`${colorBackground} formadd__input formadd__input_name_image formadd__input-select`}
                        >
                            {/* <option value="">Выберите Тренера</option> Добавить пустой вариант */}
                            {trainers !== undefined
                                ? trainers.map((trainerThis) => {

                                    return (
                                        <option key={trainerThis._id} value={trainerThis._id}>{trainerThis.name} {trainerThis.surname}</option>
                                    );
                                })
                                : <></>
                            }
                        </select>
                        <span className="formadd__text-error" id="error-firstname">{validationMassegeTrainer}</span>
                    </div>
                    {/* Тип зала */}
                    {isTypeSpace ?
                        <div className="formadd__input-conainer">
                            <select
                                value={typeSpace}
                                onChange={handleChangeTypeSpace}
                                className={`${colorBackground} formadd__input formadd__input_name_image formadd__input-select`}
                            >
                                <option value="">Выберите Зал</option> {/* Добавить пустой вариант */}
                                <>
                                    <option className="formadd__select-option" value="большой">Большой</option>
                                    <option value="малый" className="formadd__select-option">Малый</option>
                                </>
                            </select>
                            <span className="formadd__text-error" id="error-firstname">{validationMassegeTypeSpace}</span>
                        </div>
                        : <></>}
                </>
            }
        />
    )
}

export default FormUpdateTrain;