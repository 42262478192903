import { useEffect, useState } from "react";
import FormAdd from "../FormAdd/FormAdd";

// AboutClientPage -> this component

function FormAddAbonement({
    isOpen,
    isClose,
    onSubmit, // действия при сабмите
    textButtonSubmit, // текст кнопки сабмита
    colorBackground, // цвет фона
    colorButtonSubmit, // стиль кнопки сабмита
    nameClient, // имя Клиента
    abonementClient, // данные для абонемента
    text, // заголовок
    isCheckGroup, // свитчер для отслеживания работы с групповым абонементом или персональным
    abonementCosts, // стоимость абонементов
}) {

    // Стейты, в которых содержятся значения инпута и информационный текст
    const [dateOn, setDateOn] = useState(''); // дата начала
    const [dateTo, setDateTo] = useState(''); // до какого числа
    const [quantityVisitsOn, setQuantityVisitsOn] = useState(''); // сколько изначально было тренировок
    const [quantityVisits, setQuantityVisits] = useState(''); // сколько из них осталось
    const [cost, setCost] = useState(''); // стоимость абонемента
    const [typeTrain, setTypeTrain] = useState('') // тип тренировки
    const [isDiscont, setIsDiscont] = useState(false) // применять ли скидку к покупке нового абонемента
    const [discont, setDiscont] = useState(0) // процент скидки к абонементу
    const [validationMassegeDateOn, setValidationMassegeDateOn] = useState('Дата начала'); // сообщение валидации даты тренировки
    const [validationMassegeDateTo, setValidationMassegeDateTo] = useState('Дата окончания'); // сообщение валидации даты тренировки
    const [validationMassegeTypeTrain, setValidationMassegeTypeTrain] = useState('Выберете тип тренировки'); // сообщение валидации типа тренировки
    const [validationMassegeQuantityVisitsOn, setValidationMassegeQuantityVisitsOn] = useState('Количество тренировок');
    const [validationMassegeQuantityVisits, setValidationMassegeQuantityVisits] = useState('');
    const [validationMassegeCost, setValidationMassegeCost] = useState('Стоимость абонемента'); // сообщение валидации цены
    // стейты управления
    const [isFormEdited, setIsFormEdited] = useState(true); // управлять кнопкой сабмита ( для редактироваия), чтобы отправить, только после изменения одного из полей минимум
    const [isErrorInputTypeTrain, setisErrorInputTypeTrain] = useState(false); // отображать ошибку валидации инпута

    //Валидация
    useEffect(() => {

        //тип абонемента
        if (validationMassegeTypeTrain === 'Выберете тип тренировок') {
            document.getElementById('error-typeoftrainings').classList.add('formadd__text-error_show')
        } else {
            if (document.getElementById('error-typeoftrainings').classList.contains('formadd__text-error_show')) {
                document.getElementById('error-typeoftrainings').classList.remove('formadd__text-error_show');
            }
        }
        //ДАТА НАЧАЛА АБОНЕМЕНТА
        if (validationMassegeDateOn === 'Укажите дату начала' && dateOn === '') {
            document.getElementById('error-dateon').classList.add('formadd__text-error_show')
        } else {
            if (document.getElementById('error-dateon').classList.contains('formadd__text-error_show')) {
                document.getElementById('error-dateon').classList.remove('formadd__text-error_show');
            }
        }
        //ДАТА ОКОНЧАНИЯ АБОНЕМЕНТА
        if (validationMassegeDateTo === 'Укажите дату окончания' && dateTo === '') {
            document.getElementById('error-dateto').classList.add('formadd__text-error_show')
        } else {
            if (document.getElementById('error-dateto').classList.contains('formadd__text-error_show')) {
                document.getElementById('error-dateto').classList.remove('formadd__text-error_show');
            }
        }

        //КОЛИЧЕСТВО ТРЕНИРОВОК
        if ((validationMassegeQuantityVisitsOn === 'Укажите количество тренировок' && quantityVisitsOn === '') ||
            (validationMassegeQuantityVisitsOn === 'Укажите количество тренировок' && quantityVisitsOn === 0)
        ) {
            document.getElementById('error-quantity').classList.add('formadd__text-error_show')
        } else {
            if (document.getElementById('error-quantity').classList.contains('formadd__text-error_show')) {
                document.getElementById('error-quantity').classList.remove('formadd__text-error_show');
            }
        }
        //СТОИМОСТЬ
        if ((validationMassegeCost === 'Укажите стоимость абонемента' && cost === '') ||
            (validationMassegeCost === 'Укажите стоимость абонемента' && cost === 0)
        ) {
            document.getElementById('error-cost').classList.add('formadd__text-error_show')
        } else {
            if (document.getElementById('error-cost').classList.contains('formadd__text-error_show')) {
                document.getElementById('error-cost').classList.remove('formadd__text-error_show');
            }
        }
        console.log(quantityVisitsOn)
    }, [
        validationMassegeTypeTrain,
        validationMassegeDateOn,
        dateOn,
        validationMassegeDateTo,
        dateTo,
        validationMassegeQuantityVisitsOn,
        quantityVisitsOn,
        validationMassegeCost,
        cost,
    ]);

    //Валидация кнопки сабмита
    useEffect(() => {
        if (
            dateOn !== '' &&
            dateTo !== '' &&
            quantityVisitsOn !== '' &&
            quantityVisitsOn !== 0 &&
            cost !== 0 &&
            cost !== ''
        ) {
            setIsFormEdited(false)
        } else {
            setIsFormEdited(true)
        }
    }, [
        validationMassegeTypeTrain,
        validationMassegeDateOn,
        validationMassegeDateTo,
        validationMassegeQuantityVisitsOn,
        quantityVisitsOn,
        validationMassegeCost,
        dateOn,
        dateTo,
        cost,
    ])

    //передать данные  из инпутов
    function handleChangeDateOn(e) { // дата начала абонемента
        setDateOn(e.target.value);
        //setIsFormEdited(false);
        setValidationMassegeDateOn(e.target.validationMessage);
        if (e.target.validationMessage === '') {
            setValidationMassegeDateOn('Дата начала')
        } else {
            setValidationMassegeDateOn(e.target.validationMessage);
        }
    }

    function handleChangeDateTo(e) { // дата окончания абонемента
        setDateTo(e.target.value);
        setValidationMassegeDateTo(e.target.validationMessage);
        if (e.target.validationMessage === '') {
            setValidationMassegeDateTo('Дата окончания')
        } else {
            setValidationMassegeDateTo(e.target.validationMessage);
        }
    }

    function handleChangeQuantityVisitsOn(e) { // количество тренировок в абонементе
        let numberFromStr = +e.target.value;

        setQuantityVisitsOn(numberFromStr);
        setValidationMassegeQuantityVisitsOn(e.target.validationMessage);
        if (e.target.validationMessage === '') {
            setValidationMassegeQuantityVisitsOn('количество тренировок')
        } else {
            setValidationMassegeQuantityVisitsOn(e.target.validationMessage);
        }
    }

    function handleChangeTrainType(e) { // слушать изменение типа абонемента
        setTypeTrain(e.target.value);

        if (e.target.validationMessage === '') {
            setValidationMassegeTypeTrain('тип абонемента')
            setisErrorInputTypeTrain(false);
        } else {
            setisErrorInputTypeTrain(true);
            setValidationMassegeTypeTrain(e.target.validationMessage);
        }
    }

    //Слушать изменения цены
    function handleChangeCost(e) {
        setCost(e.target.value);
        if (e.target.validationMessage === '') {
            setValidationMassegeCost('стоимость абонемента')
        } else {
            setValidationMassegeCost(e.target.validationMessage);
        }
    }

    // Передать данные = отправка на сервер 
    function handleSubmit(e) {
        e.preventDefault();
        // Преобразование даты начала 
        const dateOnWithTimeZone = new Date(dateOn);
        // Преобразование даты окончания 
        const dateToWithTimeZone = new Date(dateTo);
        onSubmit({
            dateOn: dateOnWithTimeZone,
            dateTo: dateToWithTimeZone,
            quantityVisitsOn,
            quantityVisits: quantityVisitsOn,
            type: typeTrain,
            cost,
        });
    }

    //Слушать изменения инпута типа тренировки и автоматически проставлять данные в инпуты
    useEffect(() => {

        abonementCosts.forEach(abonementCost => {

            if (abonementCost.type.toLowerCase() === typeTrain.toLowerCase()) {

                if (isDiscont) {
                    // if (abonementCost.discont !== undefined) {
                    //     setDiscont(abonementCost.discont) // размер скидки в процентах
                    // } else {
                    //     setDiscont(0) // размер скидки в процентах
                    // }
                    // // Расчет скидки
                    // if (abonementCost.discont !== undefined && abonementCost.discont > 0) {
                    //     const discountAmount = (abonementCost.cost * abonementCost.discont) / 100;
                    //     // Расчет итоговой суммы с учетом скидки
                    //     const totalAmount = abonementCost.cost - discountAmount;
                    //     setCost(totalAmount) // записать цену
                    // } else {
                    //     setCost(abonementCost.cost)
                    // }
                    const discountAmount = (abonementCost.cost * 0.07);
                    // Расчет итоговой суммы с учетом скидки
                    console.log(discountAmount)
                    const totalAmount = abonementCost.cost - discountAmount;
                    setCost(totalAmount) // записать цену
                } else {
                    setCost(abonementCost.cost)
                }
            }
        });

        if (typeTrain === "групповые-разовая" || typeTrain === "персональные-разовая" || typeTrain === "персональные-сплит-разовая") {
            setQuantityVisitsOn(1); // количество тренировок изначально
        } else if (typeTrain === "групповые-4" || typeTrain === "персональные-4" || typeTrain === "персональные-сплит-4") {
            setQuantityVisitsOn(4); // количество тренировок изначально
        } else if (typeTrain === "групповые-8" || typeTrain === "персональные-8" || typeTrain === "персональные-сплит-8") {
            setQuantityVisitsOn(8); // количество тренировок изначально
        } else if (typeTrain === "групповые-12") {
            setQuantityVisitsOn(12); // количество тренировок изначально
        }

    }, [typeTrain, abonementCosts])

    // Слушать изменения инпута начала действия абонемента и автоматически проставлять на месяц вперед
    useEffect(() => {
        if (dateOn) {
            const startDate = new Date(dateOn);
            let endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate());
            //Проверить - если конец месяца начало абонемента, то конец следующего месяца конечная дата абонемента
            if (startDate.getDate() === new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0).getDate()) {
                let nextMonth = startDate.getMonth() + 1;
                let nextYear = startDate.getFullYear();
                if (nextMonth > 11) {
                    nextYear++;
                    nextMonth = 0;
                }
                const lastDayOfMonth = new Date(nextYear, nextMonth + 1, 0).getDate();
                endDate.setDate(lastDayOfMonth);
            }
            //Преобразовать дату для передачи в инпут
            const year = endDate.getFullYear();
            const month = String(endDate.getMonth() + 1).padStart(2, '0');
            const day = String(endDate.getDate()).padStart(2, '0');
            const formattedEndDate = `${year}-${month}-${day}`;
            setDateTo(formattedEndDate);
        }
    }, [dateOn]);

    // если абонемент уже есть, заполнить инпуты + проверить может ли быть применена скидка
    useEffect(() => {

        if (abonementClient !== undefined && abonementClient !== null) { // Если абонемент есть

            const currentDate = new Date();
            const sevenDaysBefore = new Date();
            sevenDaysBefore.setDate(sevenDaysBefore.getDate() - 7); // Неделя до текущей даты

            // Применить скидку к новому абонементу, если успел за 7 дней до окончания и текущая дата не позже окончания абонемента
            if ((new Date(abonementClient.dateTo) >= sevenDaysBefore) &&
                (currentDate >= new Date(abonementClient.dateTo))) {
                setIsDiscont(true);
                const startDate = new Date(abonementClient.dateTo); // Дата начала нового это следующий день окончания старого
                startDate.setDate(startDate.getDate() + 1);

                //Преобразовать дату для передачи в инпут
                const year = startDate.getFullYear();
                const month = String(startDate.getMonth() + 1).padStart(2, '0');
                const day = String(startDate.getDate()).padStart(2, '0');
                const formattedEndDate = `${year}-${month}-${day}`;
                setDateOn(formattedEndDate);
            } else {
                setIsDiscont(false);

                const startDate = new Date(); // Дата начала нового это текущий день окончания старого

                //Преобразовать дату для передачи в инпут
                const year = startDate.getFullYear();
                const month = String(startDate.getMonth() + 1).padStart(2, '0');
                const day = String(startDate.getDate()).padStart(2, '0');
                const formattedEndDate = `${year}-${month}-${day}`;
                setDateOn(formattedEndDate);
            }

            setQuantityVisitsOn(abonementClient.quantityVisitsOn);
        } else { // если предыдущего или текущего абонемента нет, то проставить даты на сегодняшний день
            const startDate = new Date(); // Дата начала нового это текущий день окончания старого

            //Преобразовать дату для передачи в инпут
            const year = startDate.getFullYear();
            const month = String(startDate.getMonth() + 1).padStart(2, '0');
            const day = String(startDate.getDate()).padStart(2, '0');
            const formattedEndDate = `${year}-${month}-${day}`;
            setDateOn(formattedEndDate);
        }
    }, []);


    return (
        <FormAdd
            onSubmit={handleSubmit} // сабмит
            isOpen={isOpen} // стейт контролирующий открытие
            isClose={isClose} // клик на кнопку для сворачивания
            colorButtonSubmit={colorButtonSubmit} // стиль кнопки сабмита
            colorBackground={colorBackground} // управление цветом формы и инпутов
            text={text} // заголовок формы
            textButtonSubmit={textButtonSubmit} // текст кнопки сабмита
            validationOptions={isFormEdited} // управлять кнопкой сабмита в зависимости от того, были заполненны поля или нет
            // buttonCloseDisable={true} // отключить кнопку закрытия
            // buttonVisibleControl={'formadd__close-button_hide'} // спрятать кнопку сворачивания
            // styleAllForm={'formadd_abonement'} // стиль всей формы
            // nameForm={'formadd__form_abonement'} // стиль формы (убрать тень)
            children={
                <>
                    <div className="formadd__input-conainer">
                        <p className="formadd__input">{nameClient}</p>
                        <span className="formadd__text-error" id="error-firstname">Имя клиента</span>
                    </div>
                    {/* Тип тренировок в абонементе (тип абонемента) */}
                    <div className="formadd__input-conainer">
                        <select
                            value={typeTrain}
                            onChange={handleChangeTrainType}
                            className={`${colorBackground} formadd__input formadd__input_name_image formadd__input-select`}
                        >
                            <option className="formadd__select-option" value="">Выберите тип абонемента</option>
                            {isCheckGroup ? // если групповой абонемент
                                <>
                                    <option className="formadd__select-option" value="групповые-разовая">гр. Разовая</option>
                                    <option className="formadd__select-option" value="групповые-4">гр. Абонемент на 4</option>
                                    <option className="formadd__select-option" value="групповые-8">гр. Абонемент на 8</option>
                                    <option className="formadd__select-option" value="групповые-12">гр. Абонемент на 12</option>
                                    <option className="formadd__select-option" value="групповые-продление">Групповые продление</option>
                                    <option className="formadd__select-option" value="групповые-настраиваемый">Групповые настраиваемый</option>
                                </>
                                : // если персональный
                                <>
                                    <option className="formadd__select-option" value="персональные-разовая">Персональные разовая</option>
                                    <option className="formadd__select-option" value="персональные-4">Перс.блок 4</option>
                                    <option className="formadd__select-option" value="персональные-8">Перс.блок 8</option>
                                    <option className="formadd__select-option" value="персональные-сплит-разовая">Сплит разовая</option>
                                    <option className="formadd__select-option" value="персональные-сплит-4">Сплит.блок 4</option>
                                    <option className="formadd__select-option" value="персональные-сплит-8">Сплит.блок 8</option>
                                    <option className="formadd__select-option" value="персональные-продление">Персональные-продление</option>
                                    <option className="formadd__select-option" value="персональные-настраиваемый">Персональный настраиваемый</option>
                                </>
                            }
                        </select>
                        <span className="formadd__text-error" id="error-typeoftrainings">{validationMassegeTypeTrain}</span>
                    </div>
                    {/* ДАТА НАЧАЛА АБОНЕМЕНТА */}
                    <div className="formadd__input-conainer">
                        <input value={dateOn} type="date" placeholder="Дата начала" className={`${colorBackground} formadd__input formadd__input_name_image formadd__input-time-and-date`}
                            name="name-client" id="name" minLength="2" maxLength="30" onChange={handleChangeDateOn} required />
                        <span className="formadd__text-error" id="error-dateon">{validationMassegeDateOn}</span>
                    </div>
                    {/* ДАТА ОКОНЧАНИЯ АБОНЕМЕНТА */}
                    <div className="formadd__input-conainer">
                        <input value={dateTo} type="date" placeholder="Дата окончания" className={`${colorBackground} formadd__input formadd__input_name_image formadd__input-time-and-date`}
                            name="subname-client" id="subname-client" minLength="2" maxLength="30" onChange={handleChangeDateTo} required />
                        <span className="formadd__text-error" id="error-dateto">{validationMassegeDateTo}</span>
                    </div>
                    {/* КОЛИЧЕСТВО ТРЕНИРОВОК */}
                    { // возможность указать количество тренировок в ручную только при данных типах
                        typeTrain === "групповые-настраиваемый"
                            || typeTrain === "персональные-настраиваемый"
                            || typeTrain === "персональные-продление"
                            || typeTrain === "групповые-продление" ?
                            <div className="formadd__input-conainer">
                                <input value={quantityVisitsOn} type="number" placeholder="Количество тренировок" className={`${colorBackground} formadd__input formadd__input_name_image`}
                                    name="number-client" id="number-client" min="1" max="12" onChange={handleChangeQuantityVisitsOn} required />
                                <span className="formadd__text-error" id="error-quantity">{validationMassegeQuantityVisitsOn}</span>
                            </div>
                            :
                            <div className="formadd__input-conainer">
                                <p className={`${colorBackground} formadd__input formadd__input_name_image`}>{quantityVisitsOn}</p>
                                <span className="formadd__text-error" id="error-quantity">{validationMassegeQuantityVisitsOn}</span>
                            </div>}
                    {/* СТОИМОСТЬ */}
                    { // возможность указать цены в ручную только при данных типах
                        typeTrain === "групповые-настраиваемый"
                            || typeTrain === "персональные-настраиваемый"
                            || typeTrain === "персональные-продление"
                            || typeTrain === "групповые-продление" ?
                            <div className="formadd__input-conainer">
                                <input value={cost} type="number" placeholder="Стоимость" className={`${colorBackground} formadd__input formadd__input_name_image`}
                                    name="number-client" id="number-client" onChange={handleChangeCost} required />
                                <span className="formadd__text-error" id="error-cost">{validationMassegeCost}</span>
                            </div>
                            :
                            <div className="formadd__input-conainer">
                                <p className={`${colorBackground} formadd__input formadd__input_name_image`}>{isDiscont ? `скидка ${7}% -  ${cost}` : cost}</p>
                                <span className="formadd__text-error" id="error-cost">{validationMassegeCost}</span>
                            </div>}
                    {/* <div className="formadd__input-conainer">
                    <input value={quantityVisits} type="text" placeholder="Остаток тренировок" className={`${colorBackground} formadd__input formadd__input_name_image`}
                        name="password-client" id="password-client" minLength="2" maxLength="11" onChange={handleChangeQuantityVisits} required />
                    <span className="formadd__text-error" id="error-firstname">{validationMassegeQuantityVisits}</span>
                </div> */}
                </>
            }
        />
    )
}

export default FormAddAbonement;