
function HystoryAbonementClient(
    {
        hystoryAbonements, // массив истории абонементов
        textTitleColor,
        backgroundColor,
        styleText,

    }
) {

    return (
        <div className='timetable__list'>
            <h2 className={`about-client-page-title ${textTitleColor} ${styleText}`}>Архив абонементов</h2>
            <div className="timetable__list">
                {hystoryAbonements.length === 0 ?
                    <p className={`about-client-page__text ${textTitleColor} ${styleText}`}>Старые абонементы отсуствуют</p>
                    :
                    hystoryAbonements.map(abonement => {
                        const dateOn = new Date(abonement.dateOn);
                        const dateTo = new Date(abonement.dateTo);
                        const formattedDateOn = dateOn.toLocaleDateString('ru-RU');
                        const formattedDateTo = dateTo.toLocaleDateString('ru-RU');

                        return (
                            <div
                                className={`timetable__list-element ${backgroundColor} `}
                                id={abonement._id}
                                key={abonement._id}
                            >
                                <div className=''>
                                    <p className={`about-client-page__text ${textTitleColor} ${styleText}`}>тип: {abonement.type}</p>
                                    <p className={`about-client-page__text ${textTitleColor} ${styleText}`}>действует с {formattedDateOn} по {formattedDateTo}</p>
                                    <p className={`about-client-page__text ${textTitleColor} ${styleText}`}>осталось занятий {abonement.quantityVisits} из {abonement.quantityVisitsOn}</p>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );

}

export default HystoryAbonementClient;