//Список тренеров для отображения у Суперадмина
//SuperAdmin -> this

import { useEffect, useState } from "react";

function AdminsList({
    backgroundColor, // цвет фона
    handleActiveAdminInfo, //управлять отображением информации о тренере при клике на него
    admins, // массив админов для отрисовки списка
    idSelectedAdmin, // айди выбранного админа для отрисовки кнопок редактирования и удаления
    handleOpenFormUpdateAdmin, // открыть форму редактирования админа
    handleOpenFormRemoveAdmin, // открыть форму удаления админа
    imgUpdate, // картинка кнопки редактирвоания админа
    imgRemove, // картинка кнопки удаления админа
    styleText, // стиль текста
}) {

    const [searchType, setSearchType] = useState("phone"); // поиск по телефону или по Имени и Фамилии
    const [adminsAlphabetically, setAdminssAlphabetically] = useState([]) // массив тренеров по алфавиту

    // 

    // открыть страницу инф о клиенте и передать в стейт его айди для поиска инф
    function handleOpenAdmin(admin) {
        handleActiveAdminInfo(admin); // передать айди в AdminPage и далее в AboutAdminPage
    }

    //ПОИСК----------ПОИСК----------ПОИСК----------ПОИСК----------ПОИСК----------ПОИСК----------
    // управлять поиском по номеру или Имени
    function handleSearchType(type) {
        setSearchType(type)
    }

    //useEffect---------------useEffect---------------useEffect---------------useEffect---------------useEffect---------------
    //Сортировать массив тренеров по алфавиту
    useEffect(() => {
        let alphabetically = admins.sort((a, b) => a.name.localeCompare(b.name, 'ru'));
        setAdminssAlphabetically(alphabetically);
    }, [admins])

    return (
        <div className="timetable">

            {/* СПИСОК ТРЕНЕРОВ + ИНПУТ ПОИСКА ТРЕНЕРОВ(ЕСЛИ НЕ БЫЛО КЛИКА НА КОНКРЕТНОМ ТРЕНЕРЕ) */}

            {/* СПИСОК ТРЕНЕРОВ */}
            <div className="timetable__list">
                {admins !== undefined ?
                    adminsAlphabetically.map(admin => {
                        let nameAdmin = admin.name;
                        let surnameAdmin = admin.surname;
                        let phoneNumberAdmin = admin.phoneNumber;
                        return <button
                            className={`timetable__list-element ${backgroundColor}`}
                            id={admin._id}
                            key={admin._id}
                            onClick={() => { handleOpenAdmin(admin) }} // клик на клиента в списке
                        >
                            <p className={`admin-list__admin-info ${styleText}`}>{nameAdmin} {surnameAdmin} {phoneNumberAdmin}</p>
                            {idSelectedAdmin === admin._id ? // отрисовать кнопки удаления и редактирования если выбран админ
                                <div className="admin-page__training-list-buttons-container">
                                    {/* РЕДАКТИРОВАТЬ ТРЕНИРОВКУ */}
                                    <div
                                        className={`admin-page__list-comand-button ${backgroundColor}`}
                                        onClick={handleOpenFormUpdateAdmin}>
                                        <img className='admin-page__button-img' src={imgUpdate} alt='imgUpdate' />
                                    </div>
                                    {/* УДАЛИТЬ ТРЕНИРОВКУ */}
                                    <div
                                        className={`admin-page__list-comand-button ${backgroundColor}`}
                                        onClick={handleOpenFormRemoveAdmin}>
                                        <img className='admin-page__button-img' src={imgRemove} alt='imgRemove' />
                                    </div>
                                </div>
                                : <></>}
                        </button>
                    })
                    : <></>
                }
            </div>
        </div >
    )
}


export default AdminsList;