//Компонент для настроек абонемента - просмотра и проставления стоимостей, которая затем автоматически проставляется при создании абонемента
//SuperAdmin --> this

import { useEffect, useState } from "react";

import InfoTooltip from "../InfoTooltip/InfoTooltip";

function SettingsAbonements({
    api,
    styleText,
    colorBackground,
    colorButtonSubmit,
}) {

    const [typeAbonement, setTypeAbonement] = useState(''); // тип абонемента
    const [cost, setCost] = useState(0); // стоимость
    const [isAbonementCost, setIsAbonementCost] = useState(true); // есть ли абонемент
    const [abonementsCosts, setAbonementsCosts] = useState([]); // массив стоимостей абонемента
    const [disabledButtonSubmit, setDisabledButtonSubmit] = useState(true); // валидация для сабмита
    const [isInfoToolOpen, setIsInfoToolOpen] = useState(false); // управление инфотулом
    const [messageInfoTool, setMessageInfoTool] = useState(''); // сообщение инфотул

    const handleChangeTrainType = (event) => {
        setTypeAbonement(event.target.value);
    };

    const handleChangeCost = (event) => {
        setCost(event.target.value);
    };

    useEffect(() => {
        //console.log(cost)
    }, [cost]);

    //Сабмит
    function onSubmit(event) {
        event.preventDefault();
        if (isAbonementCost) { // если стоимость уже заведена
            api.updateCost(typeAbonement, cost)
                .then((updateCost) => {
                    let updateCostNew = updateCost.updateCost;
                    let updatedCosts = abonementsCosts.map((abonementCost) => {
                        if (abonementCost.type === updateCostNew.type) {
                            return {
                                type: updateCostNew.type,
                                cost: updateCostNew.cost,
                            };
                        } else {
                            return abonementCost;
                        }
                    });
                    setAbonementsCosts(updatedCosts);
                    setIsInfoToolOpen(true);
                    setMessageInfoTool('Успешно');
                })
                .catch((err) => {
                    console.log(err);
                    setIsInfoToolOpen(true);
                    setMessageInfoTool('Произошла ошибка');
                });
        } else { // если новая
            api.createCost(typeAbonement, cost)
                .then((newCost) => {
                    let updatedCost = newCost.cost;
                    setAbonementsCosts((prevCosts) => [...prevCosts, updatedCost]);
                    setIsInfoToolOpen(true);
                    setMessageInfoTool('Успешно');
                })
                .catch(() => {
                    setIsInfoToolOpen(true);
                    setMessageInfoTool('Произошла ошибка');
                });
        }
    }


    //Инфотул
    //Свернуть
    function closeInfotool() {
        setIsInfoToolOpen(false);
    }

    //Валидация
    useEffect(() => {
        if (abonementsCosts) {
            let findAbonement = abonementsCosts.find(abonementsCosts => abonementsCosts.type === typeAbonement) // найти абонемент по типу для сравнения цены
            let findCost;
            if (findAbonement) {
                findCost = findAbonement.cost;
            }

            if (cost === 0 || cost === findCost) {
                setDisabledButtonSubmit(true);
            } else {
                setDisabledButtonSubmit(false);
            }
        }
    }, [abonementsCosts, typeAbonement, cost])

    //Получить стоимость абонементов
    useEffect(() => {
        api.getAbonementCosts()
            .then((abonementsCosts) => { setAbonementsCosts(abonementsCosts.abonementCosts) })
            .catch((err) => { console.log('Произошла ошибка при получении стоимости абонементов') })
    }, [])

    //Проставлять стоимость в зависимости от выбранного типа абонемента и пррверить есть ли абонемент в базе
    useEffect(() => {
        if (abonementsCosts) {
            let find = abonementsCosts.find(abonementsCost => abonementsCost.type === typeAbonement)
            if (find === undefined) {
                setIsAbonementCost(false); // проставить, что абонемент не найден
                return
            } else {
                let abonementCost = find.cost;
                setCost(abonementCost)
                setIsAbonementCost(true); // проставить, что абонемент  найден
            }
        }

    }, [typeAbonement])

    // Автоматически сворачивать инфотул
    useEffect(() => {
        if (isInfoToolOpen) {
            setTimeout(() => { setIsInfoToolOpen(false) }, 3000)
        }
    }, [isInfoToolOpen])

    return <div className="settings-abonements">
        {/* ИНФОТУЛ */}
        {isInfoToolOpen ?
            <InfoTooltip
                isOpen={isInfoToolOpen}
                isClose={closeInfotool}
                text={messageInfoTool}
                showButton={false}
                backgoundColor={colorBackground} />
            : <></>}
        {/* ФОРМА РЕДАКТИРОВАНИЯ И ДОБАВЛЕНИЯ СТОИМОСТИ  */}
        <h2 className={`settings-abonements__title ${styleText}`}>Настройки абонементов</h2>
        <form className='settings-abonements__form' onSubmit={onSubmit}>
            <div className="formadd__set">
                <div className="formadd__input-conainer ">
                    <select
                        value={typeAbonement}
                        onChange={handleChangeTrainType}
                        className={`${colorBackground} formadd__input formadd__input-select `}
                    >
                        <option className="formadd__select-option" value="">Выберите тип абонемента</option>

                        <>
                            <option className="formadd__select-option" value="групповые-разовая">гр. Разовые</option>
                            <option className="formadd__select-option" value="групповые-4">гр. Абонемент на 4</option>
                            <option className="formadd__select-option" value="групповые-8">гр. Абонемент на 8</option>
                            <option className="formadd__select-option" value="групповые-12">гр. Абонемент на 12</option>
                            <option className="formadd__select-option" value="групповые-продление">Групповые продление</option>

                            <option className="formadd__select-option" value="персональные-разовая">Персональные разовая</option>
                            <option className="formadd__select-option" value="персональные-4">Перс.блок 4</option>
                            <option className="formadd__select-option" value="персональные-8">Перс.блок 8</option>
                            <option className="formadd__select-option" value="персональные-сплит-разовая">Сплит разовая</option>
                            <option className="formadd__select-option" value="персональные-сплит-4">Сплит.блок 4</option>
                            <option className="formadd__select-option" value="персональные-сплит-8">Сплит.блок 8</option>
                            <option className="formadd__select-option" value="персональные-продление">Персональные-продление</option>
                        </>

                    </select>
                    <span className="formadd__text-error " id="error-firstname">Тип абонемента</span>
                </div>
                {/* СТОИМОСТЬ АБОНЕМЕНТА */}
                <div className="formadd__input-conainer ">
                    <input value={cost} type="number" placeholder="Стоимость" className={`${colorBackground}  formadd__input formadd__input_name_image`}
                        name="cost" id="cost" onChange={handleChangeCost} required />
                    <span className="formadd__text-error " id="error-firstname">Стоимость</span>
                </div>
            </div>
            <button className={`formadd__button ${colorButtonSubmit}`} type="submit" disabled={disabledButtonSubmit}>{isAbonementCost ? 'Редактировать' : 'Создать'}</button>
        </form>
        {/* ИНфОРМАЦИЯ О ЗАНЕСЕННЫХ В БАЗУ ДАННЫХ О СТОИМОСТЯХ */}
        <h3 className={`settings-abonements__title ${styleText}`}>Стоимости</h3>
        <div className="settings-abonements__abonements-table">
            <div className="settings-abonements__abonements-row">
                <p className={`settings-abonements__abonements-info settings-abonements__abonements-table_title ${styleText}`}>Тип абонемента</p>
                <p className={`settings-abonements__abonements-info settings-abonements__abonements-table_title ${styleText}`}>₽</p>
            </div>
            {abonementsCosts.map((abonement) => {
                let typeAbonement = abonement.type;
                let costAbonement = abonement.cost;

                return <div className="settings-abonements__abonements-row" key={abonement.type}>
                    <p className={`settings-abonements__abonements-info ${styleText}`}>{typeAbonement}</p>
                    <p className={`settings-abonements__abonements-info ${styleText}`}>{costAbonement}</p>
                </div>
            })}
        </div>
    </div>
}

export default SettingsAbonements;