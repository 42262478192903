//SuperAdmin->this
//Либо уже готовый компонент для админа

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import TimeTable from '../TimeTable/TimeTable'; // импорт списка тренировок
import SelectList from '../SelectList/SelectList'; // импорт списка для выбора (типа тренировки)
import AboutClientPage from '../AboutClientPage/AboutClientPage';
import ClientsTable from '../ClientsTable/ClientsTable'; // импорт списка клиентов
import InfoTooltip from "../InfoTooltip/InfoTooltip"; // инфотул для отображения информации об ошибке
import SearchForm from '../SearchForm/SearchForm';
import FormAddClient from '../FormAddClient/FormAddClient'; // форма добавления клиента
import FormUpdateClient from '../FormUpdateClient/FormUpdateClient'; // форма редактирования клиента
import TrainingCalendar from '../TrainingCalendar.js/TrainingCalendar';
import FormAddTrain from '../FormAddTrain/FormAddTrain';
import ListOfOneTimeTraining from '../ListOfOneTimeTraining/ListOfOneTimeTraining'; // Список разовых разовых посещений
import Preloader from '../Preloader/Preloader';
import SuperAdmin from '../SuperAdmin/SuperAdmin';
import SignIn from '../SignIn/SignIn'; // Авторизация

function AdminPage({
    backgroundColor, // цвет фона
    textHeaderColor, // цвет Хэдера
    styleTitlesColor, // цвет титульника
    styleHeaderBoarderColor, // цвет полоски в хедере
    api, // АПИ
    apiAuth, // АПИ Авторизации
    styleText, // стиль текста
    textButtonSubmit, // текст кнопки сабмита
    colorBackgroundForm, // цвет фона формы добавления
    stylePlaceHolder, // стиль плейсхолдеров инпутов
    styleButtonSubmit, // стиль кнопки сабмита
    styleButton, // стиль кнопок переключения AboutClientPage (абонемент, запись, активные тренировки)
    previousWeekButtonImg, // изображение стрелки перекл дней недели
    nextMonthButtonImg, // изображение стрелки перекл дней недели
    infoFlex,// давать ли информацию по абонементам Flex
    infoDark, // давать ли информацию по абонементам Dark
    isSuperAdmin, //Возможность добавлять группы только у суперадмина
    handleSetIsLikeAdmin, // менять страницу на админа по клику
    imgUpdateTraining, // изображение кнопки редактирования тренировки
    imgRemoveTraining, // изображение кнопки удаления тренировки
    imgWA, // изображение кнопки мессенджер
    imgAddTraining, // изображение кнопки добавления тренировки
    imgTimeTable, // изображение кнопки расписания
    imgGroupeTrain, // изображение кнопки добавления групповой тренировки
    imgPersonalTrain, // изображение кнопки добавления персональной тренировки
    imgHomePage, // изображение кнопки главной страницы
    imgList, // изображение кнопки переклбчения на список в календаре
    imgAddClients, // изображение кнопки создания клиента
    imgCheckOneTimeTraining, // изображение кнопки проверки разовых тренировок
    imgAddOneTimeTraining, //картинка добавления абонемента для разовой тренировки
    imgRemoveOneTimeTraining, // картинка удаления разовой тренировки
    imgHystoryAbonement, // картинка истории абонементов
    imgQRCode, // изображение кнопки qrcode
    isActiveTrainer, // для прокидывания с уровня ниже отображения стр управления тренерами
    //Форма поиска
    styleSearchForm, // стиль формы поиска
    imgPhoneSearch, // картинка кнопки поиска по телефону
    imgNameSurnameSearch, // картинка кнопки поиска по телефону
    //Суперадмин
    isLikeAdmin, // true - кнопки как у админа, скрыт выбор поиска и статистика по тренерам и абонам
    buttonAddTrainer, // кнопка добавления тренера
    isTrainerAndTraining, // стейт следящий за открытием компонента инф о тренерах и их тренировках(при клике на тренера)
    changeStateIsTrainerAndTraining, // поменять стейт следящий за открытием компонента инф о тренерах и их тренировках
    childButtonsFooterSuperAdmin, // дополнительнеы кнопки в футере для суперадмина
    childComponentSuperAdmin, // компоненты суперадмина
    switcherSuperAdmin, // переключатель для суперадмина на работу либо с клиентами, либо с тренерами, либо адимнами
    isSuperadminSelectTrainers, // селектор для определния работы с тренерами или клиентами, true - с тренерами 
    isSuperAdminSelectAdmins, // селектор для определения работы с админами, true - с тренерами 
    trainersList, // компонент списка тренеров
    adminsList, // компонент списка админов
    changeTrainers, // наполнить массив тренеров для отображения
    isHideFooterForSuperAdmin, // прятать футер при необходмиости
    buttonStatistics, // кнопка просмотра статистики
    ishideSearchForm, // пробрасывается стейт, управления отображением инпута поиска, true - скрыть
    isStatisticsSuperAdmin, // открыта или нет страница статистики у суперадмина(true - открыта), необходимо, чтобы скрывать некоторые компоненты
    buttonAddAdmin, // кнопка добавления админа
    //Свитчер 
    styleCheckboxButton, // стиль свитчера
    //переключение дней 
    timetableWeekButtonStyle, // стиль кнопок переключения дней недели
    //Футер 
    styleFooter, // стиль футера
    //Форма добавления тренировок
    isTypeSpace, // выбор зала, true - добавить выбор
}) {
    //Авторизация
    const [isAuthAdmin, setIsAuthAdmin] = useState(false); // стейт авторизации админа
    // стейты управления отображения компонентов на странице
    const [isActiveClientsTable, setIsActiveClientsTable] = useState(true); // стейт отображения списка клиентов
    const [isActiveClientInfo, setisActiveClientInfo] = useState(false); // стейт для переключения на инфо о клиентах
    const [isActiveTimeListInfo, setisActiveTimeListInfo] = useState(false); // стейт для переключения на расписание
    const [isBigTrainingCalendar, setIsBigTrainingCalendar] = useState(false); // стейт для открытия большого календаря
    const [isAdminPage, setIsAdminPage] = useState(true); // стейт управления в зависимости от страницы
    const [isSearch, setIsSearch] = useState(true); // показывать ли поиск
    const [isListTraining, setListTraining] = useState(false); // календарь в виде списка или большого календаря
    const [isUpdateButtonClient, setIsUpdayeButtonClient] = useState(false); // показывать ли в футере кнопку редактирования клиента
    //стейты, хранящие данные 
    const [isIdClient, setIdClient] = useState(''); // айди клиента
    const [selectedClient, setSelectedClient] = useState({}); // объект данных выбранного клиента
    const [trains, setTrains] = useState([]); // массив тренировок на месяц вперед и неделю назад от текущей даты для календаря
    //инфотул
    const [isInfoToolOpen, setIsInfoToolOpen] = useState(false); // стейт для открытия информационного окна
    const [isTextMassageInfoTool, setTextMassageInfoTool] = useState(''); // текс в инфотул
    //форма добавления клиента
    const [isOpenFormAddClients, setOpenFormAddClients] = useState(false); // открыть 
    const [isPushClient, setPushClient] = useState(false); // следить за добавлением клиентов, чтобы отрисовывать сразу список
    //форма поииска SearchForm
    const [searchValue, setSearchValue] = useState(""); // добавляем состояние и функцию для его обновления
    const [searchType, setSearchType] = useState("phone"); // поиск по телефону или по Имени и Фамилии
    //Форма редактирования клиента
    const [isOpenFormUpdateClient, setIsOpenFormUpdateClient] = useState(false);
    //ClientsTable
    const [isClients, setIsClients] = useState([]); // массив клиентов для отображения
    const [clientsAlphabetically, setClientsAlphabetically] = useState([]); // массив клиентов по алфавиту
    const [isClientsOrTrainersTable, setIsClientsOrTrainersTable] = useState(false); // показывать или нет список клиентов и тренеров
    const [isClientsTableLoader, setIsClientsTableLoader] = useState(false); // лоадер
    //ClientInfo
    const [nameClient, setNameClient] = useState(''); // имя клиента
    const [phoneNumberClient, setPhoneNumberClient] = useState('') // номер телефона клиента
    //AboutClientPage
    const [isClickAddClientOnGroupe, setIsClickAddClientOnGroupe] = useState(false) // слушатель на клик кнопки добавления клиента на группу
    const [isClickAddClientOnPersonal, setIsClickAddClientOnPersonal] = useState(false) // слушатель на клик кнопки добавления клиента на персональные
    //Календарь
    const [isBigCalendar, setIsBigCalendar] = useState(false); // показывать большой календарь
    const [isChangeArrayTrains, setIsChangeArrayTrains] = useState(true); // true - отображать все тренировки,false - без групповых 
    const [filteredTrains, setFilteredTrains] = useState([]); // Сохранить отфильтрованные тренировки в отдельном состоянии  
    //Лист ожидания 
    const [isWaitingList, setIsWaitingList] = useState(false); // отображать или нет лист ожидания
    //Форма добавления тренировки
    const [isOpenFormAddTraining, setIsOpenFormAddTraining] = useState(false); // управлять открытие формы
    const [trainers, setTrainers] = useState([]); // массив тренеров для показа в инпуте при выборе тренировки 
    //Список разовых посещений на тренировках
    const [isCheckOneTimeTraining, setIsCheckOneTimeTraining] = useState(false); // открыть работу с разовыми тренировками
    //История абонементов у клиента
    const [isClientHystoryAbonement, setIsClientHystoryAbonement] = useState(false); // управлять открытием истории абонементов у клиента
    const [hystoryAbonement, setHystoryAbonement] = useState([]); // массив истории абонементов
    //Слушать добавление новых тренировок для отрисовки
    const [isChangeTrainingsListHandle, setIsChangeTrainingsListHandle] = useState(false);
    const [newTrain, setNewTrain] = useState({}); // новая тренировка для отрисовк
    //Показывать загрузочную страницу
    const [isPreloaderAuth, setIsPreloaderAuth] = useState(true); // true - показывать страницу авторизации пока она загружается

    //Авторизация админов
    function handleSignInAdmin(data) {
        apiAuth.loginAdmin(data)
            .then(() => {
                setIsAuthAdmin(true);
            }).catch((err) => {
                setIsAuthAdmin(false);
                setTextMassageInfoTool(`${err.message}`)
            })
    }

    //Если страница тренера, то айди при получении информации о тренера
    //Проверить авторизацию тренера при отрисовке компонена
    useEffect(() => {
        if (!isSuperAdmin) {
            api.getSelfAdmin()
                .then((selfTrainer) => {
                    setIsAuthAdmin(true);
                })
                .catch((error) => {
                    setIsAuthAdmin(false);
                });
        }
    }, [])

    useEffect(() => {
        //console.log(isSuperAdmin, isSuperAdminSelectAdmins)
    }, [isSuperAdmin, isSuperAdminSelectAdmins])

    function handleClickOnAdminPage() {
        setIsActiveClientsTable(true);
        setIsAdminPage(true);
        setisActiveTimeListInfo(false);
        setisActiveClientInfo(false)
        setIsClientHystoryAbonement(false);
    }

    //Инфотул
    //Свернуть инфотул
    function closeInfotool() {
        setIsInfoToolOpen(false);
    }

    //КЛИЕНТЫ--------КЛИЕНТЫ--------КЛИЕНТЫ--------КЛИЕНТЫ--------КЛИЕНТЫ--------КЛИЕНТЫ--------КЛИЕНТЫ--------КЛИЕНТЫ--------КЛИЕНТЫ--------
    function changeActiveClientsTable() { // активировать список клиентов
        setIsActiveClientsTable(true); // активировать
        setisActiveTimeListInfo(false); // спрятать расписание
        setisActiveClientInfo(false); // отображение инф о кокнретном клиенте
        //setIsActiveTrainer();
    }

    function handleActiveClientInfo(idClient, client) { // функция, срабатывающая при клике на клиенте ClientsTable
        setisActiveClientInfo(true); // отображение инф о кокнретном клиенте
        setIsActiveClientsTable(false); // выкл кнопку добавления клиента
        setIsAdminPage(false);
        setIdClient(idClient);
        setSelectedClient(client); // объект выбранного клиента
    }

    function handleAddClient(infoClient) { // зарегистрировать нового клиента в БД
        api.register(infoClient)
            .then((newClient) => {
                setIsInfoToolOpen(true);
                setTextMassageInfoTool(`клиент ${newClient.name} ${newClient.surname} c номером телефона ${newClient.phoneNumber} создан`)
                setPushClient(true) // поменять стейт для мгновенной отрисовки обновленного списка в ClientsTable
                setOpenFormAddClients(false) // свернуть форму добавления
            }).catch((err) => {
                console.log(err);
                setIsInfoToolOpen(true);
                setTextMassageInfoTool(`произошла ошибка при добавлении ${err.message}`)
            });
    }

    //Форма добавления клиента---------Форма добавления клиента---------Форма добавления клиента---------Форма добавления клиента---------Форма добавления клиента---------
    function handleOpenFormAddClient() { // открыть форму добавления клиента
        setOpenFormAddClients(true);

    }

    function handleCloseFormAddClient() { // свернуть форму добавления клиента
        setOpenFormAddClients(false);
        setPushClient(false);
    }
    //закрыть на Esc
    function handleCloseFormAddClientOnEsc(e) {
        if (e.key === "Escape") {
            setOpenFormAddClients(false);
        }
    }

    //закрыть на оверлей
    function handleCloseFormAddClientClickOverlay(e) {
        if (e.target.classList.contains('popup_overlay')) {
            setOpenFormAddClients(false);
        }
    }

    // АБОНЕМЕНТ
    function clickOnAbonement() {
    }

    //ИНФОТУЛ---------ИНФОТУЛ---------ИНФОТУЛ---------ИНФОТУЛ---------ИНФОТУЛ---------ИНФОТУЛ---------ИНФОТУЛ---------
    function controlInfoToolText(text) { // управлять текстом инфотула
        setTextMassageInfoTool(text);
    }

    function controlInfoToolOpen(state) { // управлять открытием инфотула
        setIsInfoToolOpen(state);
    }

    //РАСПИСАНИЕ--------------РАСПИСАНИЕ--------------РАСПИСАНИЕ--------------РАСПИСАНИЕ--------------РАСПИСАНИЕ--------------РАСПИСАНИЕ--------------
    function changeActiveTimeListInfo() { // активировать расписание
        setisActiveTimeListInfo(true); // открыть расписание
        setIsActiveClientsTable(false); // скрыть инфо о клиентах
    }

    //SearchForm---------SearchForm---------SearchForm---------SearchForm---------SearchForm---------SearchForm---------SearchForm
    // управлять поиском по номеру или Имени
    function handleSearchType(type) {
        setSearchType(type)
    }

    //Сабмит поиска
    function onSubmitSearchForm(value) {
        setIsCheckOneTimeTraining(false); // свернуть просмотр разовых тренировок
        setIsBigTrainingCalendar(false);  // свернуть календарь при поиске
        setIsWaitingList(false); // свернуть лист ожидания
        setIsClientsOrTrainersTable(true); // показать список клиентов или тренеров
        if (isSuperAdmin) { changeStateIsTrainerAndTraining(false); } // поменять стейт следящий за открытием компонента инф о тренерах и их тренировках
        if (searchType === "phone") {
            searchByPhoneNumber(value);
        } else if (searchType === "nameAndSurname") {
            searchByNameAndSurname(value);
        }
    }
    //Поиск по номеру телефона
    function searchByPhoneNumber(value) {
        setIsClientsTableLoader(true); //лоадер
        const number = +value; // Преобразовать в число

        if (isSuperAdmin && isSuperadminSelectTrainers) { // если активен супер админ и переключен на инфо о тренерах
            if (value.length <= 11 && !isNaN(number)) {
                api
                    .getTrainerForNumber(number)
                    .then((res) => {
                        let Trainers = res.trainers;
                        setIsClientsTableLoader(false); //лоадер
                        if (Trainers.length > 0) {
                            changeTrainers(Trainers);
                        } else {
                            setIsClientsTableLoader(false); //лоадер
                            controlInfoToolOpen(true);
                            controlInfoToolText(
                                "Тренер с такими данными не найден, проверьте параметры ввода"
                            );
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        setIsClientsTableLoader(false); //лоадер
                    });
            } else {
                controlInfoToolOpen(true);
                controlInfoToolText("Пожалуйста, введите корректный номер телефона.");
            }

        } else { // если поиск по клиентам
            if (value.length <= 11 && !isNaN(number)) {
                api
                    .getClientForNumber(number)
                    .then((res) => {
                        let Clients = res.clients;
                        setIsClientsTableLoader(false); //лоадер
                        if (Clients.length > 0) {
                            console.log(Clients);
                            setIsClients(Clients);
                        } else {
                            setIsClientsTableLoader(false); //лоадер
                            controlInfoToolOpen(true);
                            controlInfoToolText(
                                "Клиент с такими данными не найден, проверьте параметры ввода"
                            );
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        setIsClientsTableLoader(false); //лоадер
                    });
            } else {
                controlInfoToolOpen(true);
                controlInfoToolText("Пожалуйста, введите корректный номер телефона.");
            }
        }

    }

    //Главная функция поиска
    function searchByNameAndSurname(value) {
        let name;
        let surname;

        if (value.includes(" ")) {
            [surname, name] = value.split(" ");
            searchByFullName(surname, name);
        } else {
            searchByName(value);
        }
    }

    function searchByFullName(surname, name) {
        if (isSuperAdmin && isSuperadminSelectTrainers) { // если активен супер админ и переключен на инфо о тренерах

            setIsClientsTableLoader(true); //лоадер
            api
                .getTrainerForName(surname, name)
                .then((res) => {
                    let Trainers = res.trainers;
                    setIsClientsTableLoader(false); //лоадер
                    if (Trainers.length > 0) {
                        changeTrainers(Trainers);
                    } else {
                        // Проверяем не перепутаны ли местами фамилия и имя
                        api
                            .getTrainersForName(name, surname)
                            .then((res) => {
                                setIsClientsTableLoader(false); //лоадер
                                let Trainers = res.trainers;
                                if (Trainers.length > 0) {
                                    changeTrainers(Trainers);
                                } else {
                                    setIsClientsTableLoader(false); //лоадер
                                    controlInfoToolOpen(true);
                                    controlInfoToolText(
                                        "Тренер с такими данными не найден, проверьте параметры ввода"
                                    );
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                                setIsClientsTableLoader(false); //лоадер
                            });
                    }
                })

        } else { // поиск по клиентам
            setIsClientsTableLoader(true); //лоадер
            api
                .getClientForName(surname, name)
                .then((res) => {
                    let Clients = res.clients;
                    setIsClientsTableLoader(false); //лоадер
                    if (Clients.length > 0) {
                        setIsClients(Clients);
                    } else {
                        // Проверяем не перепутаны ли местами фамилия и имя
                        api
                            .getClientForName(name, surname)
                            .then((res) => {
                                setIsClientsTableLoader(false); //лоадер
                                let Clients = res.clients;
                                if (Clients.length > 0) {
                                    setIsClients(Clients);
                                } else {
                                    setIsClientsTableLoader(false); //лоадер
                                    controlInfoToolOpen(true);
                                    controlInfoToolText(
                                        "Клиент с такими данными не найден, проверьте параметры ввода"
                                    );
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                                setIsClientsTableLoader(false); //лоадер
                            });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setIsClientsTableLoader(false); //лоадер
                });
        }

    }

    function searchByName(name) {

        if (isSuperAdmin && isSuperadminSelectTrainers) { // если активен супер админ и переключен на инфо о тренерах

            setIsClientsTableLoader(true); //лоадер
            api
                .getTrainerForName(name)
                .then((res) => {
                    setIsClientsTableLoader(false); //лоадер
                    let Trainers = res.trainers;
                    if (Trainers.length > 0) {
                        changeTrainers(Trainers);
                    } else {
                        searchBySurname(name);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setIsClientsTableLoader(false); //лоадер
                });

        } else {
            setIsClientsTableLoader(true); //лоадер
            api
                .getClientForName(name)
                .then((res) => {
                    setIsClientsTableLoader(false); //лоадер
                    let Clients = res.clients;
                    if (Clients.length > 0) {
                        setIsClients(Clients);
                    } else {
                        searchBySurname(name);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setIsClientsTableLoader(false); //лоадер
                });
        }

    }

    function searchBySurname(surname) {
        if (isSuperAdmin && isSuperadminSelectTrainers) { // если активен супер админ и переключен на инфо о тренерах
            setIsClientsTableLoader(true); //лоадер
            api
                .getTrainerForName("", surname)
                .then((res) => {
                    setIsClientsTableLoader(false); //лоадер
                    let Trainers = res.trainers;
                    if (Trainers.length > 0) {

                        changeTrainers(Trainers);
                    } else {
                        setIsClientsTableLoader(false); //лоадер
                        controlInfoToolOpen(true);
                        controlInfoToolText(
                            "Тренер с такими данными не найден, проверьте параметры ввода"
                        );
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setIsClientsTableLoader(false); //лоадер
                });
        } else {
            setIsClientsTableLoader(true); //лоадер
            api
                .getClientForName("", surname)
                .then((res) => {
                    setIsClientsTableLoader(false); //лоадер
                    let Clients = res.clients;
                    if (Clients.length > 0) {
                        console.log(Clients);
                        setIsClients(Clients);
                    } else {
                        setIsClientsTableLoader(false); //лоадер
                        controlInfoToolOpen(true);
                        controlInfoToolText(
                            "Клиент с такими данными не найден, проверьте параметры ввода"
                        );
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setIsClientsTableLoader(false); //лоадер
                });
        }
    }

    //КАЛЕНДАРЬ-----------------КАЛЕНДАРЬ-----------------КАЛЕНДАРЬ-----------------КАЛЕНДАРЬ-----------------КАЛЕНДАРЬ-----------------
    function openBigCalendar() {
        setIsClientsOrTrainersTable(false); // спрятать список клиентов или тренеров
        setIsWaitingList(false);
        setIsCheckOneTimeTraining(false); // свернуть контроль разовых тренировок
        if (isSuperAdmin) { changeStateIsTrainerAndTraining(false); }
        setIsBigTrainingCalendar(true);
        setIsBigCalendar(true);
    }

    //Переключать отображение календаря на список и большой
    function handleClickOnSettingCalendar() {
        setIsClientsOrTrainersTable(false); // спрятать список клиентов или тренеров
        setIsWaitingList(false);
        setIsCheckOneTimeTraining(false); // свернуть контроль разовых тренировок
        if (isSuperAdmin) { changeStateIsTrainerAndTraining(false); }
        setIsBigTrainingCalendar(true);
        setIsBigCalendar(false)
    }

    //Переключать тренировки на все или на без групповых
    function handleIsChangeArrayTrains(state) {
        if (state) {
            setIsChangeArrayTrains(false);
        } else {
            setIsChangeArrayTrains(true);
        }

    }

    //ClientInfo--------------
    //пробросить имя Клиента
    function handleNameClient(name) {
        setNameClient(name);
    }

    //пробросить сотовый Клиента
    function handlePhoneNumberClient(phoneNumber) {
        setPhoneNumberClient(phoneNumber);
    }

    //отправить сообщение Клиенту
    function writeWAToClient() {
        const phoneNumber = phoneNumberClient; // Замените на нужный номер телефона
        const message = `Добрый день ${nameClient}!`
        // Замените на нужный текст сообщения
        // Формируем ссылку с номером телефона и текстом сообщения
        const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        // Открываем ссылку в новом окне/вкладке
        window.open(url, "_blank");
    }

    //AboutClietnPage------------AboutClietnPage------------AboutClietnPage------------AboutClietnPage------------AboutClietnPage------------AboutClietnPage------------
    //При клике на кнопку навигации открыть расписание, все стейты прокидываются из AboutClientPage и проставляются там

    function handleAddClientOnGroupe() { // если кликнули на кнопку добавления клиента на группу(открыть календарь)
        setIsClickAddClientOnGroupe(true);
    }

    function handleIsClickAddClientOnGroupe() { // если кликнули на кнопку добавления клиента на группу
        setIsClickAddClientOnGroupe(false);
    }

    function handleAddClientOnPersonal() {  // если кликнули на кнопку добавления клиента на перс
        setIsClickAddClientOnPersonal(true);
    }

    function handleIsClickAddClientOnPersonal(is) { // если кликнули на кнопку добавления клиента на группу
        setIsClickAddClientOnPersonal(is);
    }

    function setActiveTrainsTimeTable(value) {
        setActiveTrainsTimeTable(value);
    }

    function setIsComments(value) {
        setIsComments(value);
    }

    function handleCloseFormAddTrain() {
        setIsInfoToolOpen(false);
    }

    // Открыть форму реадактирования клиента
    function openFormUpdateClient() {
        setIsOpenFormUpdateClient(true);
    }

    //Свернуть форму редактирования клиента
    function closeFormUpdateClient() {
        setIsOpenFormUpdateClient(false);
    }

    // Редактировать клиента
    function handleUpdateClient(data) {
        api.updateClient(isIdClient, data)
            .then(() => {
                setIsInfoToolOpen(true);
                setTextMassageInfoTool("Клиент отредактирован");
                setIsOpenFormUpdateClient(false);
            }).catch(err => {
                setIsInfoToolOpen(true);
                setTextMassageInfoTool(`Ошибка : ${err.message}`);
            });
    }

    // показывать ли кнопку редактирования клиента в футере
    function handleIsUpdateClientButton(state) {
        setIsUpdayeButtonClient(state);
    }

    //ЛИСТ ОЖИДАНИЯ 
    function clickOnWaitingList() { // открыть лист ожидания
        setIsWaitingList(true);
        setIsBigTrainingCalendar(false);
        setListTraining(false);
        setIsClientsOrTrainersTable(false); // спрятать список клиентов или тренеров
    }

    //ДОБАВЛЕНИЕ ТРЕНИРОВКИ-----------------ТРЕНИРОВКИ-----------------ТРЕНИРОВКИ-----------------ТРЕНИРОВКИ-----------------
    function handleOpenFormAddTraining() { // открыть форму
        setIsOpenFormAddTraining(true);
    }

    function handleCloseFormAddTraining() { // свернуть формы
        setIsOpenFormAddTraining(false);
    }

    function handleSubmitAddTraining(data, clientsOnPers) { // создать тренировку (группа, аренда, самотрен)
        if (data.typeTrain.includes('групповая')) {
            api.createTrain(data)
                .then((newTrain) => {
                    setIsInfoToolOpen(true);
                    setTextMassageInfoTool("Тренировка создана");
                    handleCloseFormAddTraining();
                    setIsChangeTrainingsListHandle(true); // отрисовать тренировку
                    setNewTrain(newTrain.newTrain); // новая тренировка
                    // Создаем новый массив, который содержит все существующие тренировки и новую тренировку
                    //const updatedTrains = [...trains, newTrain.newTrain];
                    // Устанавливаем новый массив тренировок в стейт
                    //setTrains(updatedTrains);
                    setIsOpenFormAddTraining(false);
                    setIsInfoToolOpen(true);
                    setTextMassageInfoTool("Успешно");
                })
                .catch((err) => {
                    setIsInfoToolOpen(true);
                    setTextMassageInfoTool(`Произошла ошибка ${err.message}`);
                })
        } else if (data.typeTrain.includes('персональная-сплит')) {

            let idClients = []
            clientsOnPers.forEach(client => {
                idClients.push(client._id)
            });

            api.createSplitTraining(data, idClients)
                .then(newTraining => {
                    // Создаем новый массив, который содержит все существующие тренировки и новую тренировку
                    //const updatedTrains = [...trains, newTraining.training];
                    // Устанавливаем новый массив тренировок в стейт
                    //setTrains(updatedTrains);
                    setNewTrain(newTraining.training);
                    setIsOpenFormAddTraining(false);
                    setIsInfoToolOpen(true);
                    setTextMassageInfoTool("Успешно");

                }).catch(error => {
                    setIsInfoToolOpen(true);
                    setTextMassageInfoTool(error.message);
                });

        } else if (data.typeTrain.includes('персональная') && !data.typeTrain.includes('сплит')) {
            let idClient = clientsOnPers[0]._id;
            api.createPersonalTraining(data, idClient)
                .then((training) => {
                    setNewTrain(training.training)
                    setIsOpenFormAddTraining(false);
                    setIsInfoToolOpen(true);
                    setTextMassageInfoTool("Успешно");
                })
                .catch((error) => {
                    setIsInfoToolOpen(true);
                    setTextMassageInfoTool(error.message);
                })
        } else {
            api.createTrain(data)
                .then((newTrain) => {
                    setIsInfoToolOpen(true);
                    setTextMassageInfoTool("Тренировка создана");
                    handleCloseFormAddTraining();
                    setIsChangeTrainingsListHandle(true); // отрисовать тренировку
                    setNewTrain(newTrain.newTrain); // новая тренировка
                    setIsOpenFormAddTraining(false);
                    setIsInfoToolOpen(true);
                    setTextMassageInfoTool("Успешно");
                })
                .catch((err) => {
                    setIsInfoToolOpen(true);
                    setTextMassageInfoTool(`Произошла ошибка ${err.message}`);
                })
        }
    }

    //СОЗДАТЬ НОВУЮ ПЕРСОНАЛЬНУЮ ТРЕНИРОВКУ
    function handleAddPersonalTrainSubmit(data) {
        api.createPersonalTraining(data, isIdClient)
            .then((data) => {
                //setTextMassageInfoTool('Персональная тренировка создана, клиент записан')
            })
            .catch(err => {
                console.log(err);
                //setTextMassageInfoTool(`Произошла ошибка ${err.message}`);
            })
    }

    //Слушать добавление тренировки и сбрасывать стейт который слушает изменения
    useEffect(() => {
        if (isChangeTrainingsListHandle) {
            setIsChangeTrainingsListHandle(false);
        }

    }, [isChangeTrainingsListHandle])


    //Работа с разовыми тренировками
    function clickCheckOneTimeTraining() { // открыть просмотр разовых тренировок
        setIsCheckOneTimeTraining(true);
        setIsBigTrainingCalendar(false); // свернуть календарь(расписание)
        setIsClientsOrTrainersTable(false); // спрятать список клиентов или тренеров
    }

    //Работа с историей абонементов у клиента
    // открыть историю абонементов у клиента
    function handleHystoryAbonement() {
        setIsClientHystoryAbonement(true);
    }

    //менять стейт 
    function handleisClientHystoryAbonement(state) {
        setIsClientHystoryAbonement(state);
    }

    // USEEFFECT------------USEEFFECT------------USEEFFECT------------USEEFFECT------------USEEFFECT------------USEEFFECT------------USEEFFECT------------
    //Отсортировать список клиентов по алфавиту
    useEffect(() => {
        let alphabetically = isClients.sort((a, b) => a.surname.localeCompare(b.surname, 'ru'));
        setClientsAlphabetically(alphabetically);
    }, [isClients])


    //КАЛЕНДАРЬ
    //получить тренировки на месяц вперед и неделю назад для отрисовки
    useEffect(() => {
        let updateTrains = [];
        api.getTrainsForCalendar()
            .then((trains) => {
                updateTrains = trains.trains.filter(training => training.typeOfTraining !== "групповая");
                isChangeArrayTrains ? // отображать все тренировки
                    setTrains(trains.trains)
                    :
                    setFilteredTrains(updateTrains);
            })
            .catch((err) => { console.log(err) });
    }, [isChangeArrayTrains, isChangeTrainingsListHandle, newTrain])

    //ИНФОТУЛ
    //закрывать инфотул через 3 секунды после открытия
    useEffect(() => {
        if (isInfoToolOpen) {
            setTimeout(() => {
                setIsInfoToolOpen(false);
                setTextMassageInfoTool('')
            }, 3000)
        }

    }, [isInfoToolOpen]);

    //наполнить массив истории абонементов
    useEffect(() => {
        if (isClientHystoryAbonement) {
            api.getHystoryAbonement(isIdClient)
                .then((hystoryAbonements) => {
                    //Отсортировать массив по датам
                    const sortedHistoryAbonements = hystoryAbonements.list.sort((a, b) => {
                        const dateA = new Date(a.dateOn);
                        const dateB = new Date(b.dateOn);
                        return dateB - dateA;
                    });
                    setHystoryAbonement(sortedHistoryAbonements);
                }).catch((err) => { console.log(err) });
        }
    }, [isClientHystoryAbonement])

    //ПОЛУЧИТЬ СПИСОК ТРЕНЕРОВ(для инпута в форме добавления тренировки)
    useEffect(() => {
        api.getAllTrainers()
            .then((trainers) => { setTrainers(trainers.trainers) })
            .catch((err) => { console.log(err) });
    }, [])

    //СУПЕРАДМИН-------------------УПЕРАДМИН-------------------УПЕРАДМИН-------------------УПЕРАДМИН-------------------УПЕРАДМИН-------------------УПЕРАДМИН-------------------

    //УПРАВЛЯТЬ  КНОПОКАМИ В ФУТЕРЕ ДЛЯ СУПЕРАДМИНА И ПОВЕДНИЕМ КОМПОНЕНТОВ 
    useEffect(() => {
        if (isTrainerAndTraining && isSuperAdmin) { // если открыт компонент инфы о тренерах и их тренировках
            //setIsClients([]); // сбросить массив поиска клиентов
            setIsClientsOrTrainersTable(false); // спрятать список клиентов или тренеров
            setIsBigTrainingCalendar(false); // спрятать расписание
        }

    }, [isTrainerAndTraining, isSuperAdmin])

    //Суперадмин. Управлять скрытием некоторых компонентов
    useEffect(() => {
        if (isStatisticsSuperAdmin && isSuperAdmin) { // если открыта статистика на стр суперадмина
            setIsCheckOneTimeTraining(false); // свернуть просмотр разовых тренировок
            setIsBigTrainingCalendar(false);  // свернуть календарь при поиске
            setIsWaitingList(false); // свернуть лист ожидания
        }
    }, [isStatisticsSuperAdmin, isSuperAdmin])

    //Суперадмин, управлять отображением поиска
    useEffect(() => {
        if (ishideSearchForm && SuperAdmin) {
            setIsSearch(false);
        } else if (!ishideSearchForm && SuperAdmin) {
            setIsSearch(true);
        }
    }, [ishideSearchForm, SuperAdmin])

    function changeSuperAdminState() {
        if (isLikeAdmin) {
            handleSetIsLikeAdmin(false);
        } else {
            handleSetIsLikeAdmin(true);
        }
    }

    return (
        !isSuperAdmin && !isAuthAdmin ? // если не суперадмин (подразумевается админ) и нет авторизации
            <SignIn
                onSubmit={handleSignInAdmin}
                textMassageInfoTool={isTextMassageInfoTool} // информация для отображения ошибки в случае возникновения
            />
            :
            <div className={`admin-page ${backgroundColor}`}>
                {/* ФОРМА ДОБАВЛЕНИЯ КЛИЕНТА */}
                {isOpenFormAddClients ?
                    <FormAddClient // форма добавления клиента
                        isOpen={isOpenFormAddClients} // стейт управления открытия формы добавления клиента
                        isClose={handleCloseFormAddClient} // клик на кнопку для сворачивания
                        onSubmit={handleAddClient} // передать функция добавления клиента
                        textButtonSubmit={textButtonSubmit}
                        colorBackground={colorBackgroundForm} // цвет фона
                        styleButtonSubmit={styleButtonSubmit} // стиль кнопки сабмита
                        styleText={styleText}
                    />
                    : <></>}
                {/* ФОРМА РЕДАКТИРОВАНИЯ КЛИЕНТА */}
                {isOpenFormUpdateClient ?
                    <FormUpdateClient
                        client={selectedClient}// информация о тренере(объект)
                        isOpen={isOpenFormUpdateClient} // стейт управления открытия формы добавления клиента
                        isClose={closeFormUpdateClient} // клик на кнопку для сворачивания
                        onSubmit={handleUpdateClient} // передать функция добавления клиента
                        colorBackground={colorBackgroundForm} // цвет фона
                        styleButtonSubmit={styleButtonSubmit} // стиль кнопки сабмита
                    />
                    : <></>}
                {/* ФОРМА ДОБАВЛЕНИЯ ТРЕНИРОВКИ */}
                {isOpenFormAddTraining ?
                    <FormAddTrain
                        infoFlex={infoFlex}
                        infoDark={infoDark}
                        api={api}
                        isOpen={isOpenFormAddTraining}
                        isSuperAdmin={isSuperAdmin} //Возможность добавлять группы только у суперадмина
                        colorBackground={colorBackgroundForm} // цвет фона
                        isClose={handleCloseFormAddTraining} // свернуть (функция)
                        onSubmit={handleSubmitAddTraining} // действия при сабмите
                        trainers={trainers} // массив тренеров для показа в инпуте при выборе тренировки
                        isInputPersonal={true} // не показывать на главной возможность создания персональных тренировок(только через AboutClientPage)
                        isInputGroupe={true} // групповые
                        isTypeSpace={isTypeSpace} // выбор зала, true - добавить выбор
                        styleText={styleText}
                    />
                    : <></>}
                {/* ИНФОТУЛ */}
                {isInfoToolOpen ?
                    <InfoTooltip
                        isOpen={isInfoToolOpen}
                        isClose={closeInfotool}
                        text={isTextMassageInfoTool}
                        showButton={false}
                        backgoundColor={colorBackgroundForm} />
                    : <></>}
                {/* ШАПКА */}
                <header className={`admin-page__header`}>
                    <div className={`mainPage__link-container admin-page__header-link-container`}>
                        <Link to={"/flex"} className={`mainPage__link-page ${textHeaderColor} ${styleHeaderBoarderColor}`}>Flex Lab</Link>
                        <Link to={"/dark"} className={`mainPage__link-page mainPage__link-page_Dark`}>DARK</Link>
                    </div>
                    <p className={`mainPage__link-page__description admin-page__role ${textHeaderColor}`}>ADMINISTRATION</p>
                </header>

                {/* ИНПУТ ПОИСКА КЛИЕНТА */}
                {isAdminPage && isSearch ?
                    <SearchForm
                        handleSubmOnSearch={onSubmitSearchForm} // сабмит
                        handleSearchType={handleSearchType} // управлять поиском по номеру или Имени
                        styleSearchForm={styleSearchForm} // стиль формы поиска
                        styleTextSearchForm={styleText} // стиль текста
                        imgPhoneSearch={imgPhoneSearch} // картинка кнопки поиска по телефону
                        imgNameSurnameSearch={imgNameSurnameSearch} // картинка кнопки поиска по телефону
                    /> : <></>
                }
                {/* ПЕРЕКЛЮЧАТЕЛЬ ДЛЯ СУПЕРАДМИНА НА РАБОТУ ЛИБО С КЛИЕНТАМИ, ЛИБО С ТРЕНЕРАМИ ЛИБО АДМИНАМИ*/}
                {isSuperAdmin && isAdminPage ? // isAdminPage - это главная страница админа
                    <div className='admin-page__select-container'>
                        {switcherSuperAdmin}
                    </div>
                    : <></>}
                {/* ОСНОВНАЯ ЧАСТЬ  */}
                {
                    isActiveTimeListInfo ? // открыть расписание(если кликнули на РАСПИСАНИЕ и поменялся стейт)
                        <div className='admin-page__select-container'>
                            {/* <SelectList /> */}
                            < TimeTable titleTimeTable={'Расписание'} />
                        </div>
                        : <div></div>
                }
                {
                    isClientsOrTrainersTable ? // также подразумевается, что админы в этом списке ( админ - только кликенты, суперадмин - клиенты, тренеры, админы)
                        isActiveClientsTable && isSearch ? // открыть список клиентов (если кликнули на Поиск или какого-либо клиента и поменялся стейт)
                            // ТАБЛИЦА КЛИЕНТОВ 
                            // ClientsTable -> ClientInfo
                            isAdminPage && !isSuperAdmin ? // если страница админа, то выбора между клиентами и тренерами нет, только клиенты
                                <div className='admin-page__select-container'>
                                    {isClientsTableLoader ? // отобразить прелоадер
                                        <Preloader />
                                        :
                                        <ClientsTable // таблица клиентов
                                            fullInfoClient={true} // true - полная информация по абонементу, false -краткая информация по клиенту ( только имя, телефон и индикатор абонемента)
                                            clients={clientsAlphabetically} // список клиентов для отображения в таблице
                                            styleText={styleText} // стиль текста
                                            controlInfoToolText={controlInfoToolText} // управлять текстом инфотула
                                            controlInfoToolOpen={controlInfoToolOpen} // управлять открытием инфотула
                                            api={api} // АПИ
                                            backgroundColor={backgroundColor} // цвет фона
                                            handleOpenFormAddClient={handleOpenFormAddClient} // управлять открытие формы добавления клиента
                                            isPushClient={isPushClient} // отслеживать сабмит, чтобы обновлять список клиентов
                                            stylePlaceHolder={stylePlaceHolder} // стиль плейсхолдеров инпутов
                                            styleButtonSubmit={styleButtonSubmit} // стиль сабмита - передать в форму работы с абонементом FormAddAbonement
                                            styleButton={styleButton} // стиль кнопок
                                            handleActiveClientInfo={handleActiveClientInfo} // спрятать кнопку Добавить клиента и другие при клике на клиента в списке
                                            infoFlex={infoFlex} // давать ли информацию по абонементам Flex
                                            infoDark={infoDark} // давать ли информацию по абонементам Dark
                                            admin={true} // проверка страница admin или нет (true - admin)
                                            handleNameClient={handleNameClient} // пробросить из ClientInfo для WA 
                                            handlePhoneNumberClient={handlePhoneNumberClient} // пробросить из ClientInfo для WA
                                            timetableWeekButtonStyle={timetableWeekButtonStyle} // стиль кнопок переключения дней недели
                                        />
                                    }
                                </div>
                                : isSuperAdmin && !isSuperadminSelectTrainers && !isSuperAdminSelectAdmins ? // если суперадмин и работа с клиентами
                                    <div className='admin-page__select-container'>
                                        {isClientsTableLoader ? // отобразить прелоадер
                                            <Preloader />
                                            :
                                            <ClientsTable // таблица клиентов
                                                fullInfoClient={true} // true - полная информация по абонементу, false -краткая информация по клиенту ( только имя, телефон и индикатор абонемента)
                                                clients={isClients} // список клиентов для отображения в таблице
                                                controlInfoToolText={controlInfoToolText} // управлять текстом инфотула
                                                controlInfoToolOpen={controlInfoToolOpen} // управлять открытием инфотула
                                                api={api} // АПИ
                                                styleText={styleText} // стиль текста
                                                backgroundColor={backgroundColor} // цвет фона
                                                handleOpenFormAddClient={handleOpenFormAddClient} // управлять открытие формы добавления клиента
                                                isPushClient={isPushClient} // отслеживать сабмит, чтобы обновлять список клиентов
                                                stylePlaceHolder={stylePlaceHolder} // стиль плейсхолдеров инпутов
                                                styleButtonSubmit={styleButtonSubmit} // стиль сабмита - передать в форму работы с абонементом FormAddAbonement
                                                styleButton={styleButton} // стиль кнопок
                                                handleActiveClientInfo={handleActiveClientInfo} // спрятать кнопку Добавить клиента и другие при клике на клиента в списке
                                                infoFlex={infoFlex} // давать ли информацию по абонементам Flex
                                                infoDark={infoDark} // давать ли информацию по абонементам Dark
                                                admin={true} // проверка страница admin или нет (true - admin)
                                                handleNameClient={handleNameClient} // пробросить из ClientInfo для WA 
                                                handlePhoneNumberClient={handlePhoneNumberClient} // пробросить из ClientInfo для WA
                                            />
                                        }
                                    </div>
                                    : isSuperAdmin && isSuperadminSelectTrainers && !isSuperAdminSelectAdmins ? // если суперадмин и работа с тренерами
                                        trainersList // из Суперадмина
                                        : isSuperAdmin && isSuperAdminSelectAdmins && !isSuperadminSelectTrainers ? // если суперадмин и работа с админами
                                            adminsList  // из Суперадмина
                                            : <></> // ничего не отображать если не сработал ни один из флагов
                            : <></>
                        :
                        <></> // ничего не отображать если не кликнули на поиске
                }
                {/* ИНФОРМАЦИЯ О КЛИЕНТЕ */}
                {
                    isActiveClientInfo ?
                        <AboutClientPage
                            styleText={styleText} // стиль текста
                            backgroundColor={backgroundColor}
                            nameClient={`nameClient`}
                            isIdClient={isIdClient} // передать айди клиента в AboutClientPage для получения инф и отрисовки
                            api={api} // передать АПИ студии
                            colorBackgroundForm={colorBackgroundForm} // цвет фона
                            controlInfoToolText={controlInfoToolText} // управлять текстом инфотула
                            controlInfoToolOpen={controlInfoToolOpen} // управлять открытием инфотула
                            colorButtonSubmit={styleButtonSubmit} // стиль кнопки сабмита
                            styleButton={styleButton} // стиль кнопок
                            infoFlex={infoFlex} // давать ли информацию по абонементам Flex
                            infoDark={infoDark} // давать ли информацию по абонементам Dark
                            isTypeSpace={isTypeSpace} // выбор зала, true - добавить выбор
                            clickOnAbonement={clickOnAbonement} // функция клика на абонемент
                            isClickAddClientOnGroupe={isClickAddClientOnGroupe} // стейт запись на групповую тренировку
                            handleIsClickAddClientOnGroupe={handleIsClickAddClientOnGroupe} // функция возврата isClickAddClientOnGroupe в false
                            isClickAddClientOnPersonal={isClickAddClientOnPersonal} // стейт запись на персональную тренировку
                            handleIsClickAddClientOnPersonal={handleIsClickAddClientOnPersonal} // функция возврата isClickAddClientOnPersonal в false
                            isCloseFormAddTrain={handleCloseFormAddTrain} // функция закрытия формы добавления тренировки
                            trainers={trainers} // массив тренеров для создания персональной тренировки и выбора тренера
                            hystoryAbonement={hystoryAbonement} // архив абонементов по клиенту
                            isClientHystoryAbonement={isClientHystoryAbonement} // управлять открытием истории абонементов у клиента
                            handleisClientHystoryAbonement={handleisClientHystoryAbonement} // функция возврата isClientHystoryAbonement в false
                            handleIsUpdateClientButton={handleIsUpdateClientButton} // показывать ли кнопку редактирования клиента в футере
                            timetableWeekButtonStyle={timetableWeekButtonStyle} // стиль кнопок переключения дней недел
                        />
                        : <></>
                }

                {/* РАСПИСАНИЕ И БОЛЬШОЙ КАЛЕНДАРЬ */}
                {
                    isBigTrainingCalendar ?
                        <TrainingCalendar
                            styleText={styleText} // стиль текста
                            trainings={trains} // массив тренировок на месяц
                            isBig={isBigCalendar} // true - показывать большой календарь
                            //ДЛЯ СПИСКА 
                            infoFlex={infoFlex}// давать ли информацию по абонементам Flex
                            infoDark={infoDark} // давать ли информацию по абонементам Dark
                            previousWeekButtonImg={previousWeekButtonImg} // изображение стрелки перекл дней недели
                            nextMonthButtonImg={nextMonthButtonImg} // изображение стрелки перекл дней недели
                            api={api} // передать АПИ студии из компонента студии в которое монтируется расписание
                            backgroundButtonColor={backgroundColor} // цвет фона кнопок
                            titleTimeTable={'Список тренировок'} // заголовок таблицы
                            timeTableBackgroundColor={backgroundColor} // цвет фона таблицы
                            setTrains={setTrains} // менять стейт массива тренировок в компоненте студии в которое монтируется расписание
                            client={true} // проверка есть ли клиент в БД, если нет, то не показывать инф об абонементе (true - клиент есть в БД)
                            handleIsChangeArrayTrains={handleIsChangeArrayTrains} // переключать массив тренировок ( с групповыми или без)
                            isTrainerPage={false} // true - страница тренера
                            imgUpdateTraining={imgUpdateTraining} // изображение кнопки редактирования тренировки
                            imgRemoveTraining={imgRemoveTraining} // изображение кнопки удаления тренировки
                            imgQRCode={imgQRCode} // изображение кнопки qrcode
                            trainers={trainers} // массив тренеров для показа в инпуте при выборе тренировки 
                            backgoundColorPage={backgroundColor}
                            //Слушать добавления тренировок 
                            isChangeTrainingsListHandle={isChangeTrainingsListHandle}
                            newTrain={newTrain} // новая тренировка для отрисовки после добавления
                            //Форма работы с тренировкой и инрфотулы
                            isTypeSpace={isTypeSpace} // выбор зала, true - добавить выбор
                            colorBackgroundForm={colorBackgroundForm} // цвет фона
                        // handleTrainingClick ={handleTrainingClickForCalendar} // клик на тренировку 
                        /> : <></ >
                }
                {/* СПИСОК РАЗОВЫХ ПОСЕЩЕНИЙ НА ТРЕНИРОВКАХ */}
                {
                    isCheckOneTimeTraining ?
                        <ListOfOneTimeTraining
                            api={api} // АПИ
                            imgAddOneTimeTraining={imgAddOneTimeTraining} //картинка добавления абонемента для разовой тренировки
                            imgRemoveOneTimeTraining={imgRemoveOneTimeTraining} // картинка удаления разовой тренировки
                            backgoundColor={backgroundColor}
                            styleText={styleText} // стиль текста
                            textTitleColor={styleTitlesColor} // цвет титульника
                        />
                        : <></>
                }
                {/* КОМПОНЕНТЫ СУПЕРАДМИНА */}
                {isSuperAdmin ?
                    childComponentSuperAdmin
                    : <></>}
                {/* ФУТЕР */}
                <footer className={`page__footer ${styleFooter}`}>
                    {isSuperAdmin && isHideFooterForSuperAdmin ? // если суперадмин и необходимо скрыть кнопки футера   
                        childButtonsFooterSuperAdmin // показать кнопки суперадмина (РАБОТА С ТРЕНЕРОМ)
                        :
                        < div className='admin-page__footer__container-element'>
                            <div className='admin-page__footer__container-element'>
                                {/* ДОБАВИТЬ КЛИЕНТА,ТРЕНЕРА,АДМИНА ИЛИ ПЕРЕЙТИ НА ГЛАВНУЮ*/}
                                {isAdminPage && !isSuperAdmin ? // если админ, а не суперадмин, то только добавить клиента без выбора
                                    // ДОБАВИТЬ КЛИЕНТА 
                                    <button
                                        className={`admin-page__list-comand-button ${backgroundColor}`}
                                        onClick={handleOpenFormAddClient}>
                                        <img className='admin-page__button-img' src={imgAddClients} alt='imgAddClients' />
                                    </button>
                                    : !isAdminPage ?// если админ или суперадмин, но не главная страница 
                                        <button className={`admin-page__list-comand-button ${backgroundColor}`}
                                            onClick={handleClickOnAdminPage}>
                                            <img className='admin-page__button-img' src={imgHomePage} alt='imgHomePage' />
                                        </button>
                                        : isSuperAdmin && !isSuperadminSelectTrainers && !isSuperAdminSelectAdmins ? // если суперадмин и работа с клиентами 
                                            // ДОБАВИТЬ КЛИЕНТА (Суперадмин)
                                            <button
                                                className={`admin-page__list-comand-button ${backgroundColor}`}
                                                onClick={handleOpenFormAddClient}>
                                                <img className='admin-page__button-img' src={imgAddClients} alt='imgAddClients' />
                                            </button>
                                            : isSuperAdmin && isSuperadminSelectTrainers && !isSuperAdminSelectAdmins ? // если суперадмин и работа с тренерами
                                                //ДОБАВИТЬ ТРЕНЕРА (Суперадмин)
                                                buttonAddTrainer
                                                : isSuperAdmin && !isSuperadminSelectTrainers && isSuperAdminSelectAdmins ?
                                                    //КНОПКА ДОБАВИТЬ АДМИНА
                                                    buttonAddAdmin
                                                    :
                                                    <></>
                                }
                                {/* ДОБАВИТЬ ТРЕНИРОВКУ */}
                                {isAdminPage ?
                                    <button
                                        className={`admin-page__list-comand-button ${backgroundColor}`}
                                        onClick={handleOpenFormAddTraining}>
                                        <img className='admin-page__button-img' src={imgAddTraining} alt='imgAddTraining' />
                                    </button>
                                    : <></>}
                                {isActiveClientInfo ?
                                    <>
                                        {/* Записать на персональную */}
                                        <button
                                            className={`admin-page__list-comand-button admin-page__list-comand-button_personal ${backgroundColor}`}
                                            onClick={handleAddClientOnPersonal}>
                                            <img className='admin-page__button-img' src={imgPersonalTrain} alt='imgPersonalTrain' />
                                        </button>
                                        {/* Записать на групповую */}
                                        <button
                                            className={`admin-page__list-comand-button admin-page__list-comand-button_groupe ${backgroundColor}`}
                                            onClick={handleAddClientOnGroupe}>
                                            <img className='admin-page__button-img' src={imgGroupeTrain} alt='imgGroupeTrain' />
                                        </button>
                                        {/* История абонементов */}
                                        <button
                                            className={`admin-page__list-comand-button admin-page__list-comand-button_hystory-abonements ${backgroundColor}`}
                                            onClick={handleHystoryAbonement}>
                                            <img className='admin-page__button-img' src={imgHystoryAbonement} alt='imgHystoryAbonement' />
                                        </button>
                                        {/* Написать клиенту WA */}
                                        <button
                                            className={`admin-page__list-comand-button ${backgroundColor}`}
                                            onClick={writeWAToClient}>
                                            <img className='admin-page__button-img' src={imgWA} alt='imgWA' />
                                        </button>
                                        {isUpdateButtonClient ?
                                            //Редактировать клиента
                                            <button
                                                className={`admin-page__list-comand-button ${backgroundColor}`}
                                                onClick={openFormUpdateClient}>
                                                <img className='admin-page__button-img' src={imgUpdateTraining} alt='imgUpdate' />
                                            </button>
                                            : <></>}
                                    </>
                                    : <></>}
                                {/* Список разовых тренировок(кнопка) */}
                                {isAdminPage ?
                                    < button className={`admin-page__list-comand-button ${backgroundColor}`}
                                        onClick={clickCheckOneTimeTraining}>
                                        <img className='admin-page__button-img' src={imgCheckOneTimeTraining} alt='imgCheckOneTimeTraining' />
                                    </button>
                                    : <></>}
                                {/* Расписание */}
                                {isAdminPage ?
                                    <>
                                        {/*календарь большой или список */}
                                        <button className={`admin-page__list-comand-button ${backgroundColor}`}
                                            onClick={handleClickOnSettingCalendar}>
                                            <img className='admin-page__button-img' src={imgList} alt='imgHomePage' />
                                        </button>
                                        < button className={`admin-page__list-comand-button ${backgroundColor}`}
                                            onClick={openBigCalendar}>
                                            <img className='admin-page__button-img' src={imgTimeTable} alt='imgTimeTable' />
                                        </button>
                                    </>
                                    : <></>}
                                {isSuperAdmin && isAdminPage ? // isAdminPage - подразумевается дополнительно что главная
                                    buttonStatistics // кнопка статистики у суперадмина
                                    : <></>}
                            </div>
                        </div>
                    }
                </footer >
            </div >
    )
}

export default AdminPage;