import { useEffect, useState } from "react";

function SelectMonthYear({
    colorBackground,
    styleForm,
    handleMonth,
    handleYear,
}) {

    const [month, setMonth] = useState('');
    const [year, setYear] = useState(''); // Добавлено состояние для года
    const [yearsOptions, setYearsOptions] = useState([]); // опции в селект для выбора года у суперадмина
    const [monthsOptions, setMonthsOptions] = useState([]); // опции в селект для выбора месяца
    const [monthPrevious, setMonthPrevious] = useState('');

    //Слушать изменение месяца
    function handleChangeMonth(event) {
        let month = event.target.value
        setMonth(month);
    }

    //Слушать изменения года
    function handleChangeYear(event) {
        let year = event.target.value;
        setYear(year);
    }

    // Передача месяца и года в компонент выше
    useEffect(() => {
        handleMonth(month);
        handleYear(year);
    }, [month, year]);

    //Наполнить опции для селекта выбора года и месяцев у суперадмина
    useEffect(() => {
        //Год
        const currentYear = new Date().getFullYear();
        const currentMonthIndex = new Date().getMonth();
        setYear(currentYear.toString());
        setMonth(currentMonthIndex);
        const options = [];
        for (let i = 0; i < 5; i++) {
            const yearValue = currentYear - i;
            options.push(
                <option key={yearValue} value={yearValue.toString()}>
                    {yearValue}
                </option>
            );
        }
        setYearsOptions(options);

        //Месяц
        const optionsMonths = [];
        for (let i = 0; i < 12; i++) {
            const month = new Date();
            month.setMonth(month.getMonth() - i);
            const monthIndex = month.getMonth();
            const monthName = month.toLocaleString('ru', { month: 'long' });
            optionsMonths.push(
                <option value={monthIndex.toString()} key={monthIndex}>
                    {monthName}
                </option>
            );
        }
        setMonthsOptions(optionsMonths);

    }, []);

    return (
        <div className="selectMonthYear">
            < form className={`formadd__form formadd__training-history ${styleForm}`
            } onSubmit={() => { }} >
                {/* Выбор месяца */}
                < div className="formadd__input-conainer" >
                    <select
                        value={month}
                        onChange={handleChangeMonth}
                        className={`${colorBackground} formadd__input formadd__input_name_image formadd__input-select`}
                    >
                        {monthsOptions}
                    </select>
                    <span className="formadd__text-error" id="error-firstname">Выбор месяца</span>
                </div >
                {/* Выбор года */}
                < div className="formadd__input-conainer" >
                    <select
                        value={year}
                        onChange={handleChangeYear}
                        className={`${colorBackground} formadd__input formadd__input_name_image formadd__input-select`}
                    >
                        {yearsOptions}
                    </select>
                    <span className="formadd__text-error" id="error-firstname">Выбор года</span>
                </div >
            </form >
        </div>
    )
}

export default SelectMonthYear;