import { useEffect, useState } from "react";
import FormAdd from "../FormAdd/FormAdd";

function FormAddTrainer({
    isOpen,
    isClose,
    onSubmit, // действия при сабмите
    textButtonSubmit, // текст кнопки сабмита
    colorBackground, // цвет фона
}) {

    // Стейты, в которых содержятся значения инпута
    const [nameTrainer, setNameTrainer] = useState('');
    const [subnameTrainer, setSubnameTrainer] = useState('');
    const [number, setNumber] = useState('');
    const [password, setPassword] = useState('');
    const [validationMassegePassword, setValidationMassegePassword] = useState('Пароль');
    const [validationMassegeName, setValidationMassegeName] = useState('Имя');
    const [validationMassegeSubname, setValidationMassegeSubname] = useState('Фамилия');
    const [validationMassegeNumber, setValidationMassegeNumber] = useState('Номер телефона');
    // Валидация
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [isValidInputs, setIsValidInputs] = useState(false);
    const [isValidMessages, setIsValidMessages] = useState(false);

    //передать данные  из инпутов
    function handleChangeName(e) {
        setNameTrainer(e.target.value);
        if (e.target.validationMessage === "") {
            setValidationMassegeName('Имя');
        } else {
            setValidationMassegeName(e.target.validationMessage);
        }
    }

    function handleChangeSubname(e) {
        setSubnameTrainer(e.target.value);
        if (e.target.validationMessage === "") {
            setValidationMassegeSubname('Фамилия');
        } else {
            setValidationMassegeSubname(e.target.validationMessage);
        }

    }

    function handleChangeNumber(e) {
        let numberFromStr = +e.target.value;
        setNumber(numberFromStr);
        if (e.target.validationMessage === "") {
            setValidationMassegeNumber("Номер");
        } else {
            setValidationMassegeNumber(e.target.validationMessage);
        }
    }

    function handleChangePassword(e) {
        setPassword(e.target.value);
        if (e.target.validationMessage === "") {
            setValidationMassegePassword('Пароль');
        } else {
            setValidationMassegePassword(e.target.validationMessage);
        }
    }

    //Передать данные = отправка на сервер 
    function handleSubmit(e) {
        e.preventDefault();
        onSubmit({
            name: nameTrainer,
            surname: subnameTrainer,
            phoneNumber: number,
            password: password
        });
    }

    //Отслеживать изменения в инпута, кнопка задисейблена пока нет внесены изменения 
    useEffect(() => {
        if (nameTrainer === "" || subnameTrainer === "" || number === "" || password === "") {
            setIsValidInputs(false);
        } else {
            setIsValidInputs(true);
        }
    }, [nameTrainer, subnameTrainer, number, password]);

    //Отслеживать ошибки инпутов, чтобы задисейблить кнопку
    useEffect(() => {
        if (
            validationMassegePassword !== 'Пароль' ||
            validationMassegeName !== 'Имя' ||
            validationMassegeSubname !== 'Фамилия' ||
            validationMassegeNumber !== 'Номер'
        ) {
            setIsValidMessages(false);
        } else {
            setIsValidMessages(true);
        }
    }, [validationMassegePassword, validationMassegeName, validationMassegeSubname, validationMassegeNumber])

    //Проставлять итоговый дисейбл сабмита
    useEffect(() => {
        if (isValidInputs && isValidMessages) {
            setIsSubmitDisabled(false);
        } else {
            setIsSubmitDisabled(true);
        }

    }, [isValidInputs, isValidMessages]);

    return (
        <FormAdd
            isOpen={isOpen} // стейт контролирующий открытие
            isClose={isClose} // клик на кнопку для сворачивания
            onSubmit={handleSubmit}
            text={'Добавить тренера'}
            textButtonSubmit={textButtonSubmit}
            colorBackground={colorBackground}
            validationOptions={isSubmitDisabled} // дисейблить сабмит если true
            children={
                <>
                    <div className="formadd__input-conainer">
                        <input value={nameTrainer} type="text" placeholder="Имя" className={`${colorBackground} formadd__input formadd__input_name_image`}
                            name="name-trainer" id="name-trainer" minLength="2" maxLength="30" onChange={handleChangeName} required />
                        <span className="formadd__text-error" id="error-firstname">{validationMassegeName}</span>
                    </div>
                    <div className="formadd__input-conainer">
                        <input value={subnameTrainer} type="text" placeholder="Фамилия" className={`${colorBackground} formadd__input formadd__input_name_image`}
                            name="subname-trainer" id="subname-trainer" minLength="2" maxLength="30" onChange={handleChangeSubname} required />
                        <span className="formadd__text-error" id="error-firstname">{validationMassegeSubname}</span>
                    </div>
                    <div className="formadd__input-conainer">
                        <input value={number} type="text" placeholder="Номер телефона" className={`${colorBackground} formadd__input formadd__input_name_image`}
                            name="number-trainer" id="number-trainer" minLength="11" maxLength="11" onChange={handleChangeNumber} required />
                        <span className="formadd__text-error" id="error-firstname">{validationMassegeNumber}</span>
                    </div>
                    <div className="formadd__input-conainer">
                        <input value={password} type="text" placeholder="Пароль" className={`${colorBackground} formadd__input formadd__input_name_image`}
                            name="password-trainer" id="password-trainer" minLength="2" maxLength="11" onChange={handleChangePassword} required />
                        <span className="formadd__text-error" id="error-firstname">{validationMassegePassword}</span>
                    </div>
                </>
            }
        />
    )
}

export default FormAddTrainer;