import { React } from 'react';

import AdminPage from '../AdminPage/AdminPage';
import apiFlex from '../../utils/ApiFlex';

import nextMonthButtonImg from './../../images/next-week.svg'
import previousWeekButtonImg from './../../images/previous-week.svg'
import imgUpdateTraining from './../../images/edit-pencil-line-01-svgrepo-com.svg'
import imgRemoveTraining from './../../images/delete-1-svgrepo-com.svg'
import imgWA from './../../images/message-three-points-1560-svgrepo-com.svg';
import imgAddTraining from './../../images/add-square-s.svg';
import imgTimeTable from './../../images/calendar-1-svgrepo-com.svg';
import imgGroupeTrain from './../../images/group-svgrepo-com.svg';
import imgPersonalTrain from './../../images/personal.svg';
import imgHomePage from './../../images/home-1-svgrepo-com.svg';
import imgList from './../../images/list-center-svgrepo-com.svg';
import imgAddClients from './../../images/add-profile-svgrepo-com.svg'
import imgCheckOneTimeTraining from './../../images/question-answer-help-svgrepo-com.svg'
import imgAddOneTimeTraining from './../../images/check-svgrepo-com.svg' //картинка добавления абонемента для разовой тренировки
import imgRemoveOneTimeTraining from './../../images/remove-svgrepo-com.svg' // картинка удаления разовой тренировки
import imgHystoryAbonement from './../../images/inbox-empty-round-1553-svgrepo-com.svg'; // картинка истории абонементов
import imgPhoneSearch from './../../images/mobile-svgrepo-com.svg';// картинка кнопки поиска по телефону
import imgNameSurnameSearch from './../../images/search-svgrepo-com.svg'; // картинка кнопки поиска по телефону

function AdminPageFlex({apiAuth}) {
    return (
        <AdminPage
            backgroundColor={'flex'} // цвет фона кнопок
            backgroundColorButton={'about-client-page__button_flex'} // цвет фона кнопок
            timetableWeekButtonStyle={'flex__button-day-weeks'} // стиль кнопок переключения дней недели
            api={apiFlex} // Апи
            apiAuth={apiAuth} // АПИ Авторизации
            styleButton={'flex__buttons'} // стиль кнопок
            textButtonSubmit={'Отправить'}// текст кнопки сабмита(добавления клиента)
            colorBackgroundForm={'flex'} // цвет фона формы сабмита нового клиента
            stylePlaceHolder={'clients-table__form__input_flex::placeholder'} // стиль плейсхолдера у инпутов
            styleButtonSubmit={'formadd__button_flex'} // стиль кнопки сабмита
            textHeaderColor={'flex__title-color-text'} // цвет текста Титульника
            styleHeaderBoarderColor={'admin-page__header-boarder'} // цвет полоски в хедере
            styleText ={'flex__color-text'} // стиль текста
            previousWeekButtonImg={previousWeekButtonImg} // изображение стрелки перекл дней недели
            nextMonthButtonImg={nextMonthButtonImg} // изображение стрелки перекл дней недели
            infoFlex={true}// давать ли информацию по абонементам Flex
            infoDark={false} // давать ли информацию по абонементам Dark
            isSuperAdmin={false} //Возможность добавлять группы только у суперадмина
            imgUpdateTraining={imgUpdateTraining} // изображение кнопки редактирования тренировки
            imgRemoveTraining={imgRemoveTraining} // изображение кнопки удаления тренировки
            imgWA={imgWA} // изображение кнопки мессенджер
            imgAddTraining={imgAddTraining} // изображение кнопки добавления тренировки
            imgTimeTable={imgTimeTable} // изображение кнопки расписания
            imgGroupeTrain={imgGroupeTrain} // изображение кнопки добавления групповой тренировки
            imgPersonalTrain={imgPersonalTrain} // изображение кнопки добавления персональной тренировки
            imgHomePage={imgHomePage} // изображение кнопки главной страницы
            imgList={imgList} // изображение кнопки переклбчения на список в календаре
            imgAddClients={imgAddClients} // изображение кнопки создания клиента
            imgCheckOneTimeTraining={imgCheckOneTimeTraining} // изображение кнопки проверки разовых тренировок
            imgAddOneTimeTraining={imgAddOneTimeTraining} //картинка добавления абонемента для разовой тренировки
            imgRemoveOneTimeTraining={imgRemoveOneTimeTraining} // картинка удаления разовой тренировки
            imgHystoryAbonement={imgHystoryAbonement} // картинка истории абонементов
            imgPhoneSearch={imgPhoneSearch} // картинка кнопки поиска по телефону
            imgNameSurnameSearch={imgNameSurnameSearch} // картинка кнопки поиска по телефону
            //Форма добавления зала 
            isTypeSpace={false} // выбор зала, true - добавить выбор
        />
    )
}

export default AdminPageFlex;