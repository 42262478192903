// Список всех абонементов за какой-либо месяц и год
//StatisticsSuperAdmin -> this

import { useEffect, useState } from "react";

function AbonementsInfo({
    api,
    styleForm,
    onSubmit,
    colorBackground,
    styleText,
    month, // месяц
    year, // год
    abonements, // абонементы
}) {

    //Количества
    const [numberOfAbonementsInSum, setNumberOfAbonementsInSum] = useState(0); // общеее количество абонементов
    const [numberOfAbonementsInGroupsOne, setNumberOfAbonementsInGroupsOne] = useState(0); // количество  группах
    const [numberOfAbonementsInGroupsFour, setNumberOfAbonementsInGroupsFour] = useState(0); // количество группах
    const [numberOfAbonementsInGroupsEight, setNumberOfAbonementsInGroupsEight] = useState(0); // количество  группах
    const [numberOfAbonementsInGroupsTwelve, setNumberOfAbonementsInGroupsTwelve] = useState(0); // количество  группах
    const [numberOfAbonementsInGroupsSettings, setNumberOfAbonementsInGroupsSettings] = useState(0); // количество  настраиваемых-персональных
    const [numberOfAbonementsInPersonalSplitOne, setNumberOfAbonementsInPersonalSplitOne] = useState(0); // количество  персональных-сплит-абонемент разовых
    const [numberOfAbonementsInPersonalSplitFour, setNumberOfAbonementsInPersonalSplitFour] = useState(0); // сплит-4
    const [numberOfAbonementsInPersonalSplitEight, setNumberOfAbonementsInPersonalSplitEight] = useState(0); // сплит -8
    const [numberOfAbonementsInPersonalOne, setNumberOfAbonementsInPersonalOne] = useState(0); // количество  персональных-разовых
    const [numberOfAbonementsInPersonalFour, setNumberOfAbonementsInPersonalFour] = useState(0); // количество  персональных-абонемент на 4 тренировк
    const [numberOfAbonementsInPersonalEight, setNumberOfAbonementsInPersonalEight] = useState(0); // количество  персональных-абонемент на 8 тренировки
    const [numberOfAbonementsInPersonalSettings, setNumberOfAbonementsInPersonalSettings] = useState(0); // количество  настраиваемых-персональных

    //Поступления
    const [costsOfAbonementsInSum, setCostsOfAbonementsInSum] = useState(0); // общее количество поступлений 
    const [abonementsCostsInGroupsOne, setAbonementsCostsInGroupsOne] = useState(0); // количество поступлений в группах
    const [abonementsCostsInGroupsFour, setAbonementsCostsInGroupsFour] = useState(0); // количество поступлений в группах
    const [abonementsCostsInGroupsEight, setAbonementsCostsInGroupsEight] = useState(0); // количество поступлений в группах
    const [abonementsCostsInGroupsTwelve, setAbonementsCostsInGroupsTwelve] = useState(0); // количество поступлений в группах
    const [abonementsCostsInGroupsSettings, setAbonementsCostsInGroupsSettings] = useState(0); // количество поступлений в группах
    const [abonementsCostsInPersonalSplitOne, setAbonementsCostsInPersonalSplitOne] = useState(0); // количество поступлений в персональных-сплит-абонемент разовых
    const [abonementsCostsInPersonalSplitFour, setAbonementsCostsInPersonalSplitFour] = useState(0); // сплит-4
    const [abonementsCostsInPersonalSplitEight, setAbonementsCostsInPersonalSplitEight] = useState(0); // сплит -8
    const [abonementsCostsInPersonalOne, setAbonementsCostsInPersonalOne] = useState(0); // количество поступлений в персональных-разовых
    const [abonementsCostsInPersonalFour, setAbonementsCostsInPersonalFour] = useState(0); // количество поступлений в персональных-абонемент на 4 тренировк
    const [abonementsCostsInPersonalEight, setAbonementsCostsInPersonalEight] = useState(0); // количество поступлений в персональных-абонемент на 8 тренировк
    const [abonementsCostsInPersonalSettings, setAbonementsCostsInPersonalSettings] = useState(0); // количество поступлений в персональных-абонемент настраиваемый

    //Отрисовать количество абонементов и стоимость
    useEffect(() => {
        //Счетчики количеств абонементов
        //Групповые
        let abonementsInGroupsOne = 0;
        let abonementsInGroupsFour = 0;
        let abonementsInGroupsEight = 0;
        let abonementsInGroupsTwelve = 0;
        let abonementsInGroupsSettings = 0;
        //Персональные
        let abonementsInPersonalOne = 0;
        let abonementsInPersonalFour = 0;
        let abonementsInPersonalEight = 0;
        let abonementsInPersonalSettings = 0;
        //Персональные-сплит
        let abonementsInPersonalSplitOne = 0;
        let abonementsInPersonalSplitFour = 0;
        let abonementsInPersonalSplitEight = 0;
        //Итого
        let abonementsIsSum = 0;

        //Счетчик поступлений
        //Групповые
        let costsInGroupsOne = 0;
        let costsInGroupsFour = 0;
        let costsInGroupsEight = 0;
        let costsInGroupsTwelve = 0;
        let costsInGroupsSettings = 0;
        //Персональные
        let costsInPersonalOne = 0;
        let costsInPersonalFour = 0;
        let costsInPersonalEight = 0;
        //Персональные-сплит
        let costsInPersonalSplitOne = 0;
        let costsInPersonalSplitFour = 0;
        let costsInPersonalSplitEight = 0;
        let costsInPersonalSettings = 0;

        if (abonements !== undefined) {
            abonements.forEach((abonement) => {
                if (abonement.type === 'групповые-разовая') {
                    abonementsInGroupsOne += 1;
                    abonementsIsSum += 1;
                    costsInGroupsOne += abonement.cost
                } else if (abonement.type === 'групповые-4') {
                    abonementsInGroupsFour += 1;
                    costsInGroupsFour += abonement.cost
                } else if (abonement.type === 'групповые-8') {
                    abonementsInGroupsEight += 1;
                    abonementsIsSum += 1;
                    costsInGroupsEight += abonement.cost
                } else if (abonement.type === 'групповые-12') {
                    abonementsInGroupsTwelve += 1;
                    abonementsIsSum += 1;
                    costsInGroupsTwelve += abonement.cost
                } else if (abonement.type === 'групповые-настраиваемый') {
                    abonementsInGroupsSettings += 1;
                    abonementsIsSum += 1;
                    costsInGroupsSettings += abonement.cost
                } else if (abonement.type === 'персональные-разовая') {
                    abonementsInPersonalOne += 1;
                    abonementsIsSum += 1;
                    costsInPersonalOne += abonement.cost
                } else if (abonement.type === 'персональные-4') {
                    abonementsInPersonalFour += 1;
                    abonementsIsSum += 1;
                    costsInPersonalFour += abonement.cost
                } else if (abonement.type === 'персональные-8') {
                    abonementsInPersonalEight += 1;
                    abonementsIsSum += 1;
                    costsInPersonalEight += abonement.cost
                } else if (abonement.type === 'персональные-сплит-разовая') {
                    abonementsInPersonalSplitOne += 1;
                    abonementsIsSum += 1;
                    costsInPersonalSplitOne += abonement.cost
                } else if (abonement.type === 'персональные-сплит-4') {
                    abonementsInPersonalSplitFour += 1;
                    abonementsIsSum += 1;
                    costsInPersonalSplitFour += abonement.cost
                } else if (abonement.type === 'персональные-сплит-8') {
                    abonementsInPersonalSplitEight += 1;
                    abonementsIsSum += 1;
                    costsInPersonalSplitEight += abonement.cost
                } else if (abonement.type === 'персональные-настраиваемый') {
                    abonementsInPersonalSettings += 1;
                    abonementsIsSum += 1;
                    costsInPersonalSettings += abonement.cost
                }
            })
        }

        //Количества
        setNumberOfAbonementsInSum(abonementsIsSum); // общеее количество абонементов
        setNumberOfAbonementsInGroupsOne(abonementsInGroupsOne); // количество  группах
        setNumberOfAbonementsInGroupsFour(abonementsCostsInGroupsFour); // количество группах
        setNumberOfAbonementsInGroupsEight(abonementsInGroupsEight); // количество  группах
        setNumberOfAbonementsInGroupsTwelve(abonementsInGroupsTwelve); // количество  группах
        setNumberOfAbonementsInGroupsSettings(abonementsInGroupsSettings); // количество  настраиваемых-персональных
        setNumberOfAbonementsInPersonalSplitOne(abonementsCostsInPersonalSplitOne); // количество  персональных-сплит-абонемент разовых
        setNumberOfAbonementsInPersonalSplitFour(abonementsCostsInPersonalFour); // сплит-4
        setNumberOfAbonementsInPersonalSplitEight(abonementsCostsInPersonalSplitEight); // сплит -8
        setNumberOfAbonementsInPersonalOne(abonementsInPersonalOne); // количество  персональных-разовых
        setNumberOfAbonementsInPersonalFour(abonementsInPersonalFour); // количество  персональных-абонемент на 4 тренировк
        setNumberOfAbonementsInPersonalEight(abonementsCostsInGroupsEight); // количество  персональных-абонемент на 8 тренировки
        setNumberOfAbonementsInPersonalSettings(abonementsInPersonalSettings); // количество  настраиваемых-персональных

        //Поступления
        let inSumCosts =
            costsInGroupsOne + costsInGroupsFour + costsInGroupsEight + costsInGroupsTwelve + costsInGroupsSettings
            + costsInPersonalOne + costsInPersonalFour + costsInPersonalEight + costsInPersonalSettings
            + costsInPersonalSplitOne + costsInPersonalSplitEight + costsInPersonalSettings;
        setCostsOfAbonementsInSum(inSumCosts); // общее количество поступлений 
        setAbonementsCostsInGroupsOne(costsInGroupsOne); // количество поступлений в группах
        setAbonementsCostsInGroupsFour(costsInGroupsFour); // количество поступлений в группах
        setAbonementsCostsInGroupsEight(costsInGroupsEight); // количество поступлений в группах
        setAbonementsCostsInGroupsTwelve(costsInGroupsTwelve); // количество поступлений в группах
        setAbonementsCostsInGroupsSettings(costsInGroupsSettings); // количество поступлений в группах
        setAbonementsCostsInPersonalSplitOne(costsInPersonalOne); // количество поступлений в персональных-сплит-абонемент разовых
        setAbonementsCostsInPersonalSplitFour(costsInPersonalFour); // сплит-4
        setAbonementsCostsInPersonalSplitEight(costsInPersonalEight); // сплит -8
        setAbonementsCostsInPersonalOne(costsInPersonalSplitOne); // количество поступлений в персональных-разовых
        setAbonementsCostsInPersonalFour(costsInPersonalSplitFour); // количество поступлений в персональных-абонемент на 4 тренировк
        setAbonementsCostsInPersonalEight(costsInPersonalSplitEight); // количество поступлений в персональных-абонемент на 8 тренировк
        setAbonementsCostsInPersonalSettings(costsInPersonalSettings); // количество поступлений в персональных-абонемент настраиваемый


    }, [abonements]);


    return <div className="timetable__list">
        {/* ИНФОРМАЦИЯ ОБ АБОНЕМЕНТАХ */}
        < h3 className={`abonements-info__title ${styleText}`}> Абонементы </h3 >
        <div className='abonements-info__table'>
            <div className="abonements-info__table-column">
                < h5 className={`abonements-info__title-column abonements-info__text-align-left ${styleText}`}> Тип </h5 >
                <p className={`abonements-info__table-column-text abonements-info__text-align-left ${styleText}`}>групповые-разовые: </p>
                <p className={`abonements-info__table-column-text abonements-info__text-align-left ${styleText}`}>групповые-4:</p>
                <p className={`abonements-info__table-column-text abonements-info__text-align-left ${styleText}`}>групповые-8:</p>
                <p className={`abonements-info__table-column-text abonements-info__text-align-left ${styleText}`}>групповые-12:</p>
                <p className={`abonements-info__table-column-text abonements-info__text-align-left ${styleText}`}>групповые-настр.:</p>
                <p className={`abonements-info__table-column-text abonements-info__text-align-left ${styleText}`}>персональные-разовые:</p>
                <p className={`abonements-info__table-column-text abonements-info__text-align-left ${styleText}`}>персональные-4: </p>
                <p className={`abonements-info__table-column-text abonements-info__text-align-left ${styleText}`}>персональные-8:</p>
                <p className={`abonements-info__table-column-text abonements-info__text-align-left ${styleText}`}>сплит-разовые:</p>
                <p className={`abonements-info__table-column-text abonements-info__text-align-left ${styleText}`}>сплит-4: </p>
                <p className={`abonements-info__table-column-text abonements-info__text-align-left ${styleText}`}>сплит-8:</p>
                <p className={`abonements-info__table-column-text abonements-info__text-align-left ${styleText}`}>перс.настр.:</p>
                <p className={`abonements-info__title-column abonements-info__table-column-text abonements-info__text-align-left ${styleText}`}>Итого</p>
            </div>
            <div className="abonements-info__table-column">
                < h5 className={`abonements-info__title-column ${styleText}`}> Количество </h5 >
                <p className={`abonements-info__table-column-text ${styleText}`}>{numberOfAbonementsInGroupsOne}</p>
                <p className={`abonements-info__table-column-text ${styleText}`}>{numberOfAbonementsInGroupsFour}</p>
                <p className={`abonements-info__table-column-text ${styleText}`}>{numberOfAbonementsInGroupsEight}</p>
                <p className={`abonements-info__table-column-text ${styleText}`}>{numberOfAbonementsInGroupsTwelve}</p>
                <p className={`abonements-info__table-column-text ${styleText}`}>{numberOfAbonementsInGroupsSettings}</p>
                <p className={`abonements-info__table-column-text ${styleText}`}>{numberOfAbonementsInPersonalOne}</p>
                <p className={`abonements-info__table-column-text ${styleText}`}>{numberOfAbonementsInPersonalFour}</p>
                <p className={`abonements-info__table-column-text ${styleText}`}>{numberOfAbonementsInPersonalEight}</p>
                <p className={`abonements-info__table-column-text ${styleText}`}>{numberOfAbonementsInPersonalSplitOne}</p>
                <p className={`abonements-info__table-column-text ${styleText}`}>{numberOfAbonementsInPersonalSplitFour}</p>
                <p className={`abonements-info__table-column-text ${styleText}`}>{numberOfAbonementsInPersonalSplitEight}</p>
                <p className={`abonements-info__table-column-text ${styleText}`}>{numberOfAbonementsInPersonalSettings}</p>
                <p className={`abonements-info__title-column abonements-info__table-column-text ${styleText}`}>{numberOfAbonementsInSum}</p>
            </div>
            <div className="abonements-info__table-column">
                < h5 className={`abonements-info__title-column abonements-info__text-align-right ${styleText}`}> ₽ </h5 >
                <p className={`abonements-info__table-column-text abonements-info__text-align-right ${styleText}`}>{abonementsCostsInGroupsOne}</p>
                <p className={`abonements-info__table-column-text abonements-info__text-align-right ${styleText}`}>{abonementsCostsInGroupsFour}</p>
                <p className={`abonements-info__table-column-text abonements-info__text-align-right ${styleText}`}>{abonementsCostsInGroupsEight}</p>
                <p className={`abonements-info__table-column-text abonements-info__text-align-right ${styleText}`}>{abonementsCostsInGroupsTwelve}</p>
                <p className={`abonements-info__table-column-text abonements-info__text-align-right ${styleText}`}>{abonementsCostsInGroupsSettings}</p>
                <p className={`abonements-info__table-column-text abonements-info__text-align-right ${styleText}`}>{abonementsCostsInPersonalOne}</p>
                <p className={`abonements-info__table-column-text abonements-info__text-align-right ${styleText}`}>{abonementsCostsInPersonalFour}</p>
                <p className={`abonements-info__table-column-text abonements-info__text-align-right ${styleText}`}>{abonementsCostsInPersonalEight}</p>
                <p className={`abonements-info__table-column-text abonements-info__text-align-right ${styleText}`}>{abonementsCostsInPersonalSplitOne}</p>
                <p className={`abonements-info__table-column-text abonements-info__text-align-right ${styleText}`}>{abonementsCostsInPersonalSplitFour}</p>
                <p className={`abonements-info__table-column-text abonements-info__text-align-right ${styleText}`}>{abonementsCostsInPersonalSplitEight}</p>
                <p className={`abonements-info__table-column-text abonements-info__text-align-right ${styleText}`}>{abonementsCostsInPersonalSettings}</p>
                <p className={`abonements-info__title-column abonements-info__table-column-text abonements-info__text-align-right ${styleText}`}>{costsOfAbonementsInSum}</p>
            </div>
        </div>
    </div>
}

export default AbonementsInfo;